import config from "config";
import {
  gql,
  QueryFunctionOptions,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { ENTITY_STATUSES } from "const";
import { IEntity, IOrganizationEntity } from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import { ORGANIZATION_FIELDS } from "./Organization.constants";
import { useEffect } from "react";

const GET_ORGANIZATIONS = gql`
  query getOrganizations(
    $where: EntityWhere
    $fundingRoundsWhere: FundingRoundWhere
    $sort: [EntitySort!]
    $limit: Int
    $offset: Int
    $phrase: String!
    $isAdmin: Boolean!
    $isAuth: Boolean!
  ) {
    organizationsAggregate: entitiesAggregate (where: $where, fulltext: { FullTextSearchFields: { phrase: $phrase } }) {
			count
		}
    organizations: entities (
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      fulltext: { FullTextSearchFields: { phrase: $phrase } }
      ) {
      ${ORGANIZATION_FIELDS}
    }
  }
`;

type GraphCompanyVarsType = {
  limit?: number;
  offset?: number;
  sort?: {};
  where?: { status?: ENTITY_STATUSES; name_CONTAINS?: string; id?: string };
  search?: string | null;
  fulltext?: string;
};

export function useGraphQLCompanies({
  limit,
  offset,
  sort,
  where,
  search,
}: GraphCompanyVarsType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<{
    organizations: IOrganizationEntity[];
    organizationsAggregate: { count: number };
  }>(GET_ORGANIZATIONS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        entityType_INCLUDES: "ORGANIZATION",
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
      fundingRoundsWhere: {
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
    },
  });

  return { loading, error, data };
}

export function useOrganizationExportQuery({
  sort,
  where,
  search,
  limit,
}: GraphCompanyVarsType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();

  const [getExportData, { loading: loadingExport, data: dataExport }] =
    useLazyQuery<{
      [key: string]: IEntity[];
    }>(GET_ORGANIZATIONS, {
      variables: {
        where: {
          ...where,
          entityType_INCLUDES: "ORGANIZATION",
          vertical_INCLUDES: config.VERTICAL,
          ...(!isSuper &&
            !isAdmin && {
              status_IN: ENTITY_STATUSES.APPROVED,
            }),
        },
        sort: sort,
        phrase: search
          ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
          : "*",
        isAdmin: isAdmin,
        isAuth: isPremium,
        limit: limit || 30,
      },
    });

  return { loadingExport, dataExport, getExportData };
}

export function useGraphQLCompaniesList({ canStart = true }) {
  const [runQuery, { loading, error, data }] = useLazyQuery<{
    organizations: IOrganizationEntity[];
  }>(
    gql`
      query getOrganizationsList($where: EntityWhere) {
        organizations: entities(where: $where) {
          id
          name
        }
      }
    `,
    {
      variables: {
        where: {
          entityType_INCLUDES: "ORGANIZATION",
          vertical_INCLUDES: config.VERTICAL,
        },
      },
    }
  );

  useEffect(() => {
    if (canStart) runQuery().then();
  }, [canStart, runQuery]);

  return { loading, error, data };
}

export function useEntitiesUserContextOptionsQuery({
  name,
  ...rest
}: {
  name: string | undefined;
} & QueryFunctionOptions<
  {
    entities: Partial<IEntity>[];
  },
  {
    phrase: string | undefined;
    where: {
      entityType_INCLUDES?: string;
      vertical_INCLUDES: string | undefined;
    };
  }
>) {
  const { loading, error, data } = useQuery<
    {
      entities: Partial<IEntity>[];
    },
    {
      phrase: string | undefined;
      where: {
        entityType_INCLUDES?: string;
        vertical_INCLUDES: string | undefined;
      };
    }
  >(
    gql`
      query getEntityOptions($where: EntityWhere, $phrase: String!) {
        entities: entitiesFulltextFullTextSearchFields(
          phrase: $phrase
          where: { score: { min: 0.5 }, entity: $where }
          sort: { score: DESC }
          limit: 10
        ) {
          score
          entity {
            id
            name
            logo
            vertical
            entityType
            employees {
              id
              email
              firstName
              lastName
              createdAt
              profilePic
              adminOf {
                id
              }
            }
            hasSubscription {
              id
              inTrial
              customPrice
              startsAt
              expiresAt
              members {
                id
                firstName
                lastName
                profilePic
                createdAt
                email
                jobTitle
                aboutMe
              }
              admins {
                id
                firstName
                lastName
                profilePic
                createdAt
                email
                jobTitle
                aboutMe
              }
              type {
                id
                name
                price
                maxSeats
                minSubscriptionMonths
                createdAt
              }
            }
          }
        }
      }
    `,
    {
      context: { api: "userProfile" },
      variables: {
        phrase: name?.replace("-", " ") + "*" || "",
        where: {
          vertical_INCLUDES: config.VERTICAL,
        },
      },
      ...rest,
    }
  );
  return { loading, error, data };
}
