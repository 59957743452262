import classnames from "classnames";

import { IFilterType } from "types";

import { Input } from "components/Input";
import { FilterUpdateFunc } from "../../Filter.types";

interface IProps {
  column: string;
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function PlainInput({
  column,
  checkedTags,
  columnName,
  showSectorHeader = false,
  sectorHeader,
  onCheck,
}: IProps) {

  const value = checkedTags.length ? checkedTags[0] : '';

  return (
    <div className="flex flex-col h-full overflow-hidden mt-2">
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="flex mb-1 relative h-full">
        <div
          className={classnames("h-full overflow-hidden  bg-primary ")}
        >
          <div className="text-primary text-[14px] font-bold pb-[6px]">
            {columnName}
          </div>
          <div
            className={classnames(
              "flex flex-row justify-start items-center bg-primary mt-2"
            )}
          >
            <Input
              maxLength={4}
              placeholder=""
              className="bg-primary border border-primary w-[340px] h-[40px] mr-[5px] rounded-md outline-none p-4 text-[#98A0A6] text-[12px]"
              type="number"
              value={value}
              onChange={(e) => onCheck(column, column, e.target.value)}
              min={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
