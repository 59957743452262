import Select, { GroupBase, OptionProps, components } from "react-select";
import { Fragment, useEffect, useState } from "react";
import { InputBase, Modal } from "@mui/material";
import classNames from "classnames";
import PatentsCheck from "./PatentsCheck";
import { PatentsInputProps } from "../PatentsStats/PatentsStats.types";

// TODO: refactor more and maybe move PatentsInput/KeywordSelect to common components and refactor it, we are using it for patents, newsfeed and media monitoring

const PatentsInput = ({
  value,
  onChange,
  keywordsValue,
  placeholder,
  style,
  isLoading,
  handleSubmitData,
  handleClear,
}: PatentsInputProps) => {
  const [openPopupState, setOpenPopupState] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [inputValue, setInputValue] = useState(keywordsValue || "");

  const countOfOption = 5;

  const isRenderMoreButton = value && value.length > countOfOption;
  const valuesLength = value.length;
  const isHasValues = keywordsValue || value.length > 0;

  useEffect(() => {
    setInputValue(keywordsValue);
  }, [keywordsValue]);

  const inputBaseStyles = {
    width: isHasValues ? "unset" : "600px",
    flex: isHasValues ? "1 1 auto !important" : "unset !important",
    input: {
      width: isHasValues ? "100%" : "600px",
    },
  };

  const handlePopupState = () => {
    setOpenPopupState((prevState) => !prevState);
  };

  const handleOpenState = () => {
    setOpenState((prevState) => !prevState);
  };

  const handleChange = (newValue) => {
    if (keywordsValue && newValue.length > 1) {
      const lastIndex = newValue.length - 1;
      const newArray = [...value];
      newArray.push({
        value: keywordsValue,
        label: keywordsValue,
      });
      onChange([...newArray, newValue[lastIndex]]);
      setInputValue("");
    } else {
      onChange([...newValue]);
    }

    setOpenState(false);
  };

  const handlePressKey = (key) => {
    const keyPress = "Backspace";
    const keyEnter = "Enter";
    const keyCtrlOrCmd = key.ctrlKey || key.metaKey;

    if (key?.code === keyEnter && inputValue) {
      const valueList = [...value];

      const keywords = inputValue.match(/"[^"]*"|\S+|[()]/g);
      keywords?.forEach((keyword) => {
        if (
          keyword.toUpperCase() === "OR" ||
          keyword.toUpperCase() === "AND" ||
          keyword.toUpperCase() === "NOT"
        ) {
          valueList.push({
            value: keyword.toUpperCase(),
            label: keyword.toUpperCase(),
          });
          return;
        }
        valueList.push({ value: keyword, label: keyword });
      });

      onChange(valueList);
      setInputValue("");
    }

    if (key?.code === keyPress && !inputValue && value.length > 0) {
      const valueArray = [...value];
      valueArray.pop();
      onChange(valueArray);
    }

    // Submit on ctrl/command + enter
    if (keyCtrlOrCmd && key?.code === keyEnter) {
      handleSubmitData();
      handleOpenState();
    }
  };

  const handleRemoveValue =
    (itemIndex, isChangeState = false) =>
    (event) => {
      const oldValuesState = [...value];
      oldValuesState.splice(itemIndex, 1);
      onChange(oldValuesState);

      isChangeState && handleOpenState();
    };

  const handleMore = () => {
    handlePopupState();
    handleOpenState();
  };

  const renderMoreButton = isRenderMoreButton ? (
    <button
      onClick={handleMore}
      className="text-[12px] px-2 py-[2px] rounded text-[#7b868e] bg-[#e6eff5] ml-1"
    >
      +{valuesLength - countOfOption} more
    </button>
  ) : null;

  const renderClearAll = (
    <button
      className="text-[#82888C] absolute right-16 text-center z-10"
      onClick={handleClear}
    >
      Clear
    </button>
  );

  const renderInputOptions =
    value &&
    value?.map((item, index) => {
      let labelColor = "";
      if (item.label === "AND") {
        labelColor = "!bg-[#10BBB9] !text-[10px]";
      }
      if (item.label === "OR") {
        labelColor = "!bg-[#3E76DC] !text-[10px]";
      }
      if (item.label === "NOT") {
        labelColor = "!bg-[#AF56D9] !text-[10px]";
      }
      if (item.label === "(" || item.label === ")") {
        labelColor = "!bg-[#718DCB] !text-[10px]";
      }

      return index < countOfOption ? (
        <div
          key={index}
          className={classNames(
            "flex items-center py-[2px] px-2 bg-[#E6EFF5] text-[#31414E] text-[12px] font-normal rounded-[47px] mx-1",
            labelColor,
            {
              "bg-transparent !text-white":
                item.label === "AND" ||
                item.label === "OR" ||
                item.label === "NOT" ||
                item.label === "(" ||
                item.label === ")",
            }
          )}
        >
          <PatentsCheck
            key={index}
            index={index}
            onCheckboxChange={handleRemoveValue(index, true)}
            optionLabel={item.label}
            isChecked
          />
        </div>
      ) : null;
    });

  const renderInputContent =
    value && value.length > 0 ? (
      <Fragment>
        {renderInputOptions}
        {renderMoreButton}
        {renderClearAll}
      </Fragment>
    ) : null;

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
  };

  const renderTarget = (
    <div
      className="bg-tablePrimary text-primary h-[38px] min-w-[500px] md:w-[600px] max-w-full flex-wrap items-center px-3 text-[12px] w-full flex rounded"
      onClick={handleOpenState}
    >
      {renderInputContent}
      <InputBase
        className="text-[12px] select-none"
        onFocus={handleOpenState}
        onClick={handleOpenState}
        onChange={handleInputChange}
        value={inputValue}
        placeholder={value.length > 0 ? "" : placeholder}
        onKeyDownCapture={handlePressKey}
        sx={inputBaseStyles}
      />
      <span
        className="text-[12px] text-primaryBlue font-medium absolute right-4 z-20 cursor-pointer select-none"
        onClick={() => {
          handleSubmitData();
          handleOpenState();
        }}
      >
        Search
      </span>
    </div>
  );

  const renderOptionPopup = isHasValues ? (
    <Modal open={openPopupState} onClose={handlePopupState}>
      <div className="absolute top-[50%] left-[50%] outline-none max-h-[90%] p-4 overflow-y-auto translate-x-[-50%] translate-y-[-50%] bg-primary filter-popup w-[90%] !max-w-[800px] py-4 px-2 bg-primary text-primary rounded gap-2 !flex !flex-row !flex-wrap">
        {value?.map((item, index) => {
          let labelColor = "";
          if (item.label === "AND") {
            labelColor =
              "!bg-[#10BBB9] !text-[10px] !text-white leading-[100%] !py-0";
          }
          if (item.label === "OR") {
            labelColor =
              "!bg-[#3E76DC] !text-[10px] !text-white leading-[100%] !py-0";
          }
          if (item.label === "NOT") {
            labelColor =
              "!bg-[#AF56D9] !text-[10px] !text-white leading-[100%] !py-0";
          }
          if (item.label === "(" || item.label === ")") {
            labelColor =
              "!bg-[#718DCB] !text-[10px] !text-white leading-[100%] !py-0";
          }
          return (
            <div
              key={index}
              className={classNames(
                "flex items-center justify-center py-[2px] px-2 bg-[#E6EFF5] text-[#31414E] text-[12px] font-normal rounded-[47px]",
                labelColor
              )}
            >
              <PatentsCheck
                optionLabel={item.label}
                onCheckboxChange={handleRemoveValue(index)}
                isChecked
                index={index}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  ) : null;

  return (
    <>
      <Fragment>
        <DropdownRoot
          open={openState}
          onClose={handleOpenState}
          target={renderTarget}
          style={style}
          value={value}
          onChange={onChange}
        >
          <Select
            value={value}
            onChange={handleChange}
            blurInputOnSelect
            isLoading={isLoading}
            styles={{
              control: (provided) => ({
                ...provided,
                border: "none",
                margin: "4px 7px",
                fontFamily: "Roboto",
                fontSize: "12px",
                lineHeight: "1.2",
                color: "#98A0A6",
                boxShadow: "none",
              }),
              placeholder: (defaultStyles) => ({
                ...defaultStyles,
                fontFamily: "Roboto",
                fontSize: "12px",
                lineHeight: "1.2",
                color: "#98A0A6",
              }),
              menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
            }}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={true}
            isClearable={false}
            isMulti
            menuIsOpen
            tabSelectsValue={false}
            components={{
              DropdownIndicator,
              IndicatorSeparator: null,
              Option: (props: OptionProps<any, boolean, GroupBase<any>>) =>
                InputOption({ ...props }),
            }}
            classNamePrefix="react-select"
          />
        </DropdownRoot>
        {renderOptionPopup}
      </Fragment>
    </>
  );
};

function DropdownRoot({
  children,
  open,
  target,
  onClose,
  style,
  value,
  onChange,
}) {
  return <div style={{ position: "relative", ...style }}>{target}</div>;
}

function DropdownIndicator() {
  return (
    <div className="text-[#98A0A6] h-[20px] w-[24px]">
      <Svg>
        <path
          d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </Svg>
    </div>
  );
}

function Svg(p) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
}

function InputOption({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps<unknown, boolean, GroupBase<unknown>>) {
  const style = {
    backgroundColor: "#fff",
    alignItems: "center",
    gap: "0 12px",
    padding: "10px 16px",
    display: "flex ",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.4,
    color: "#31414E",
    cursor: "pointer",
  };

  const props = {
    ...innerProps,
    style,
  };

  return (
    <Fragment>
      <components.Option
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
        {...rest}
      >
        <PatentsCheck
          isChecked={isSelected}
          optionLabel={children}
          index={0}
          onCheckboxChange={() => {}}
        />
      </components.Option>
    </Fragment>
  );
}

export default PatentsInput;
