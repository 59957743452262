import config from "config";

export const CONSORTIUM_FIELDS = `
  inCountry {
    name
    region {
      id
      name
    }
  }
  city
  description
  goals
  lat
  lng
  logo
  partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
    id
    date
    description
    source
    updatedAt
    createdAt
    status
    vertical
    entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
      id
      name
      logo
      entityType
      vertical
    }
  }
  consortiumHasEntities {
    id
    logo
    name
    entityType 
    vertical
    __typename
  }
  id
  name
  website
  status
  createdAt
  updatedAt
`;