import config from "config";
import { gql, QueryFunctionOptions, useQuery } from "@apollo/client";
import { IEntity } from "types";

export function useEntitiesOptionsQuery({
  name,
  entityType,
  ...rest
}: {
  name: string | undefined;
  entityType?: string;
} & QueryFunctionOptions<
  {
    entities: Partial<IEntity>[];
  },
  {
    phrase: string | undefined;
    where: {
      entityType_INCLUDES?: string;
      vertical_INCLUDES: string | undefined;
    };
  }
>) {
  const { loading, error, data } = useQuery<
    {
      entities: Partial<IEntity>[];
    },
    {
      phrase: string | undefined;
      where: {
        entityType_INCLUDES?: string;
        vertical_INCLUDES: string | undefined;
      };
    }
  >(
    gql`
      query getEntityOptions($where: EntityWhere, $phrase: String!) {
        entities(
          options: { limit: 10 }
          where: $where
          fulltext: { FullTextSearchFields: { phrase: $phrase } }
        ) {
          id
          name
          logo
          vertical
          entityType
        }
      }
    `,
    {
      variables: {
        phrase: name?.replace("-", " ") + "*" || "",
        where: {
          // entityType_INCLUDES: entityType,
          ...(entityType && {
            entityType_INCLUDES: entityType,
          }),
          vertical_INCLUDES: config.VERTICAL,
        },
      },
      ...rest,
    }
  );
  return { loading, error, data };
}
