import { ApolloError, gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { ISelectOption, SelectOption } from "types";

export function usePatentsEntityDynamicProps({
  filterBy = "UO",
}: {
  filterBy?: "UO" | "INV";
}) {
  const [options, makeSearch, loading] = useDynamicProps({
    useQuery: useOrgsOptionsQuery,
    selector: (value: string) => {
      return {
        queryString: `query=${filterBy}=(${value})&fl=biblio&start=0&rows=5&group=minesoftfamily`,
        skip: !value || value.length < 2,
      };
    },
    buildOptions: (data) => {
      const uniqueOptions = new Set();
      const options = data?.patentQuery?.docs.flatMap((patent) => {
        if (!patent) return new SelectOption("", "");
        if (filterBy === "UO") {
          const ultimateOwner =
            patent?.ultimate_owner && JSON.parse(patent?.ultimate_owner);
          return (
            ultimateOwner?.map((owner) => {
              return new SelectOption(owner || "", owner || "");
            }) || []
          );
        }
        if (filterBy === "INV") {
          const inventors = patent?.inventors && JSON.parse(patent?.inventors);
          return (
            inventors?.map((inventor) => {
              return new SelectOption(
                inventor?.name || "",
                inventor.name || ""
              );
            }) || []
          );
        }
        return [];
      });

      options?.forEach((option) => uniqueOptions.add(JSON.stringify(option)));

      return Array.from(uniqueOptions)
        .map((option) => JSON.parse(option as string))
        .slice(0, 10);
    },
  });

  return {
    options,
    makeSearch,
    loading,
  };
}

interface IDynamicProps<TData, TProps> {
  useQuery: (props: TProps) => {
    loading: boolean;
    error: ApolloError | undefined;
    data: TData | undefined;
  };
  selector: (item: string) => TProps;
  buildOptions: (items: TData | undefined) => ISelectOption[];
}

function useDynamicProps<TData, TProps>({
  useQuery,
  selector,
  buildOptions,
}: IDynamicProps<TData, TProps>): [
  ISelectOption[],
  React.Dispatch<React.SetStateAction<string>>,
  boolean
] {
  const [item, setItem] = useState("");
  const { data, loading } = useQuery(selector(item));
  const options = useMemo(() => {
    return buildOptions(data);
  }, [buildOptions, data]);

  return [options, setItem, loading];
}

function useOrgsOptionsQuery({
  queryString,
  ...rest
}: {
  queryString: string | undefined;
}) {
  const { loading, error, data } = useQuery<{
    patentQuery: {
      docs: {
        ultimate_owner: string;
        inventors: string;
      }[];
    };
  }>(
    gql`
      query getPatentsEntityQuery($queryString: String!) {
        patentQuery(queryString: $queryString) {
          docs {
            ultimate_owner
            inventors
          }
        }
      }
    `,
    {
      variables: {
        queryString: queryString,
      },
      ...rest,
    }
  );
  return { loading, error, data };
}
