import { gql } from "@apollo/client";
import config from "config";

export const GET_PATENTS = gql`
  query getPatentsQuery($queryString: String!) {
    patentQuery(queryString: $queryString) {
      numFound
      docs {
        id
        titles
        titles_mt
        ultimate_owner
        inventors
        abstracts
        abstracts_mt
        publicationdate
        applicationdate
        familydata
        patentstatuses
        expectedexpiryexplanation
      }
    }
  }
`;

export const GET_PATENT_LINKS = gql`
  query getPatentLinks($queryString: String!) {
    patentLink(queryString: $queryString) {
      id
      FamilytreeURL
      PdfURL
    }
  }
`;

export const GET_PLATFORM_ENTITIES = gql`
  query getPlatformEntities {
    entities(where: {
      vertical_INCLUDES: ${config.VERTICAL}, 
      status: APPROVED,
      entityType_INCLUDES: ORGANIZATION
    }) {
      id
      name
      entityType
    }
  }
`;

export const GET_PATENT_STATISTICS = gql`
  query getPatentStats(
    $queryStringCompanies: String!
    $queryStringCountries: String!
    $queryStringCountriesAndYears: String!
    $queryLastYear: String!
    $queryStringDeadAlive: String!
  ) {
    companies: PatentStats(queryString: $queryStringCompanies) {
      response
    }
    countries: PatentStats(queryString: $queryStringCountries) {
      response
    }
    countriesAndYears: PatentStats(queryString: $queryStringCountriesAndYears) {
      response
    }
    queryLastYear: PatentStats(queryString: $queryLastYear) {
      response
    }
    deadAlive: PatentStats(queryString: $queryStringDeadAlive) {
      response
    }
  }
`;
