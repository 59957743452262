import { useNavigate } from "react-router";
import { getNewestValue, updateValueInQueryString } from "./helper";
import { UpdateFunc } from "./types";

export const useMultipleOfArrayFilter = (
  fieldName,
  graphQLFilterName,
  optionsArray,
) => {
  const navigate = useNavigate();

  const value = getNewestValue(fieldName);

  const updateValue: UpdateFunc = (filterField, newValue, doClear) => {
    const value = getNewestValue(fieldName);

    let updatedValue;
    if (doClear || value.includes(newValue as string)) {
      updatedValue = value.filter(e => e !== newValue);
    } else {
      updatedValue = [ ...value, newValue ];
    }

    navigate({ search: updateValueInQueryString(fieldName, updatedValue) });
  }

  const graphqlParams = {
    [graphQLFilterName]: value && value.length
      ? value
      : undefined,
  };

  return {
    value,
    updateValue,
    options: optionsArray.map((s, i) => ({ id: i, tag: s })),
    graphqlParams,
  };
};
