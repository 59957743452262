import React from "react";
import classnames from "classnames";

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  customRef?: React.MutableRefObject<HTMLInputElement | null>;
}

function Input({ className, customRef, ...rest }: IProps) {
  const classes = classnames(className, "focus:placeholder-opacity-0");

  return <input ref={customRef} {...rest} className={classes} />;
}

export default Input;
