import { memo } from "react";
import { useLocation } from "react-router";

import { matchPath } from "react-router";

import classNames from "classnames";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  path?: string;
  className?: string;
}

function Tab({ path, children, className, ...rest }: IProps) {
  const location = useLocation();
  let match;
  if (path) {
    match = matchPath({ path, end: true }, location.pathname);
  }

  return (
    <div
      className={classNames(
        "border-primary min-w-[50px] w-full md:w-[190px] md:max-w-[190px] min-h-[48px] max-h-[52px] flex justify-center rounded break-words",
        {
          "border bg-tablePrimary text-primaryBlue font-bold": !match,
        },
        {
          "border-0 border bg-primaryBlue text-white font-bold": match,
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
Tab.defaultProps = {
  className: "p-2",
};
export default memo(Tab);
