import config from "config";

export const UNIVERSITY_FIELDS = `
id
name
status
city
lat
lng
linkedIn
logo
memberOfConsortiums(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
  id
  name
}
inCountry {
  name
  region {
    id
    name
  }
}
founded
description
spinouts(where: {vertical_INCLUDES:${config.VERTICAL}}) {
  id
  name
  logo
  vertical
  createdAt
  updatedAt
  description
}
partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) @include(if: $isAuth) {
  id
  date
  description
  source
  updatedAt
  createdAt
  status
  vertical
  entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
    id
    name
    logo
    entityType
    vertical
  }
}
employeeRange
website
raised
lastUpdatedBy @include(if: $isAdmin)
updatedAt
createdAt
${config.USER_INTEREST_IN_FIELD}
vertical
parentOf(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
  id
  name
}
${config.KEY_SOURCES_FIELD}
`;
