import { memo, useCallback } from "react";
import { FilterModal } from "components/FilterModal";
import {
  columnsUseCases,
  useCasesSearchTabsStructure,
} from "./UseCases.constants";

import { useToggleModal } from "hooks/useToggleModal";
import { useTagFilter } from "./UseCases.hooks";

import { TableExtendedDraggable } from "components/TableExtended";
import { APP_ROUTES } from "const";
import { useNavigate } from "react-router";
import { useMapFilterPanelData } from "hooks/useMapFilterPanelData";
function UseCases() {
  const { toggleModal, isModalShowing } = useToggleModal();

  const { filterPanelData, filters, onChangeFilter, useCases, status } =
    useTagFilter();

  const navigate = useNavigate();

  const mappedFilterData = useMapFilterPanelData(filterPanelData, filters);

  return (
    <div className="office flex flex-col w-full bg-primary">
      <div className="my-2 w-full">
        <div className="flex flex-wrap w-full">
          <div className="overflow-auto w-full">
            <TableExtendedDraggable
              classNames={{
                table: "text-[12px] bg-primary",
                headTh: "text-[12px] bg-tablePrimary text-secondary",
                tr: "min-w-full",
                td: "text-primary",
              }}
              premiumAccess={true}
              showFiltering={true}
              showPendingSwitch={false}
              toggleFilteringModal={toggleModal}
              showPagination
              showPageSizeSelector
              queryStatus={status}
              showColumnVisibility
              showEntriesCount
              totalCount={useCases?.useCasesAggregate?.count}
              data={useCases?.useCases || []}
              columns={columnsUseCases}
              tableName="Use Cases"
              buttonAddNewText="Add Use Case"
              onAdd={useCallback(() => {
                navigate(APP_ROUTES.USE_CASE_NEW);
              }, [navigate])}
            />
            {filterPanelData && mappedFilterData && (
              <FilterModal
                filterData={mappedFilterData}
                childrenTabs={useCasesSearchTabsStructure}
                isShowing={isModalShowing}
                onHide={toggleModal}
                checkedTags={filters}
                onCheckTagToggle={onChangeFilter}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(UseCases);
