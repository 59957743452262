import React, { memo } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { IFilterData, IFilteredTags } from "types";
import classnames from "classnames";
import CloseLight from "assets/images/close-light.svg";
import FilterActive from "./FilterActive.component";
import { Button } from "components/Button";
import { FilterMapper } from "./FilterMapper.component";
import { FilterUpdateFunc } from "../Filter.types";

interface ITagGroupFilter {
  groupName: string;
  hint: string;
  showHeader: boolean;
  filterIds: Array<string | string[]>;
  filterNames: string[] | null | undefined;
  groupStyle: string;
}
interface ISearchTab {
  tabId: number;
  tabLabel: string;
  tagGroupFilter: ITagGroupFilter[];
}
interface ISearchTabArray extends Array<ISearchTab> {}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  childrenPanels?: IFilterData[];
  childrenTabs: ISearchTabArray;
  childrenFilterBlock?: React.ReactNode | React.ReactNodeArray;
  checkedTags: IFilteredTags;
  onClearTags: () => void;
  onHide: () => void;
  onCheckTagToggle: FilterUpdateFunc;
}

function FilterTabComponent({
  childrenPanels,
  checkedTags,
  childrenTabs,
  onHide,
  onClearTags,
  onCheckTagToggle,
}: IProps) {
  return (
    <Tabs
      className="flex flex-col md:flex-row w-full max-w-[1048px] lg:min-w-[800px] md:h-[568px] overflow-auto relative"
      defaultIndex={0}
    >
      <div className={classnames("absolute  right-0 pt-6 pr-6 z-50")}>
        <button
          type="button"
          className=""
          data-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        >
          <img alt="Close" src={CloseLight} />
        </button>
      </div>

      {childrenTabs.length > 1 && (
        <TabList className="flex flex-col pt-8 h-full items-center min-w-[200px] bg-tablePrimary">
          {childrenTabs?.map((tabItem, i) => (
            <Tab
              selectedClassName={"bg-primaryBlue rounded-md text-white"}
              className={classnames(
                "w-[170px] pl-5 text-left text-[16px] font-bold p-2 pr-3 cursor-pointer text-primary"
              )}
              key={i}
            >
              {tabItem.tabLabel}
            </Tab>
          ))}
        </TabList>
      )}

      <div className="bg-primary flex flex-col w-full h-full overflow-auto max-w-[100%] md:max-w-[1046px] ml-2 md:max-h-full">
        <div className="overflow-y-auto w-full h-full max-w-[1046px]">
          {childrenTabs?.map((tabItem, tabIndex) => (
            <TabPanel
              className={classnames(
                "grid grid-flow-row auto-rows-min  relative",
                {
                  "pt-7": tabIndex === 0,
                  "grid-cols-2": tabItem.tabLabel === "Funding",
                }
              )}
              key={"tab-panel-" + tabIndex}
            >
              {tabItem.tagGroupFilter.map((tagGroupItem, tagGroupItemId) => {
                const filterIds = tagGroupItem.filterIds;
                const filterNames = tagGroupItem.filterNames;
                return (
                  <div
                    // todo: refactor this piece
                    className={classnames({
                      "col-span-2":
                        tagGroupItem.groupName === "Investors" ||
                        tagGroupItem.groupName === "Last funding amount" ||
                        tagGroupItem.groupName === "Total funding raised",
                    })}
                  >
                    {tagGroupItem.showHeader && (
                      <div
                        className={classnames(
                          "flex flex-col justify-start ml-1 md:ml-8 ",
                          { "mt-5": tagGroupItemId > 0 }
                        )}
                      >
                        <p className="font-bold text-[20px] text-primary">
                          {tagGroupItem.groupName}
                        </p>
                        <p
                          className={classnames(
                            "font-normal text-[12px] text-[#98A0A6] mt-[10px]",
                            { hidden: !tagGroupItem.hint }
                          )}
                        >
                          {tagGroupItem.hint}
                        </p>
                      </div>
                    )}
                    <div
                      className={classnames("grid grid-flow-row gap-6 mx-8", {
                        //auto-cols-min
                        "grid grid-flow-col mt-[10px] !gap-3":
                          tagGroupItem.groupStyle === "col",
                        "grid-cols-2 mr-[90px]":
                          tagGroupItem.groupName === "Investors",
                        "grid-flow-col": tagGroupItem.groupStyle === "input",
                      })}
                    >
                      {filterIds.map((filterId, ind) => {
                        let checkedData;
                        let data;
                        if (typeof filterId === "string") {
                          checkedData = checkedTags[filterId];
                          data = childrenPanels?.find(
                            (p) => p.column === filterId
                          )?.data;
                        } else {
                          checkedData = filterId.map((id) => checkedTags[id]);
                          data = filterId.map(
                            (id) =>
                              childrenPanels?.find((p) => p.column === id)?.data
                          );
                        }
                        return (
                          <FilterMapper
                            column={filterId}
                            columnName={filterNames?.[ind]}
                            data={data}
                            checkedData={checkedData}
                            groupStyle={tagGroupItem.groupStyle}
                            onCheckTagToggle={onCheckTagToggle}
                            key={ind}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </TabPanel>
          ))}
        </div>
        <div
          className={classnames(
            "block w-full max-h-[150px] overflow-y-auto max-w-[1046px] pt-3 pb-3 border-b border-primary"
          )}
        >
          {Object.entries(checkedTags).map((item) => {
            return item[1].map((tag: string) => {
              if (tag?.length > 0) {
                return (
                  <FilterActive
                    checkedTag={item[0]}
                    item={tag}
                    onCheckTagToggle={onCheckTagToggle}
                  />
                );
              }
              return null;
            });
          })}
        </div>
        <div className="flex flex-row justify-end items-end p-3">
          <Button
            customPaddings
            className="flex w-[150px] rounded-md h-[40px] bg-primaryBlue text-white font-bold text-[12px] items-center justify-center p-1 ml-3"
            onClick={onHide}
          >
            Apply
          </Button>
          <Button
            customPaddings
            className="flex w-[150px] rounded-md h-[40px] border hover:bg-primaryBlue hover:text-white border-primaryBlue bg-primary text-primaryBlue font-bold text-[12px] items-center justify-center p-1 ml-3"
            onClick={() => {
              onClearTags();
            }}
          >
            Clear
          </Button>
          <Button
            customPaddings
            className="flex w-[150px] rounded-md h-[40px] hover:bg-primaryBlue hover:text-white border border-primaryBlue bg-primary text-primaryBlue font-bold text-[12px] items-center justify-center p-1 ml-3"
            onClick={() => {
              onClearTags();
              onHide();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Tabs>
  );
}

export default memo(FilterTabComponent);
