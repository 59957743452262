import { memo, useCallback, useEffect, useMemo } from "react";
import TableExtendedDraggable from "components/TableExtended/TableExtendedDraggable.component";
import { useLocation } from "react-router";
import { useCheckPremium } from "utils";
import { useMapPatentsData, usePatentFilters } from "./Patents.hooks";
import { columnsPatents } from "./Patents.constants";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PatentQuery, PlatformEntities } from "./Patents.types";
import PatentsKeywordsSearch from "./input/PatentsKeywordsSearch";
import { transformQueryObject } from "./helpers/Patents.utils";
import { GET_PATENTS, GET_PLATFORM_ENTITIES } from "./Patents.queries";
import PatentsFilter from "./filters/PatentsFilter";
import { APP_ROUTES } from "const";
import PatentsListView from "./PatentsListView/PatentsListView";

function Patents() {
  const isPremium = useCheckPremium();
  const location = useLocation();

  const {
    pdParamFrom,
    pdParamEnd,
    organizationQuery,
    inventorsQuery,
    sortBy,
    filtersState,
    setFiltersState,
    limit,
    offset,
    defaultKeywords,
  } = usePatentFilters({});

  const [getPatentsData, { data: patentData, loading: patentsLoading }] =
    useLazyQuery<PatentQuery>(GET_PATENTS);

  const { data: platformEntitiesData, loading: platformEntitiesLoading } =
    useQuery<PlatformEntities>(GET_PLATFORM_ENTITIES);

  const filtersString = useMemo(
    () =>
      `${pdParamFrom}${pdParamEnd}${organizationQuery}${inventorsQuery}&fl=biblio&start=${offset}&rows=${limit}&sort=${sortBy}&group=minesoftfamily`,
    [
      limit,
      offset,
      organizationQuery,
      inventorsQuery,
      pdParamEnd,
      pdParamFrom,
      sortBy,
    ]
  );

  const fetchPatentData = useCallback(() => {
    if (
      (filtersState.entities.length > 0 || organizationQuery !== "") &&
      (platformEntitiesData?.entities?.length ?? 0) > 0
    ) {
      getPatentsData({
        variables: {
          queryString:
            filtersState.entities.length > 0
              ? `query=atac=(${transformQueryObject(
                  filtersState.entities
                ).replace(/"/g, '\\"')})${filtersString}`
              : `query=${filtersString}`,
        },
      });
    }
  }, [
    filtersState.entities,
    filtersString,
    getPatentsData,
    organizationQuery,
    platformEntitiesData?.entities?.length,
  ]);

  useEffect(() => {
    fetchPatentData();
  }, [fetchPatentData]);

  const handleSubmitData = useCallback(() => {
    fetchPatentData();
  }, [fetchPatentData]);

  const { mappedPatents, isLinksLoading } = useMapPatentsData({
    patents: patentData?.patentQuery?.docs,
    platformEntities: platformEntitiesData?.entities,
  });

  const topPanel = (
    <>
      <PatentsKeywordsSearch
        filtersState={filtersState}
        setFiltersState={setFiltersState}
        handleSubmitData={handleSubmitData}
        isLoading={patentsLoading}
      />
      <PatentsFilter
        handleSubmitData={handleSubmitData}
        showSort={true}
        filtersState={filtersState}
        setFiltersState={setFiltersState}
        defaultKeywords={defaultKeywords}
      />
      {(platformEntitiesLoading || patentsLoading || isLinksLoading) && (
        <>
          <span className="text-[12px] font-normal text-secondary">
            {platformEntitiesLoading
              ? "Platform Entities Loading..."
              : patentsLoading
              ? "Patents Loading..."
              : isLinksLoading
              ? "Patent Links Loading..."
              : ""}
          </span>
        </>
      )}
    </>
  );

  return (
    <div className="flex flex-col w-full bg-primary">
      <div className="mx-4">
        <div className="my-2 w-full">
          <div className="flex flex-wrap w-full">
            <div className="overflow-auto w-full">
              {location.pathname === APP_ROUTES.PATENTS_LIST_VIEW ? (
                <PatentsListView
                  patents={patentData?.patentQuery?.docs}
                  platformEntities={platformEntitiesData?.entities}
                  totalCount={patentData?.patentQuery?.numFound || 0}
                  topPanel={topPanel}
                  isLoading={patentsLoading || platformEntitiesLoading}
                />
              ) : (
                <TableExtendedDraggable
                  classNames={{
                    table: "text-[12px] bg-primary mt-4",
                    headTh: "text-[12px] bg-tablePrimary text-secondary",
                    tr: "min-w-full",
                    td: "text-primary",
                    cardClassName: "!max-h-[calc(100vh-200px)]",
                    titleText: "flex-wrap",
                  }}
                  premiumAccess={isPremium}
                  showPageSizeSelector={isPremium}
                  showFiltering={false}
                  showColumnVisibility={true}
                  showHeader={true}
                  showAddButton={false}
                  showPendingSwitch={false}
                  showResizing={false}
                  enableSearch={false}
                  showPagination
                  showEntriesCount
                  isSortable
                  totalCount={patentData?.patentQuery?.numFound || 0}
                  data={mappedPatents || []}
                  columns={columnsPatents}
                  tableName="Patents"
                  showTableName={false}
                  queryStatus={
                    patentsLoading || platformEntitiesLoading
                      ? "loading"
                      : "success"
                  }
                  customPageSizes={[10, 20, 30]}
                  customLimit={limit}
                  beforeTitle={topPanel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(Patents);
