import config from "config";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { IInvestorTypeEntity } from "types";

const TYPE_FIELDS = `
  id
  name
  description
`;

const GET_TYPE = gql`
  query getInvestorTypes {
    tags (where: {tagType: INVESTOR_TYPE, vertical_INCLUDES: ${config.VERTICAL}}) {
      ${TYPE_FIELDS}
    }
  }
`;

export function useGraphQLInvestorType({ canStart = true } = {}) {
  const [runQuery, { loading, error, data }] = useLazyQuery<{
    tags: Array<IInvestorTypeEntity>;
  }>(GET_TYPE);

  useEffect(() => {
    if (canStart) runQuery().then();
  }, [canStart]);

  return { loading, error, data };
}
