import { Doughnut } from "react-chartjs-2";
import { PatentsChartDoughnutData } from "./PatentsStats.types";
import { ChartOptions, Plugin } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { AnyObject } from "chart.js/types/basic";
import { doughnutChartOptions } from "./PatentsStats.constants";

export const PatentsStatsDoughnut = ({
  barLabels,
  barDatasets,
}: PatentsChartDoughnutData) => {
  return (
    <div className="flex flex-col bg-tablePrimary rounded p-3 justify-between">
      <div className="text-primary text-[18px] font-medium p-1 flex">
        Status
        {/* <span className="text-[10px] text-secondary mt-1">*</span> */}
      </div>
      <div className="w-[224px] h-[224px] p-2 mb-8">
        <Doughnut
          data={{
            labels: barLabels,
            datasets: barDatasets,
          }}
          options={doughnutChartOptions as ChartOptions<"doughnut">}
          plugins={[ChartDataLabels as Plugin<"doughnut", AnyObject>]}
        />
      </div>
      {/* <span className="text-[10px] text-secondary">
        *Patent status is not available for all the patents
      </span> */}
    </div>
  );
};
