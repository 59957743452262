import config from "config";

export const GOVERNMENT_FIELDS = `
id
name
partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) @include(if: $isAuth) {
  id
  date
  description
  source
  updatedAt
  createdAt
  status
  vertical
  entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
    id
    name
    logo
    entityType
    vertical
  }
}
inCountry {
  id
  name
  region {
    id
    name
  }
}
description
employeeRange
founded
lat
# legalName
linkedIn
lng
logo
# parent
raised
# type
entityType
website
city
status
createdAt
lastUpdatedBy @include(if: $isAdmin)
updatedAt
${config.KEY_SOURCES_FIELD}
`;
