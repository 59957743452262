import { gql, useQuery } from "@apollo/client";
import { IAggregate, ICountryEntity, IGovernmentEntity } from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import { GOVERNMENT_FIELDS } from "./Government.constants";
import config from "config";
import { ENTITY_STATUSES } from "const";

const GET_GOVERNMENTS = gql`
  query getGovernmentEntities(
    $where: EntityWhere
    $sort: [EntitySort!]
    $limit: Int
    $offset: Int
    $phrase: String!
    $isAdmin: Boolean!
    $isAuth: Boolean!
  ) {
    governmentEntitiesAggregate: entitiesAggregate(where: $where, fulltext: { FullTextSearchFields: { phrase: $phrase } }) {
			count
		}
    governmentEntities: entities(
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      fulltext: { FullTextSearchFields: { phrase: $phrase } }
      ) {
      ${GOVERNMENT_FIELDS}
    }    
    countries @include(if: $isAuth) {
      name
    }
    cityList @include(if: $isAuth)
  }
`;

type GovernmentVariableType = {
  // where?: {
  //   name_IN?: string[];
  //   id?: string;
  //   status?: string;
  // };
  where?: any;
  sort?: {};
  limit?: number;
  offset?: number;
  search?: string | null;
  phrase?: string;
  isAdmin?: boolean;
  isAuth?: boolean;
};

export function useGraphQLGovernments({
  limit,
  offset,
  sort,
  where,
  search,
}: GovernmentVariableType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<
    {
      governmentEntities: Array<IGovernmentEntity>;
      governmentEntitiesAggregate: IAggregate<{}>;
      countries: Partial<ICountryEntity>[];
      cityList: string[];
    },
    GovernmentVariableType
  >(GET_GOVERNMENTS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        OR: [
          { entityType_INCLUDES: "GOVERNMENT_AGENCY" },
          { entityType_INCLUDES: "NATIONAL_LAB" },
        ],
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
    },
  });

  return { loading, error, data };
}
