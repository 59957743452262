import classnames from "classnames";
import { memo, useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  VisibilityState,
} from "@tanstack/react-table";
import { useColumnStructure, useSorting } from "./TableExtended.hooks";
import ColumnVisibilityModal from "./components/ColumnVisibilityModal";
import { Card } from "components/Card";
import { Checkbox } from "components/Checkbox";
import { IOutletAuth } from "types";
import { ACCESS_LEVEL } from "const";
import { useCompanyColumnVisibilityModal } from "./TableExtended.hooks";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from "react-loader-spinner";
import { checkRoleOnPermission, useCheckPremium } from "utils";

import "./TableExtended.styles.css";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { TableManagementPanel } from "./components/TableManagementPanel";
import { PremiumBluredBlock } from "components/PremiumCards";
import THeadItem from "./components/THeadItem";
import PaginationBlock from "./components/PaginationBlock";
import PageSizeSelector from "./components/PageSizeSelector";
import config from "config";
import { TableExtendedDraggableProps } from "./TableExtended.types";

function TableExtendedDraggable({
  columns,
  data,
  classNames,
  isSortable = true,
  showHeader = true,
  showColumnVisibility = false,
  enableSearch = false,
  showPageSizeSelector = false,
  showPagination = false,
  showTableName = true,
  showEntriesCount = false,
  showFiltering = false,
  premiumAccess = false,
  isPreviousData = false,
  footer,
  tableName,
  totalCount,
  queryStatus,
  previous = false,
  next = true,
  toggleFilteringModal,
  onAdd,
  headerChildren,
  showPendingSwitch = true,
  showAddButton = true,
  onRowClick,
  hasAutoWidthColumn = false,
  customLimit,
  customPageSizes = undefined,
  beforeTitle,
  buttonAddNewText = undefined,
  showResizing = true,
  showExportButton = false,
  loadingExport,
  dataExport,
  getExportData,
}: TableExtendedDraggableProps) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    Object.fromEntries(columns.map((col) => [col.id, col.show || false]))
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(() =>
    columns.map((c) => c.id!)
  );

  const { toggleColumnVisibilityModal, isColumnVisibilityShowing } =
    useCompanyColumnVisibilityModal();

  const context = useOutletContext<IOutletAuth>();
  const roles = context.roles;
  const isPremium = useCheckPremium();

  const mappedColumns = useColumnStructure({
    columns,
    roles,
  });

  const defaultColumn = useMemo(
    () => ({
      minSize: 150,
      size: hasAutoWidthColumn ? undefined : 150,
      maxSize: 800,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { sorting, onSortingChange } = useSorting();

  const {
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    previousPage,
    getAllColumns,
    getVisibleFlatColumns,
    resetColumnSizing,
    setPageSize,
    getRowModel,
    toggleAllColumnsVisible,
  } = useReactTable({
    columns: mappedColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: "onChange",
    defaultColumn,
    state: {
      columnVisibility,
      sorting,
      columnOrder,
    },
    manualSorting: true,
    enableSortingRemoval: false,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const onSearch = (e) => {
    params.delete("offset");
    params.delete("searchEntity");
    params.append("searchEntity", e.target.value);
    navigate({ search: params.toString() });
  };

  const limit = Number(params.get("limit")) || customLimit || 30;
  const offset = Number(params.get("offset")) || 0;

  useEffect(() => {
    setPageSize(limit);
  }, [limit, setPageSize]);

  const currentPage =
    offset === 0 || limit === 0
      ? 1
      : offset === limit
      ? 2
      : Number(offset) / Number(limit) + 1;

  const page = getRowModel().rows;
  const firstPageRows = page.slice(0, limit);

  const nonPremiumRows =
    !isPremium && page.length === 5
      ? [...page, ...page].slice(0, 10)
      : page.slice(0, 10);

  const rowsToRender = isPremium ? firstPageRows : nonPremiumRows;

  const [isResizeStarted, setIsResizeStarted] = useState(false);

  return (
    <>
      <Card
        title={showTableName ? tableName || "" : ""}
        beforeTitle={beforeTitle}
        className={classnames(
          classNames?.cardClassName,
          "max-h-[calc(100vh-130px)] overflow-auto"
        )}
        classNames={{
          title: classnames(
            classNames?.title,
            "!justify-start !w-full !gap-2 flex-col md:flex-row !py-0 mt-1 mb-3"
          ),
          titleContainer: "w-full justify-center md:w-fit whitespace-nowrap",
          titleText: classnames(classNames?.titleText, "xl:absolute left-4"),
          headerChildren: classnames(
            classNames?.headerChildren,
            "!justify-center md:!justify-end !w-full items-center flex xl:h-[36px]"
          ),
        }}
        headerChildren={
          showHeader &&
          (headerChildren || (
            <TableManagementPanel
              buttonAddNewText={
                buttonAddNewText ? buttonAddNewText : "Add Entities"
              }
              showColumnVisibility={showColumnVisibility}
              resetResizing={resetColumnSizing}
              toggleColumnVisibilityModal={toggleColumnVisibilityModal}
              showFiltering={showFiltering}
              toggleFilteringModal={toggleFilteringModal}
              disableAdvanced={queryStatus === "loading" || !data}
              queryStatus={queryStatus}
              enableSearch={enableSearch}
              onSearch={onSearch}
              onAdd={onAdd}
              isAdmin={checkRoleOnPermission({
                requiredRole: ACCESS_LEVEL.ADMIN,
                roles,
              })}
              showPendingSwitch={showPendingSwitch}
              showAddButton={showAddButton}
              isResizeStarted={isResizeStarted}
              setIsResizeStarted={setIsResizeStarted}
              tableName={tableName}
              showResizing={showResizing}
              showExportButton={showExportButton}
              loadingExport={loadingExport}
              dataExport={dataExport}
              getExportData={getExportData}
              columnVisibility={columnVisibility}
            />
          ))
        }
      >
        <table
          role="table"
          className={classnames(
            classNames?.table,
            "w-full overflow-auto rounded table-extended !bg-tablePrimary min-w-[1110px]"
          )}
        >
          <thead
            className={classnames(
              classNames?.thead,
              "sticky top-0 z-20 bg-tablePrimary"
            )}
          >
            {getHeaderGroups().map((headerGroup, i) => (
              <THeadItem
                key={i}
                visibleColumns={getVisibleFlatColumns()}
                setColumnOrder={setColumnOrder}
                headerGroup={headerGroup}
                classNames={classNames?.headTh}
                premiumAccess={premiumAccess}
                isSortable={isSortable}
                isPremium={isPremium}
                setIsResizeStarted={setIsResizeStarted}
              />
            ))}
          </thead>
          {queryStatus === "loading" || isPreviousData ? (
            <tbody>
              <tr>
                <td>
                  <div className="flex h-full justify-center items-center">
                    <Rings color="#00BFFF" height={80} width={80} />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className={classnames(classNames?.tbody, "relative")}>
              {rowsToRender.map((row, i) => {
                return (
                  <tr
                    key={row.id}
                    className={classnames("flex min-w-full", classNames?.tr, {
                      "bg-tablePrimary": i % 2,
                      "bg-tableSecondary": i % 2 === 0,
                      "hover:bg-tableSecondaryHover": row,
                      "cursor-pointer": !!onRowClick,
                    })}
                    onClick={() => {
                      onRowClick && onRowClick(row?.original);
                    }}
                    style={{
                      display: "flex",
                      flex: "1 0 auto",
                      minWidth: "1110px",
                    }}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <td
                        className={classnames(classNames?.td, {
                          "p-3 border-gray-100 text-[12px] leading-[1.15] font-normal text-primary table-td break-words":
                            cell,
                          "blur-sm select-none":
                            !isPremium &&
                            !cell.column.showForFreeUsers &&
                            tableName === "Companies",
                        })}
                        key={cell.id}
                        style={{
                          boxSizing: "border-box",
                          minWidth: cell.column.columnDef?.minSize,
                          maxWidth: cell.column.columnDef?.maxSize,
                          ...cell.column.columnDef.style,
                          flex: `${cell.column.getSize()} 0 auto`,
                          width: cell.column.getSize(),
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {!isPremium && (
                <PremiumBluredBlock
                  className={classnames(
                    "hidden",
                    {
                      "!flex w-[100%] pt-[200px]":
                        (tableName === "Companies" ||
                          tableName === "Recent Partnerships" ||
                          tableName === "Investors" ||
                          tableName === "Funding" ||
                          tableName === "Groups and Centers" ||
                          tableName === "Universities" ||
                          tableName === "Enterprise Users" ||
                          tableName === "Government Entities") &&
                        page.length >= 5,
                    },
                    {
                      "!flex h-full bottom-0 pt-4":
                        tableName === "Fundings" && page.length > 1,
                    },
                    {
                      "!flex h-full bottom-0 pt-4 items-center":
                        (tableName === "Government Funding" ||
                          tableName === "QCaaS" ||
                          tableName === "QPU" ||
                          tableName === "Use Cases") &&
                        page.length > 1,
                    }
                  )}
                />
              )}
            </tbody>
          )}
          {footer && (
            <tfoot
              className={classnames(
                classNames?.tfoot,
                "bg-[#31414E] w-full mb-4"
              )}
            >
              {footer}
            </tfoot>
          )}
        </table>
      </Card>
      {isPremium && (
        <div
          className={classnames(
            "flex justify-between bg-tablePrimary py-2 border-t-[1px] border-primary flex-wrap md:flex-nowrap",
            {
              hidden: !showEntriesCount,
            }
          )}
        >
          <div
            className={classnames(
              {
                " hidden ": !showEntriesCount,
              },
              "flex flex-row items-center mx-4 md:w-[280px]"
            )}
          >
            {premiumAccess ? (
              <div className="text-[12px] text-[#98A0A6]">
                {totalCount && totalCount > 0 ? (
                  <p>
                    Showing {offset === 0 ? 1 : offset} to{" "}
                    {Number(limit) + Number(offset)} of {totalCount} entries
                  </p>
                ) : (
                  <p>No records to show</p>
                )}
              </div>
            ) : (
              <div className="font-medium text-[14px] text-secondary">
                {totalCount && `Showing 1 to 10 of ${totalCount} entries`}
              </div>
            )}
          </div>

          {showPageSizeSelector && (
            <PageSizeSelector
              disabled={!premiumAccess}
              limit={limit}
              params={params}
              sizes={customPageSizes}
            />
          )}

          <PaginationBlock
            premiumAccess={premiumAccess}
            showPagination={showPagination}
            previous={previous}
            params={params}
            offset={offset}
            limit={limit}
            isPreviousData={isPreviousData}
            currentPage={currentPage}
            next={next}
            canPreviousPage={getCanPreviousPage()}
            canNextPage={getCanNextPage()}
            previousPage={previousPage}
            totalCount={totalCount}
          />
        </div>
      )}
      <ColumnVisibilityModal
        showAllColumns={() => toggleAllColumnsVisible(true)}
        hideAllColumns={() => toggleAllColumnsVisible(false)}
        isShowing={isColumnVisibilityShowing}
        onHide={toggleColumnVisibilityModal}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-2 gap-x-10 w-full h-full cols">
          {getAllColumns().map((column, index) => {
            if (
              checkRoleOnPermission({
                roles: roles,
                requiredRole:
                  column.columnDef.role || config.VERTICAL + "_User",
              })
            ) {
              return (
                <Checkbox
                  id={column.id}
                  key={index}
                  label={column.columnDef.label || ""}
                  onChange={column.getToggleVisibilityHandler()}
                  isSelected={column.getIsVisible()}
                />
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </ColumnVisibilityModal>
    </>
  );
}

export default memo(TableExtendedDraggable);
