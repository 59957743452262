import { ITableColumn } from "types";

export const columnsPatents: ITableColumn = [
  {
    header: "Title",
    accessorKey: "col0",
    id: "titles",
    label: "Title",
    show: true,
    enableSorting: false,
    minSize: 240,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Abstract",
    accessorKey: "col1",
    id: "abstracts",
    label: "Abstract",
    show: true,
    minSize: 300,
    enableSorting: false,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Publication Date",
    accessorKey: "col2",
    id: "publicationdate",
    label: "Publication Date",
    show: true,
    enableSorting: true,
    minSize: 120,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Application Date",
    accessorKey: "col10",
    id: "applicationdate",
    label: "Application Date",
    show: true,
    enableSorting: true,
    minSize: 120,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Organizations",
    accessorKey: "col3",
    id: "ultimate_owner",
    label: "Organizations",
    style: {
      width: 180,
      minWidth: 180,
    },
    show: true,
    enableSorting: true,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Inventors",
    accessorKey: "col4",
    id: "inventors",
    label: "Inventors",
    style: {
      width: 180,
      minWidth: 180,
    },
    show: true,
    enableSorting: false,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Patent PDF",
    accessorKey: "col5",
    id: "PdfURL",
    label: "Patent PDF",
    show: true,
    enableSorting: false,
    minSize: 120,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Related Patents",
    accessorKey: "col6",
    id: "relatedpatents",
    label: "Related Patents",
    style: {
      width: 180,
      minWidth: 180,
    },
    show: true,
    enableSorting: false,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Patent Family Tree",
    accessorKey: "col7",
    id: "familydata",
    label: "Patent Family Tree",
    show: true,
    enableSorting: false,
    minSize: 120,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Status",
    accessorKey: "col8",
    id: "patentstatuses",
    label: "Status",
    show: true,
    enableSorting: false,
    minSize: 100,
    cell: (cell) => cell.renderValue(),
  },
  {
    header: "Expiry Date",
    accessorKey: "col9",
    id: "expectedexpiryexplanation",
    label: "Expiry Date",
    show: true,
    enableSorting: false,
    minSize: 120,
    cell: (cell) => cell.renderValue(),
  },
];
