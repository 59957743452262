import { gql, useQuery } from "@apollo/client";
import { IRegionEntity } from "types";

const REGION_FIELDS = `
  id
  name
`;

const GET_REGION = gql`
  query getRegions{
    regions {
      ${REGION_FIELDS}
    }
  }
`;

export function useGraphQLRegion({
  skip = false,
}) {
  const { loading, error, data } = useQuery<
      {
        regions: Partial<IRegionEntity>[];
      }
    >(GET_REGION, {
    skip: skip,
  });

  return { loading, error, data };
}
