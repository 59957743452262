export const SUBSCRIPTION_FIELDS = `
  id
  vertical
  name
  inTrial
  isActive
  customPrice
  customMaxSeats
  duration
  startsAt
  expiresAt
  comment
  admins {
    id
    email
    firstName
    lastName
    profilePic
  }
  members {
    id
    email
    firstName
    lastName
    profilePic
  }
  organizationHasSubscription {
    id
    logo
    name
    entityType
    employees {
      id
      firstName
      lastName
    }
  }
  type {
    id
    name
    price
    maxSeats
    minSubscriptionMonths
    createdAt
  }
`;
