import { memo, useState } from "react";
import { useColumnsAdminDashboard } from "./AdminDashboard.hooks";
import { TableExtendedDraggable } from "components/TableExtended";
import { useCheckPremium } from "utils";
import { ThreeDots } from "react-loader-spinner";
import { useGraphQLAdminDashboardEntities } from "graphql-requests";
import { usePagination } from "hooks/usePagination";

function AdminDashboardEntities() {
  const isPremium = useCheckPremium();
  const { limit, offset } = usePagination();
  const { data: dashboardData, loading } = useGraphQLAdminDashboardEntities({
    limit,
    offset,
  });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const columns = useColumnsAdminDashboard({
    allIds: dashboardData?.entities?.map((item) => item.id) || [],
    selectedIds,
    setSelectedIds,
    tableType: "Entities",
  });

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center my-8">
          <ThreeDots color="#00649C" height={25} width={25} />
        </div>
      ) : (
        <TableExtendedDraggable
          classNames={{
            table: "text-[12px] bg-primary",
            headTh: "text-[12px] bg-tablePrimary text-secondary",
            tr: "min-w-full",
            td: "text-primary",
            cardClassName: "!max-h-[calc(100vh-190px)] mt-1",
          }}
          premiumAccess={isPremium}
          data={dashboardData?.entities || []}
          columns={columns}
          showHeader={false}
          queryStatus={loading ? "loading" : "success"}
          showPagination
          showPageSizeSelector
          showEntriesCount
          enableSearch
          totalCount={dashboardData?.entitiesAggregate?.count}
        />
      )}
    </>
  );
}

export default memo(AdminDashboardEntities);
