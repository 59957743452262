import { memo } from "react";
import PatentsInput from "./PatentsInput";
import { PatentsKeywordsSearchProps } from "../Patents.types";
import { useLocation, useNavigate } from "react-router";

const PatentsKeywordsSearch = ({
  filtersState,
  setFiltersState,
  handleSubmitData,
  isLoading,
}: PatentsKeywordsSearchProps) => {
  const handleChangeEntites = (newValue) => {
    setFiltersState({ ...filtersState, entities: newValue, keywords: "" });
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  return (
    <div className="flex items-left max-w-full flex-wrap gap-4">
      <div className="flex min-w-[500px] md:w-[600px] max-w-full">
        <PatentsInput
          placeholder="Search for keywords"
          value={filtersState?.entities}
          onChange={handleChangeEntites}
          keywordsValue={filtersState?.keywords}
          isLoading={isLoading}
          style={{ width: "100%" }}
          handleSubmitData={handleSubmitData}
          handleClear={() => {
            setFiltersState({ ...filtersState, entities: [], keywords: "" });
            params.set("restricted", "false");
            navigate({ search: params.toString() });
          }}
        />
      </div>
    </div>
  );
};

export default memo(PatentsKeywordsSearch);
