import config from "config";

export const CENTER_FIELDS = `
id
name
status
city
inCountry {
  name
  region {
    name
  }
}
lat
lng
description
focusAreas @include(if: $isAuth) {
    id
    name
}
founded
${config.FUNDING_FIELD}
logo
memberOfConsortiums(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
    id
    name
}
partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) @include(if: $isAuth) {
  id
  date
  description
  source
  updatedAt
  createdAt
  status
  vertical
  entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
    id
    name
    logo
    entityType
    vertical
  }
}
childOf {
    id
    name
}
website
lastUpdatedBy @include(if: $isAdmin)
updatedAt
createdAt
${config.KEY_SOURCES_FIELD}
`;

export const UPDATE_CENTER_FIELDS = `description: $description,
founded: $founded,
website: $website,
name: $name,
id: $id
`;

export const UPDATE_CENTER_PARAMS = `$description: String,
$founded: Int,
$website: String,
$name: String,
$id: ID!
`;

export const CREATE_CENTER_FIELDS = `description: $description,
founded: $founded,
website: $website,
name: $name,
`;

export const CREATE_CENTER_PARAMS = `$description: String,
$founded: Int,
$website: String,
$name: String,
`;
