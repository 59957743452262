import classNames from "classnames";
import { useNavigate } from "react-router";
import PatentFilterButton from "./PatentFilterButton";
import { PatentDateRange } from "./PatentFilterDate";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Modal } from "@mui/material";
import PatentFilterEntity from "./PatentFilterEntity";
import PatentFilterSort from "./PatentFilterSort";

const PatentFilterPopup = ({
  openFilters,
  onApplyFilters,
  onClose,
  showDateFilter = false,
  showSort = false,
  showOrganizationFilter = false,
  showInventorFilter = false,
  setFiltersState,
  defaultKeywords,
  customStartDate,
}: {
  openFilters: boolean;
  onApplyFilters: () => void;
  onClose: () => void;
  showDateFilter?: boolean;
  showSort?: boolean;
  showOrganizationFilter?: boolean;
  showInventorFilter?: boolean;
  setFiltersState: React.Dispatch<
    React.SetStateAction<{
      entities: {
        value: string;
        label: string;
      }[];
      keywords: string;
    }>
  >;
  defaultKeywords: {
    value: string;
    label: string;
  }[];
  customStartDate?: Date;
}) => {
  const navigate = useNavigate();

  const renderTopbar = (
    <div className="flex justify-between items-center w-full">
      <span className="text-[20px] text-primary font-bold">Filters</span>
      <CloseOutlinedIcon
        className="ml-auto cursor-pointer text-secondary"
        onClick={onClose}
      />
    </div>
  );

  const renderButtonsBar = (
    <div className="flex flex-row bg-tablePrimary py-2 px-4 rounded items-center mt-4 items-center justify-between">
      <div className="flex flex-row">
        <div
          className="text-primaryBlue text-[12px] cursor-pointer select-none font-bold hover:opacity-60"
          onClick={() => {
            navigate({ search: "" });
            setFiltersState({ entities: defaultKeywords, keywords: "" });
          }}
        >
          Reset
        </div>
      </div>

      <PatentFilterButton
        // label="Apply"
        label="Done"
        onClick={() => {
          // onApplyFilters();
          onClose();
        }}
      />
    </div>
  );

  const renderContent = (
    <div className="flex flex-col gap-4 mt-4">
      {showSort && (
        <FlexboxRow rowTitle="Sort by" className="bg-tablePrimary p-4 rounded">
          <div className="flex flex-row gap-2 select-none">
            <PatentFilterSort />
          </div>
        </FlexboxRow>
      )}
      {showDateFilter && (
        <FlexboxRow
          rowTitle="Published date range"
          className="bg-tablePrimary p-4 rounded"
        >
          <div className="flex flex-row justify-start items-center gap-2">
            <PatentDateRange
              label="From"
              paramName="startDate"
              currentDate={customStartDate || null}
              //  addDays(new Date(), -366)
            />
            <PatentDateRange
              label="To"
              paramName="endDate"
              currentDate={new Date()}
            />
          </div>
        </FlexboxRow>
      )}
      {showOrganizationFilter && (
        <FlexboxRow
          rowTitle="Organization"
          className="bg-tablePrimary p-4 rounded"
        >
          <div className="flex flex-row gap-2 select-none w-full">
            <PatentFilterEntity paramName="organization" filterBy="UO" />
          </div>
        </FlexboxRow>
      )}
      {showInventorFilter && (
        <FlexboxRow rowTitle="Inventor" className="bg-tablePrimary p-4 rounded">
          <div className="flex flex-row gap-2 select-none w-full">
            <PatentFilterEntity paramName="inventor" filterBy="INV" />
          </div>
        </FlexboxRow>
      )}
    </div>
  );

  return (
    <Modal open={openFilters} onClose={onClose}>
      <div className="absolute top-[50%] left-[50%] outline-none max-h-[90%] p-4 overflow-y-auto translate-x-[-50%] translate-y-[-50%] bg-primary filter-popup w-[650px] min-w-[364px] height-auto px-4 py-6 !justify-between">
        <div>
          {renderTopbar}
          {renderContent}
        </div>
        {renderButtonsBar}
      </div>
    </Modal>
  );
};

const FlexboxRow = ({
  children,
  rowTitle,
  showTitle = true,
  className,
}: {
  children: React.ReactNode;
  rowTitle: string;
  showTitle?: boolean;
  className?: string;
}) => {
  return (
    <div className={classNames("flex flex-col w-full gap-2", className)}>
      {showTitle && (
        <div className="flex flex-row items-center">
          <span className="font-medium text-[14px] text-primary">
            {rowTitle}
          </span>
        </div>
      )}
      {children}
    </div>
  );
};

export default PatentFilterPopup;
