import { useMemo, useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

export function useDebouncedHandler<T>(
  handler: (params: T) => any | void,
  duration: number = 300
) {
  const debouncedHandler = useMemo(() => {
    return debounce(handler, duration);
  }, [duration, handler]);

  useEffect(() => {
    return () => {
      debouncedHandler.cancel();
    };
  }, [debouncedHandler]);

  return debouncedHandler;
}

export function useDebounce(delay: number, func?: (...params: any) => any) {
  const [id, setId] = useState<NodeJS.Timeout | null>();
  return useCallback(
    (...args: any) => {
      if (id) {
        clearTimeout(id);
      }
      const newId = setTimeout(() => {
        setId(null);
        if (func) {
          func(...args);
        }
      }, delay);
      setId(newId);
    },
    [func, id, setId, delay]
  );
}
