import { useEffect, useState } from "react";
import config from "config";
import classnames from "classnames";

// const ENTITY_LOGO_BASE_PATH =
//   "https://s3.us-east-2.amazonaws.com/tqd.s3.bucket/"; // todo: check this
// const EXTENSIONS = ["jpg", "jpeg", "webp"];

interface IEntityLogo {
  entityName: string;
  logoFromDB: string | null | undefined;
  logoWidth: number;
  classNames?: string;
}

// TODO: Refactor EntityLogo

const EntityLogo = ({
  entityName,
  logoFromDB,
  logoWidth = 22,
  classNames,
}: IEntityLogo) => {
  const initialLogoUrl = logoFromDB ? config.S3_BUCKET + logoFromDB : null;
  // : `${ENTITY_LOGO_BASE_PATH}${entityName}.png`;
  const [logoUrl, setLogoUrl] = useState<null | string>(initialLogoUrl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!logoUrl) {
      setLoading(true);
    }
  }, [logoUrl]);

  const handleImageError = async () => {
    let foundImageUrl: string | null = null;
    setLoading(true);
    setLogoUrl(null);

    // for (const extension of EXTENSIONS) {
    //   const newImageUrl = `${ENTITY_LOGO_BASE_PATH}${entityName}.${extension}`;

    //   try {
    //     // eslint-disable-next-line no-loop-func
    //     await new Promise<void>((resolve, reject) => {
    //       const img = new Image();

    //       img.onload = () => {
    //         foundImageUrl = newImageUrl;
    //         resolve();
    //       };

    //       img.onerror = () => {
    //         reject();
    //       };

    //       img.src = newImageUrl;
    //     });
    //   } catch (error) {
    //     // console.log(error)
    //   }

    //   if (foundImageUrl) {
    //     break;
    //   }
    // }

    setLoading(false);
    setLogoUrl(foundImageUrl);
  };

  return (
    <div key={entityName}>
      {logoUrl && !loading ? (
        <img
          alt=""
          className={classnames(classNames, "rounded-full")}
          style={{
            maxWidth: logoWidth,
            minWidth: logoWidth,
            minHeight: logoWidth,
            maxHeight: logoWidth,
          }}
          src={logoUrl}
          onError={handleImageError}
        />
      ) : (
        <div
          style={{
            maxWidth: logoWidth,
            minWidth: logoWidth,
            maxHeight: logoWidth,
            minHeight: logoWidth,
          }}
          className={classnames(
            classNames,
            "select-none border border-primary bg-[#FFFFFF] text-primaryBlue text-[12px] rounded-full flex content-center items-center justify-center"
          )}
        >
          {entityName?.trim().charAt(0).toLocaleUpperCase()}
        </div>
      )}
    </div>
  );
};

export default EntityLogo;
