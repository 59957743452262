import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import config from "config";
declare const hbspt: any;

export const handleHubspotClick = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setOpen(true);
  const script = document.createElement("script");
  script.src = "//js.hsforms.net/forms/v2.js";
  document.body.appendChild(script);

  script.addEventListener("load", () => {
    hbspt.forms.create({
      region: "na1",
      portalId: "7697776",
      formId: config.HUBSPOT_FORM_ID,
      target: "#hubspot-form",
    });
  });
};

const HubspotForm = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleClose = () => {
    setOpen(false);
    const script = document.querySelector(
      "script[src='//js.hsforms.net/forms/v2.js']"
    );
    if (script) {
      script.remove();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Contact Us</DialogTitle>
      <DialogContent>
        <div id="hubspot-form"></div>
      </DialogContent>
    </Dialog>
  );
};

export default HubspotForm;
