import { UpdateFunc } from "./filters/types";

interface FuncMap {
  [key: string]: UpdateFunc;
}

export function useChangeFilterValue(funcMap: FuncMap) {
  return (
    filterId: string,
    filterField: string | null,
    value: string | number | null | undefined,
    doClear?: boolean,
  ) => {
    const func = funcMap[filterId];
    func(filterField, value, doClear);
  };
}
