import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat,
  NormalizedCacheObject,
} from "@apollo/client";

import { AWSCloudWatchProvider, Amplify, Logger, Auth, Hub } from "aws-amplify";
import { useEffect, useMemo, useRef, useState } from "react";
import { HubCapsule } from "@aws-amplify/core";
import { ICognitoUser } from "types";
import { onError } from "@apollo/client/link/error";
import config from "config";

const logoutLink = (client?: {
  current: Partial<ApolloClient<NormalizedCacheObject>>;
}) =>
  onError(({ networkError, operation, ...rest }) => {
    const requestBody = operation.query.loc?.source.body;
    if (networkError) {
      const logger = new Logger("networkError", "ERROR");
      const logError =
        networkError?.message +
        ", vertical: " +
        config.VERTICAL +
        ", requestBody: " +
        requestBody;
      Amplify.register(logger);
      logger.addPluggable(new AWSCloudWatchProvider());
      logger.error(logError);
    }
    // if (
    //   (networkError &&
    //     "statusCode" in networkError &&
    //     networkError.statusCode === 401) ||
    //   networkError?.message === "Failed to fetch" //TODO- this condition we have to remove in the future when we start receiving proper response header
    //   // for now every false response like 40X, 500 errors lead to sign out
    // ) {
    //   // TODO - change response from back with "Access-Control-Allow-Origin": "*",
    //   // here is an explanation https://github.com/apollographql/apollo-link/issues/300#issuecomment-488337783
    //   // Auth.signOut();
    //   // if (client && client?.current?.clearStore) {
    //   //   client?.current?.clearStore();
    //   // }
    // }
  });

function getApolloLinkMiddleware(token?: string) {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      if (token) {
        Object.assign(headers, {
          Authorization: token,
        });
      }
      return {
        headers,
      };
    });

    return forward(operation);
  });
}

function createApolloClient(idToken?: string) {
  let result = { current: {} };

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(
      getApolloLinkMiddleware(idToken), // here we add auth header
      logoutLink(result).concat(
        ApolloLink.split(
          (operation) => operation.getContext().api === "userProfile",
          userAPIHTTPLink,
          httpLink
        )
      )
    ),
  });
  result.current = client;
  return client;
}

function getJwtToken(user?: ICognitoUser) {
  if (user) {
    return user.getSignInUserSession()?.getIdToken().getJwtToken();
  }
  return null;
}
//https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
const apolloClientListener =
  (
    setClient: (...rest: any) => void,
    apolloClient?: ApolloClient<NormalizedCacheObject>
  ) =>
  async (data: HubCapsule) => {
    switch (data.payload.event) {
      case "signIn": //add token on sign in - TODO- check
        if (data?.payload?.data?.getSignInUserSession) {
          //TODO - check if verified
          const idToken = getJwtToken(data?.payload?.data);
          idToken && setClient(createApolloClient(idToken));
        }
        break;
      case "tokenRefresh": //update token on refresh-TODO-check
        Auth.currentSession() // run on mount
          .then((data) => {
            if (data) {
              setClient(createApolloClient(data.getIdToken().getJwtToken()));
              localStorage.setItem(
                "currentToken",
                data.getIdToken().getJwtToken()
              );
            }
          });
        break;
      case "signOut":
        setClient(
          new ApolloClient({
            cache: new InMemoryCache(),
            link: new HttpLink({
              uri:
                process.env.REACT_APP_ENV === "dev"
                  ? config.API_DEV
                  : config.API_PROD,
            }),
          })
        );
        if (apolloClient) {
          apolloClient.clearStore();
        }
        console.log("signOut");
        localStorage.clear();
        break;
    }
  };

//https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
const authProfileListener =
  (setProfile: (...rest: any) => void) => (data: HubCapsule) => {
    switch (data.payload.event) {
      case "signIn":
        if (data?.payload?.data?.getSignInUserSession) {
          setProfile(data?.payload?.data);
        }
        break;
      case "signOut": // clear profile on sign out, this will redirect to login
        setProfile(null);
        break;
    }
  };
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_ENV === "dev" ? config.API_DEV : config.API_PROD,
});

const userAPIHTTPLink = new HttpLink({
  uri:
    process.env.REACT_APP_ENV === "dev"
      ? config.USER_PROFILE_API_DEV
      : config.USER_PROFILE_API_PROD,
});

export function useAuthProfile() {
  const [profile, setProfile] = useState<ICognitoUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        //if there is a User session - we get user and set in state, then it use in context
        //check if verified- TODO
        setProfile(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    Hub.listen("auth", authProfileListener(setProfile)); //run on events
  }, []);

  return { profile, isLoading };
}

function useRoles(cognitoProfile: ICognitoUser | null) {
  return cognitoProfile?.getSignInUserSession()?.getIdToken().payload[
    "cognito:groups"
  ];
}

function useRefreshToken(cognitoProfile: ICognitoUser | null) {
  const ref = useRef(false);
  useEffect(() => {
    const id = setInterval(() => {
      // Limited user access: one user at a time
      setTimeout(() => {
        const deviceKey = localStorage.getItem("deviceKey");
        Auth.fetchDevices().then((res) => {
          if (deviceKey && res && res.length && res[0].id !== deviceKey) {
            console.log("Invalid deviceKey:", deviceKey);
            Auth.signOut();
          }
        });
      }, 200);

      const expirationTime = new Date(
        cognitoProfile?.getSignInUserSession()?.getIdToken().payload.exp * 1000
      ).getTime();
      const currentTime = new Date().getTime();
      const diffInMinutes = (expirationTime - currentTime) / 1000 / 60;
      if (!ref.current && diffInMinutes <= 10) {
        // if the difference is less than 10 min - refresh token
        ref.current = true;
        const refreshToken = cognitoProfile
          ?.getSignInUserSession()
          ?.getRefreshToken();
        if (refreshToken) {
          cognitoProfile?.refreshSession(refreshToken, () => {
            ref.current = false;
            console.info("Token was refreshed");
            Auth.currentAuthenticatedUser().catch((err) => {
              if (err === "The user is not authenticated") {
                Auth.signOut();
              }
            });
          });
        }
      }
    }, 1000 * 60); // check every minute

    return () => {
      clearInterval(id);
    };
  }, [cognitoProfile]);
}

// get userSession onMount, set refresh token interval
function useSilentAuth() {
  const { profile, isLoading } = useAuthProfile(); // get cashed user session
  useRefreshToken(profile); // use refresh token logic
  const roles = useRoles(profile); // get current user roles
  return { roles, authProfile: profile, isLoading: isLoading };
}
export function useAuthContext() {
  const [accessType, setAccessType] = useState<
    null | "public" | "private" | "common"
  >(null);
  const { authProfile, roles, isLoading } = useSilentAuth(); // get user session on mount
  const context = useMemo(() => {
    return {
      isAuth: !!authProfile,
      accessType,
      setAccessType: setAccessType,
      user: authProfile,
      roles,
    };
  }, [accessType, authProfile, roles]);
  return { context, isLoading };
}

// return apollo client
// this approach was taken because we have to update graphql auth header every time when token is changed
// this why we have to track events like: signIn, refreshToken and recreate apollo client with updated token
// by default apollo client is setted without auth header, and it change if user is logged in:
// 1. we check  Auth.currentSession on mount
// 2. we check on events: signIn, signOut, refreshToken.

export function useApolloClientProvider() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(
    new ApolloClient({
      cache: new InMemoryCache(),
      link: new HttpLink({
        uri:
          process.env.REACT_APP_ENV === "dev"
            ? config.API_DEV
            : config.API_PROD,
      }),
    })
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //check if user is logged in on mount
    Auth.currentSession() // run on mount
      .then((data) => {
        if (data) {
          //update header and recreate apollo client
          setClient(createApolloClient(data.getIdToken().getJwtToken()));
          localStorage.setItem("currentToken", data.getIdToken().getJwtToken());
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  //listen events to update apollo client if it would be needed: the signIn and refresh token events
  Hub.listen("auth", apolloClientListener(setClient)); //run on events

  return { client, isLoading };
}
