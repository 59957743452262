import { useCallback, useEffect, useMemo, useState } from "react";
import { GET_PATENT_STATISTICS } from "../Patents.queries";
import { useLazyQuery } from "@apollo/client";
import { PatentStats } from "./PatentsStats.types";
import {
  barChartColors,
  stackedBarChartColors,
} from "./PatentsStats.constants";
import { countryList } from "./countryList";

export const usePatentsStats = ({
  currentKeywords,
  pdParamFrom,
  pdParamEnd,
  organizationQuery,
  inventorsQuery,
}: {
  currentKeywords: string;
  pdParamFrom: string;
  pdParamEnd: string;
  organizationQuery: string;
  inventorsQuery: string;
}) => {
  const [companyStats, setCompanyStats] = useState<PatentStats | undefined>();
  const [countryStats, setCountryStats] = useState<PatentStats | undefined>();
  // const [yearStats, setYearStats] = useState<PatentStats | undefined>();
  const [countryAndYearStats, setCountryAndYearStats] = useState<
    PatentStats | undefined
  >();
  const [organizationsCount, setOrganizationsCount] = useState<number>(0);
  const [patentsCountOverall, setPatentsCountOverall] = useState<number>(0);
  const [deadAliveStats, setDeadAliveStats] = useState<
    PatentStats | undefined
  >();

  const filtersString = useMemo(
    () => `${pdParamFrom}${pdParamEnd}${organizationQuery}${inventorsQuery}`,
    [organizationQuery, inventorsQuery, pdParamEnd, pdParamFrom]
  );

  const queryString = !!currentKeywords
    ? `query=atac=(${currentKeywords.replace(
        /"/g,
        '\\"'
      )})${filtersString}&group=minesoftfamily`
    : `query=${filtersString}&group=minesoftfamily`;

  // const pdStartYear = encodeURIComponent(pdParamFrom)
  //   .split("pd%20%3E%3D%20")[1]
  //   ?.slice(0, 4);
  // const pdEndYear = encodeURIComponent(pdParamEnd)
  //   .split("pd%20%3C%3D%20")[1]
  //   ?.slice(0, 4);

  // const yearDifference = Number(pdEndYear) - Number(pdStartYear);

  const queryStringCompanies = queryString + "&f1=ultimate_owner&limit1=10";
  const queryStringCountries = queryString + "&f1=country";
  // const queryStringYears = queryString + "&f1=publicationyear";
  const queryStringCountriesAndYears =
    queryString +
    // `&f1=country&limit1=${
    //   !!yearDifference ? yearDifference + 1 : "10"
    // }&f2=publicationyear&limit2=${
    //   !!yearDifference ? yearDifference + 1 : "10"
    // }`;
    "&f1=country&f2=publicationyear&limit1=10&limit2=999";

  const queryStringDeadAlive = queryString + "&f1=deadalive";

  const queryLastYearOrgStats = !!currentKeywords
    ? `query=atac=(${currentKeywords.replace(
        /"/g,
        '\\"'
      )})${` AND pd>=${new Date().getFullYear()}01`}${organizationQuery}${inventorsQuery}&group=minesoftfamily&f1=ultimate_owner&limit1=999`
    : `query=pd>=${new Date().getFullYear()}01${organizationQuery}${inventorsQuery}&group=minesoftfamily&f1=ultimate_owner&limit1=999`;

  const [getPatentStats, { data: patentStatsCompanies, loading }] =
    useLazyQuery<{
      [key in
        | "companies"
        | "countries"
        | "years"
        | "countriesAndYears"
        | "queryLastYear"
        | "deadAlive"]: {
        response: string;
      };
    }>(GET_PATENT_STATISTICS);

  const fetchPatentStats = useCallback(() => {
    if (!!currentKeywords || !!organizationQuery || !!inventorsQuery) {
      getPatentStats({
        variables: {
          queryStringCompanies: queryStringCompanies,
          queryStringCountries: queryStringCountries,
          // queryStringYears: queryStringYears,
          queryStringCountriesAndYears: queryStringCountriesAndYears,
          queryLastYear: queryLastYearOrgStats,
          queryStringDeadAlive: queryStringDeadAlive,
        },
      });
    }
  }, [
    currentKeywords,
    organizationQuery,
    inventorsQuery,
    getPatentStats,
    queryStringCompanies,
    queryStringCountries,
    // queryStringYears,
    queryStringCountriesAndYears,
    queryLastYearOrgStats,
    queryStringDeadAlive,
  ]);

  useEffect(() => {
    fetchPatentStats();
  }, [fetchPatentStats]);

  useEffect(() => {
    if (!!patentStatsCompanies) {
      setCompanyStats(
        !!patentStatsCompanies.companies
          ? JSON.parse(patentStatsCompanies.companies.response)
          : undefined
      );
      setCountryStats(
        !!patentStatsCompanies.countries
          ? JSON.parse(patentStatsCompanies.countries.response)
          : undefined
      );
      // setYearStats(
      //   !!patentStatsCompanies.years
      //     ? JSON.parse(patentStatsCompanies.years.response)
      //     : undefined
      // );
      setCountryAndYearStats(
        !!patentStatsCompanies.countriesAndYears
          ? JSON.parse(patentStatsCompanies.countriesAndYears.response)
          : undefined
      );
      setOrganizationsCount(
        !!patentStatsCompanies.queryLastYear
          ? (
              JSON.parse(
                patentStatsCompanies.queryLastYear.response
              ) as PatentStats
            )?.ultimate_owner?.buckets?.length
          : 0
      );

      setDeadAliveStats(
        !!patentStatsCompanies.deadAlive
          ? JSON.parse(patentStatsCompanies.deadAlive.response)
          : undefined
      );

      const DeadAliveSum =
        !!patentStatsCompanies.deadAlive &&
        JSON.parse(
          patentStatsCompanies.deadAlive.response
        )?.deadalive?.buckets?.reduce(
          (acc, curr) => Number(acc) + Number(curr.simplefamily),
          0
        );

      setPatentsCountOverall(DeadAliveSum);
    }
  }, [patentStatsCompanies]);

  const companyDatasets = useMemo(() => {
    const labels =
      companyStats?.ultimate_owner?.buckets
        ?.sort((a, b) => a.simplefamily - b.simplefamily)
        ?.map((company) => String(company.val)) || [];
    const dataset = [
      {
        label: "Patents",
        data:
          companyStats?.ultimate_owner?.buckets
            ?.sort((a, b) => a.simplefamily - b.simplefamily)
            ?.map((company) => company.simplefamily) || [],
        backgroundColor: barChartColors,
      },
    ];
    return { labels, dataset };
  }, [companyStats?.ultimate_owner?.buckets]);

  const countryDatasets = useMemo(() => {
    const labels =
      countryStats?.country?.buckets
        ?.sort((a, b) => a.simplefamily - b.simplefamily)
        ?.map((country) =>
          String(
            countryList.find((item) => item.code === country.val)?.name ||
              String(country.val)
          )
        ) || [];
    const dataset = [
      {
        label: "Patents",
        data:
          countryStats?.country?.buckets
            ?.sort((a, b) => a.simplefamily - b.simplefamily)
            ?.map((country) => country.simplefamily) || [],
        backgroundColor: barChartColors,
      },
    ];
    return { labels, dataset };
  }, [countryStats?.country?.buckets]);

  // const yearDatasets = useMemo(() => {
  //   const labels =
  //     yearStats?.publicationyear?.buckets
  //       ?.sort((a, b) => Number(a.val) - Number(b.val))
  //       ?.map((year) => String(year.val)) || [];
  //   const dataset = [
  //     {
  //       label: "Patents",
  //       data:
  //         yearStats?.publicationyear?.buckets
  //           ?.sort((a, b) => Number(a.val) - Number(b.val))
  //           ?.map((year) => year.simplefamily) || [],
  //       backgroundColor: "rgba(62, 118, 220, 0.2)",
  //       borderColor: "#3E76DC",
  //       fill: true,
  //     },
  //   ];
  //   return { labels, dataset };
  // }, [yearStats?.publicationyear?.buckets]);

  const countryAndYearDatasets = useMemo(() => {
    const years = countryAndYearStats?.country?.buckets
      ?.flatMap((country) =>
        country.publicationyear?.buckets.map((year) => year.val)
      )
      ?.sort((a, b) => Number(a) - Number(b));

    const uniqueYears = [...new Set(years)];

    const datasets = countryAndYearStats?.country?.buckets.map((country, i) => {
      const data = uniqueYears.map((year) => {
        const yearCount = country.publicationyear?.buckets.find(
          (yearBucket) => yearBucket.val === year
        );
        return yearCount ? yearCount.simplefamily : 0;
      });

      return {
        label:
          countryList.find((item) => item?.code === String(country?.val))
            ?.name || country.val,
        data,
        backgroundColor: stackedBarChartColors[i],
      };
    });

    return {
      labels: uniqueYears || [],
      datasets,
    };
  }, [countryAndYearStats]);

  // const patentsCountOverLastYear = useMemo(() => {
  //   const lastYear =
  //     (countryAndYearDatasets?.labels.slice(-1)[0] as string) ||
  //     new Date().getFullYear();
  //   return (
  //     yearStats?.publicationyear?.buckets?.filter(
  //       (year) => year.val === lastYear
  //     )[0]?.simplefamily || 0
  //   );
  // }, [countryAndYearDatasets?.labels, yearStats?.publicationyear?.buckets]);

  const deadAliveDatasets = useMemo(() => {
    const labels = ["Dead", "Alive"];
    const dataset = [
      {
        label: "Status",
        data:
          deadAliveStats?.deadalive &&
          deadAliveStats?.deadalive?.buckets?.length > 0
            ? [
                deadAliveStats?.deadalive?.buckets.find(
                  (deadAlive) => deadAlive.val === "Dead"
                )?.simplefamily || 0,
                deadAliveStats?.deadalive?.buckets.find(
                  (deadAlive) => deadAlive.val === "Alive"
                )?.simplefamily || 0,
              ]
            : [],
        backgroundColor: ["#3f3f3f", "#3E76DC"],
        hoverBackgroundColor: ["#4a4a4a", "#447be1"],
      },
    ];
    return { labels, dataset };
  }, [deadAliveStats?.deadalive]);

  return {
    companyDatasets,
    countryDatasets,
    countryAndYearDatasets,
    // yearDatasets,
    loading,
    organizationsCount,
    patentsCountOverall,
    deadAliveDatasets,
  };
};
