import config from "config";
import { gql, useQuery } from "@apollo/client";
import { IAggregate, IOrganizationEntity, VariableType } from "types";

export function useGraphQLQcaas({ limit, offset, sort, search }: VariableType) {
  const { loading, error, data } = useQuery<{
    organizations: Array<IOrganizationEntity>;
    organizationsAggregate: IAggregate<{}>;
  }>(
    gql`
      query getQcaas($sort: [EntitySort!], $limit: Int, $offset: Int, $phrase: String!) {
        organizationsAggregate: entitiesAggregate(
          where: {
            entityType_INCLUDES: ORGANIZATION
            vertical_INCLUDES: ${config.VERTICAL}
            offersQcaas: true
          },
          fulltext: { FullTextSearchFields: { phrase: $phrase } }
        ) {
          count
        }
        organizations: entities(
          where: {
            entityType_INCLUDES: ORGANIZATION
            vertical_INCLUDES: ${config.VERTICAL}
            offersQcaas: true
          },
          fulltext: { FullTextSearchFields: { phrase: $phrase } }
          options: { limit: $limit, offset: $offset, sort: $sort }
        ) {
          id
          logo
          name
          inCountry {
            name
            region {
              name
            }
          }
          facilitatesAccessToOrganizations {
            id
            name
          }
          cloudOffering
          simulatorOffering
          consultingOffering
          cpuOffering
          gpuOffering
          softwareOffering
          quantumLibrary
          quantumAlgorithms
          quantumCircuit
          assemblyLanguage
          offersQpu
          offersQcaas
        }
      }
    `,
    {
      variables: {
        limit: limit,
        sort: sort,
        offset: offset,
        phrase: search
          ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
          : "*",
      },
    }
  );

  return { loading, error, data };
}

export function useGraphQLQpu({ limit, offset, sort, search }: VariableType) {
  const { loading, error, data } = useQuery<{
    organizations: Array<IOrganizationEntity>;
    organizationsAggregate: IAggregate<{}>;
  }>(
    gql`
      query getQpu($sort: [EntitySort!], $limit: Int, $offset: Int, $phrase: String!) {
        organizationsAggregate: entitiesAggregate(
          where: {
            entityType_INCLUDES: ORGANIZATION
            vertical_INCLUDES: ${config.VERTICAL}
            offersQpu: true
          },
          fulltext: { FullTextSearchFields: { phrase: $phrase } }
        ) {
          count
        }
        organizations: entities(
          where: {
            entityType_INCLUDES: ORGANIZATION
            vertical_INCLUDES: ${config.VERTICAL}
            offersQpu: true
          },
          fulltext: { FullTextSearchFields: { phrase: $phrase } }
          options: { limit: $limit, offset: $offset, sort: $sort }
        ) {
          id
          logo
          name
          cloudOffering
          simulatorOffering
          consultingOffering
          facilitatesAccessToOrganizations {
            id
            name
          }
          organizationFacilitatesAccessTo {
            id
            name
          }
          offersQpu
          offersQcaas
        }
      }
    `,
    {
      variables: {
        limit: limit,
        sort: sort,
        offset: offset,
        phrase: search
          ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
          : "*",
      },
    }
  );

  return { loading, error, data };
}
