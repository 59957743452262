import classNames from "classnames";
import { APP_ROUTES } from "const";
import { Link } from "react-router-dom";
import { PlatformEntities } from "../Patents.types";

export const PlatformEntitiesMatch = ({
  entityName,
  customClass,
  platformEntities = [],
}: {
  entityName: string;
  customClass?: string;
  platformEntities: PlatformEntities["entities"] | undefined;
}) => {
  const normalizedEntityName = entityName
    .toLowerCase()
    .replace(/\b(ltd|inc|corp|co|llc|gmbh|sarl|sa)\b/gi, "")
    .trim();

  const platformEntity = platformEntities?.filter(
    (entity) =>
      entity.name
        ?.toLowerCase()
        .replace(/\b(ltd|inc|corp|co|llc|gmbh|sarl|sa)\b/gi, "")
        .trim() === normalizedEntityName
  );

  const entityLink = APP_ROUTES.COMPANY_PROFILE;

  return (
    <span
      className={classNames(
        "leading-[160%] font-normal text-primary text-[12px]",
        { "!text-primaryBlue": platformEntity.length > 0 },
        customClass
      )}
    >
      {platformEntity.length > 0 ? (
        <Link
          to={entityLink && entityLink.replace(/:[^/]+/g, platformEntity[0].id)}
          className="hover:underline font-medium"
          target="_blank"
        >
          {entityName}
        </Link>
      ) : (
        entityName
      )}
    </span>
  );
};
