import config from "config";

export const ENTERPRISE_USER_FIELDS = `
  id
  name
  city
  inCountry {
    name
    region {
      name
    }
  }
  description
  employeeRange
  founded
  lat
  linkedIn
  lng
  logo
  memberOfConsortiums(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
    id
    name
  }
  partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) @include(if: $isAuth) {
    id
    date
    description
    source
    updatedAt
    createdAt
    status
    vertical
    entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
      id
      name
      logo
      entityType
      vertical
    }
  }
  raised
  website
  status
  ${config.USER_INTEREST_IN_FIELD}
  lastUpdatedBy @include(if: $isAdmin)
  updatedAt @include(if: $isAuth)
  createdAt
  sector(where: {vertical_INCLUDES:${config.VERTICAL}}) {
    name
  }
  interests {
    name
  }
  entityType
  ${config.KEY_SOURCES_FIELD}
  symbol
`;
