import { useCallback } from "react";

export function useDetectCheckedRange(
  checkedLimits: {
    first: number;
    last: number;
  },
  setCheckedLimits: React.Dispatch<
    React.SetStateAction<{
      first: number;
      last: number;
    }>
    >
) {
  return useCallback(
    (id: number) => {
      if (isNaN(checkedLimits.first) && isNaN(checkedLimits.last)) {
        setCheckedLimits((prevState) => ({
          first: Number(id),
          last: prevState.last,
        }));
      } else if (id === checkedLimits.last) {
        setCheckedLimits((prevState) => ({
          first: prevState.first,
          last: NaN,
        }));
      } else if (id === checkedLimits.first) {
        setCheckedLimits((prevState) => ({
          first: NaN,
          last: prevState.last,
        }));
      } else if (!isNaN(checkedLimits.first) && isNaN(checkedLimits.last)) {
        setCheckedLimits((prevState) => ({
          first: prevState.first,
          last: Number(id),
        }));
      } else if (!isNaN(checkedLimits.first) && !isNaN(checkedLimits.last)) {
        if (id > checkedLimits.last) {
          setCheckedLimits((prevState) => ({
            first: prevState.first,
            last: Number(id),
          }));
        } else if (id < checkedLimits.last) {
          setCheckedLimits((prevState) => ({
            first: Number(id),
            last: prevState.last,
          }));
        }
      }
    },
    [setCheckedLimits, checkedLimits]
  );
}