import { memo } from "react";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useCheckPremium } from "utils";
import patentsTableView from "assets/images/patentsTableView.svg";
import patentsTableViewActive from "assets/images/patentsTableViewActive.svg";
import patentsListView from "assets/images/patentsListView.svg";
import patentsListViewActive from "assets/images/patentsListViewActive.svg";
import patentsStats from "assets/images/patentsStats.svg";
import patentsStatsActive from "assets/images/patentsStatsActive.svg";
import { APP_ROUTES } from "const";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

function generateLink(route: string, params: URLSearchParams) {
  let path = route;
  let hasParams = false;

  params.forEach((value, key) => {
    path += `${hasParams ? "&" : "?"}${key}=${encodeURIComponent(value)}`;
    hasParams = true;
  });

  return path;
}

function PatentsLayout() {
  const context = useOutletContext();
  const isPremium = useCheckPremium();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row mr-3 mt-4 justify-between mb-1">
        <div className="flex flex-row w-full justify-between items-center mx-4 pb-4 border-b-[1px] border-primary">
          <div className="flex flex-col justify-center">
            <h1 className="text-[16px] font-bold text-primary">Patents</h1>
            <div className="text-secondary text-[12px]">
              This is a beta feature
            </div>
          </div>
          {isPremium && (
            <div className="flex flex-row gap-1 md:ml-6 items-center md:justify-center">
              <div className="bg-tablePrimary rounded flex flex-row items-center justify-center gap-1">
                <ReactTooltip
                  id="patent-table-view"
                  place="top"
                  effect="solid"
                  className="text-[12px] text-center rounded !opacity-100"
                />
                <Link to={generateLink(APP_ROUTES.PATENTS, params)}>
                  <div
                    className={classNames(
                      "rounded bg-tablePrimary hover:bg-primaryBlue hover:cursor-pointer",
                      {
                        "bg-primaryBlue":
                          location.pathname === APP_ROUTES.PATENTS,
                      }
                    )}
                    data-tip="Table View"
                    data-for="patent-table-view"
                  >
                    <img
                      src={
                        location.pathname === APP_ROUTES.PATENTS
                          ? patentsTableViewActive
                          : patentsTableView
                      }
                      alt="patentsTableView"
                      className="p-2"
                      onMouseEnter={(e) =>
                        (e.currentTarget.src = patentsTableViewActive)
                      }
                      onMouseLeave={(e) => {
                        if (location.pathname === APP_ROUTES.PATENTS) return;
                        e.currentTarget.src = patentsTableView;
                      }}
                    />
                  </div>
                </Link>
                <ReactTooltip
                  id="patent-list-view"
                  place="top"
                  effect="solid"
                  className="text-[12px] text-center rounded !opacity-100"
                />
                <Link to={generateLink(APP_ROUTES.PATENTS_LIST_VIEW, params)}>
                  <div
                    className={classNames(
                      "rounded bg-tablePrimary hover:bg-primaryBlue hover:cursor-pointer",
                      {
                        "bg-primaryBlue":
                          location.pathname === APP_ROUTES.PATENTS_LIST_VIEW,
                      }
                    )}
                    data-tip="List View"
                    data-for="patent-list-view"
                  >
                    <img
                      src={
                        location.pathname === APP_ROUTES.PATENTS_LIST_VIEW
                          ? patentsListViewActive
                          : patentsListView
                      }
                      alt="patentsListView"
                      className="p-2"
                      onMouseEnter={(e) =>
                        (e.currentTarget.src = patentsListViewActive)
                      }
                      onMouseLeave={(e) => {
                        if (location.pathname === APP_ROUTES.PATENTS_LIST_VIEW)
                          return;
                        e.currentTarget.src = patentsListView;
                      }}
                    />
                  </div>
                </Link>
                <ReactTooltip
                  id="patent-stats"
                  place="top"
                  effect="solid"
                  className="text-[12px] text-center rounded !opacity-100"
                />
                <Link to={generateLink(APP_ROUTES.PATENTS_STATS, params)}>
                  <div
                    className={classNames(
                      "rounded bg-tablePrimary hover:bg-primaryBlue",
                      {
                        "bg-primaryBlue":
                          location.pathname === APP_ROUTES.PATENTS_STATS,
                      }
                    )}
                    data-tip="Stats"
                    data-for="patent-stats"
                  >
                    <img
                      src={
                        location.pathname === APP_ROUTES.PATENTS_STATS
                          ? patentsStatsActive
                          : patentsStats
                      }
                      alt="patentsStats"
                      className="p-2"
                      onMouseEnter={(e) =>
                        (e.currentTarget.src = patentsStatsActive)
                      }
                      onMouseLeave={(e) => {
                        if (location.pathname === APP_ROUTES.PATENTS_STATS)
                          return;
                        e.currentTarget.src = patentsStats;
                      }}
                    />
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <Outlet context={context} />
    </div>
  );
}

export default memo(PatentsLayout);
