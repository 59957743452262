import config from "config";

export const INVESTOR_FIELDS = `
vertical
id
name
inCountry {
  name
  region {
    name
  }
}
city
createdAt
description
founded
id
lat
linkedIn
lng
logo
status
raised @include(if: $isAuth)
website
memberOfConsortiums {
  id
  name
}
employeeRange
ofType (where:{vertical_INCLUDES: ${config.VERTICAL}}) {
  id
  name
  description
}
partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) @include(if: $isAuth) {
  id
  date
  description
  source
  updatedAt
  createdAt
  status
  vertical
  entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
    id
    name
    logo
    entityType
    vertical
  }
}
portfolio @include(if: $isAuth) {
    id
    name
}
lastUpdatedBy @include(if: $isAdmin)
updatedAt
portfolio {
  id
  name
}
entityType
`;
