import { DateTimePicker } from "components/DatePicker";
import { parse } from "date-fns";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router";

export const PatentDateRange = ({
  label,
  paramName,
  currentDate,
}: {
  label: string;
  paramName: string;
  currentDate: Date | null;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  return (
    <div>
      <div className="text-[12px] font-normal text-primary mb-2">{label}</div>
      <div className="date-before flex flex-col relative justify-center mb-2">
        <DateTimePicker
          popperPlacement="right"
          placeholderText="Select date"
          className="w-[150px] h-[40px] mr-[14px] rounded-md outline-none pl-12 text-[12px] text-primary border border-primary"
          selected={
            params.get(paramName)
              ? parse(params.get(paramName) as string, "yyyy-MM-dd", new Date())
              : !!currentDate
              ? parse(
                  currentDate.toISOString().split("T")[0],
                  "yyyy-MM-dd",
                  new Date()
                )
              : null
          }
          dateFormat="yyyy-MM-dd"
          onChangeRaw={(e) => e.preventDefault()}
          onChange={() => {}}
          onSelect={(date) => {
            params.delete("offset");
            params.delete("limit");
            const formatedDate = format(date, "yyyy-MM-dd");
            params.set(paramName, formatedDate);
            navigate({ search: params.toString() });
          }}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </div>
    </div>
  );
};
