import classnames from "classnames";
import React, { memo } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DateTime.styles.css";

export interface IDatePickerProps extends ReactDatePickerProps {
  className?: string;
  customRef?: React.MutableRefObject<DatePicker<any> | null>;
}

function DateTimePicker({ className, customRef, ...rest }: IDatePickerProps) {
  return (
    <DatePicker
      ref={customRef}
      popperClassName={classnames(
        "date-picker-component-popper",
        rest?.popperClassName
      )}
      className={classnames(
        className,
        "bg-primary text-primary border-primary hover:border-primaryBlue"
      )}
      {...rest}
    />
  );
}

export default memo(DateTimePicker);
