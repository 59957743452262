import classnames from "classnames";

import { IFilterType } from "types";

import { useState } from "react";
import { DateTimePicker } from "components/DatePicker";

import { useSortAndFilters } from "../../../Filter.hooks";
import format from "date-fns/format";
import { useLocation, useNavigate } from "react-router";
import { adjustDateForUTC } from "utils";
import { ReactComponent as CalendarIcon } from "assets/images/calendar.svg";
import { useRangeDatesMapping } from "./RangeDates.hooks";
import { FilterUpdateFunc } from "../../../Filter.types";

interface IProps {
  column: string;
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function RangeDates({
  column,
  data = [],
  checkedTags,
  columnName,
  showSectorHeader,
  sectorHeader,
  onCheck,
}: IProps) {
  const { dateTimeData } = useSortAndFilters(data);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [disableInput, setDisableInput] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  useRangeDatesMapping(
    column,
    dateTimeData,
    startDate?.getTime(),
    endDate?.getTime(),
    onCheck,
    checkedTags
  );

  const handleChangeDateOptions = (numOfMonths: number) => {
    const selectedDate = format(
      adjustDateForUTC(subtractMonths(numOfMonths, new Date())),
      "yyyy-MM-dd"
    );
    params.delete("startDate_GTE");
    params.delete("startDate_LTE");
    params.append("startDate_GTE", selectedDate);
    onCheck(column, "startDate_GTE", selectedDate); // todo: remove startDate_GTE
    setStartDate(
      adjustDateForUTC(subtractMonths(numOfMonths, new Date())) as Date
    );
    setDisableInput(true);
    navigate({ search: params.toString() });
  };

  return (
    <div className="flex flex-col w-full h-full mt-2">
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="flex mb-3">
        <p
          className={classnames(
            "border-none text-primary text-[14px] rounded-[2px] font-bold outline-none"
          )}
        >
          {columnName === "Last funding date" && columnName}
        </p>
      </div>
      <div className="flex mb-1 relative h-full">
        <div className={classnames("h-full bg-primary grid grid-cols-1")}>
          {columnName === "Last funding date" && (  // todo: fix this
            <div className="grid mb-3 text-primary text-[14px]">
              <label>
                <input
                  type="radio"
                  className="mr-2 mb-3"
                  name="dateRange[]"
                  onChange={() => {
                    handleChangeDateOptions(1);
                  }}
                />
                Past month
              </label>
              <label>
                <input
                  type="radio"
                  className="mr-2 mb-3"
                  name="dateRange[]"
                  onChange={() => {
                    handleChangeDateOptions(4);
                  }}
                />
                Past quarter
              </label>

              <label>
                <input
                  type="radio"
                  className="mr-2 mb-3"
                  name="dateRange[]"
                  onChange={() => {
                    handleChangeDateOptions(12);
                  }}
                />
                Past year
              </label>
              <label>
                <input
                  type="radio"
                  className="mr-2 mb-3"
                  name="dateRange[]"
                  onChange={() => {
                    params.delete("startDate_GTE");
                    params.delete("startDate_LTE");
                    setDisableInput(false);
                    navigate({ search: params.toString() });
                    setDisableInput(false);
                  }}
                />
                Custom date range
              </label>
            </div>
          )}
          <div
            className={classnames(
              "flex flex-row justify-start items-center bg-primary mb-2",
              columnName === "Last funding type" ? "ml-3" : ""
            )}
          >
            <div>
              <div className="text-[13px] font-bold text-primary mb-2">
                From
              </div>
              <div className="date-before flex flex-col relative justify-center mb-2">
                <CalendarIcon className="absolute z-10 ml-5" />
                <DateTimePicker
                  className="w-[150px] h-[40px] mr-[14px] rounded-md outline-none pl-12 text-[12px] text-[#98A0A6] border border-primary"
                  selected={
                    params.get("startDate_GTE")
                      ? adjustDateForUTC(String(params.get("startDate_GTE")))
                      : null
                  }
                  dateFormat="yyyy-MM-dd"
                  locale="en"
                  placeholderText="Choose Date"
                  onChangeRaw={() => setStartDate(null)}
                  onChange={() => {}}
                  onSelect={(date) => {
                    setStartDate(date as Date);
                    onCheck("startDate_GTE", "startDate_GTE", format(date, "yyyy-MM-dd"));
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={disableInput}
                />
              </div>
            </div>
            <div>
              <div className="text-[13px] font-bold text-primary mb-2">To</div>
              <div className="date-before flex flex-col relative justify-center mb-2">
                <CalendarIcon className="absolute z-10 ml-5" />
                <DateTimePicker
                  className="w-[150px] h-[40px] mr-[14px] rounded-md outline-none pl-12 text-[12px] text-[#98A0A6] border border-primary"
                  selected={
                    params.get("startDate_LTE")
                      ? adjustDateForUTC(String(params.get("startDate_LTE")))
                      : null
                  }
                  placeholderText="Choose Date"
                  dateFormat="yyyy-MM-dd"
                  onChange={() => {}}
                  onChangeRaw={(e) => e.preventDefault()}
                  onSelect={(date) => {
                    setEndDate(date as Date);
                    onCheck("startDate_LTE", "startDate_LTE", format(date, "yyyy-MM-dd"));
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={disableInput}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function subtractMonths(numOfMonths: number, date = new Date()) {
  const dateCopy = new Date(date.getTime());

  dateCopy.setUTCMonth(dateCopy.getUTCMonth() - numOfMonths);

  return dateCopy;
}
