import config from "config";
import {
  gql,
  QueryFunctionOptions,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { IAggregate, IInvestorEntity, VariableType } from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import { INVESTOR_FIELDS } from "./Investor.constants";
import { useEffect } from "react";
import { ENTITY_STATUSES } from "const";

const GET_INVESTORS = gql`
  query getInvestors(
    $where: EntityWhere
    $sort: [EntitySort!]
    $limit: Int
    $offset: Int
    $phrase: String!
    $isAdmin: Boolean!
    $isAuth: Boolean!
  ) {
    investorsAggregate:entitiesAggregate (where: $where, fulltext: { FullTextSearchFields: { phrase: $phrase } }) {
			count
		}
    investors: entities (
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      fulltext: { FullTextSearchFields: { phrase: $phrase } }
      ) {
      ${INVESTOR_FIELDS}
    }
  }
`;

export function useGraphQLInvestors({
  limit,
  offset,
  sort,
  where,
  search,
  ...rest
}: VariableType &
  QueryFunctionOptions<
    {
      investors: Partial<IInvestorEntity>[];
    },
    VariableType
  >) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<
    {
      investors: Array<IInvestorEntity>;
      investorsAggregate: IAggregate<{}>;
    },
    VariableType
  >(GET_INVESTORS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        entityType_INCLUDES: "INVESTOR",
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
    },
    ...rest,
  });

  return { loading, error, data };
}

export function useGraphQLInvestorsList({ canStart = true }) {
  const [runQuery, { loading, error, data }] = useLazyQuery<
    {
      investors: Array<IInvestorEntity>;
    },
    VariableType
  >(
    gql`
      query getInvestors($where: EntityWhere) {
        investors: entities(where: $where) {
          id
          name
        }
      }
    `,
    {
      variables: {
        where: {
          entityType_INCLUDES: "INVESTOR",
          vertical_INCLUDES: config.VERTICAL,
        },
      },
    }
  );

  useEffect(() => {
    if (canStart) runQuery().then();
  }, [canStart]);

  return { loading, error, data };
}
