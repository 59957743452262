import classnames from "classnames";
import { LoaderComponent } from "components/Loader/Loader";

import { IFilterType } from "types";

import { useSortAndFilters } from "../../Filter.hooks";
import { FilterUpdateFunc } from "../../Filter.types";

interface IProps {
  column: string;
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function CheckButton({
  data = [],
  checkedTags,
  column,
  showSectorHeader = false,
  sectorHeader,
  onCheck,
  columnName,
}: IProps) {
  const { transformedData } = useSortAndFilters(data);
  return (
    <div className="flex flex-col overflow-auto mt-2">
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="flex mb-[6px]">
        <p className="border-none text-primary text-[14px] rounded-[2px] font-bold outline-none">
          {columnName}
        </p>
      </div>
      <div className="filters-buttons ml-[-6px] max-h-[200px] md:max-h-full overflow-auto">
        {(data && data.length > 0) || column === "secondaryClassification" ? (
          <>
            {checkedTags &&
              transformedData.map((item, i) => {
                let tagCompare: number | string = item.tag;
                if (
                  column === "primaryClassification" ||
                  column === "secondaryClassification"
                ) {
                  tagCompare = item.id;
                }

                return (
                  <div
                    className={classnames(
                      "inline-flex justify-start cursor-pointer p-[6px]"
                    )}
                    key={item.tag + i}
                    onClick={() => {
                      onCheck(column, String(item.id), String(item.tag));
                    }}
                  >
                    <div
                      className={classnames(
                        "flex justify-center items-center align-middle text-left py-2 px-4 text-[12px] text-primary border border-primary rounded-[4px]",
                        {
                          "bg-primaryBlue text-white": Object.values(
                            checkedTags
                          ).find((tag) => String(tag) === String(tagCompare)),
                        }
                      )}
                    >
                      {item.tag}
                    </div>
                  </div>
                );
              })}
          </>
        ) : (
          <LoaderComponent />
        )}
      </div>
    </div>
  );
}
