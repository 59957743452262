import { useChangeFilterValue } from "hooks/useChangeFilterValue";
import { useSortByColumn } from "hooks/useSortByColumn";
import { ICheckedTags } from "types";
import { useSearchParam } from "hooks/useSearchParam";
import { useGraphQLUseCases } from "graphql-requests/UseCases";
import {
  UseCasesCurrentStatus,
  UseCasesIndustries,
  UseCasesProblemDomain,
} from "./UseCases.constants";
import { useMultipleOfArrayFilter } from "hooks/filters/useMultipleOfArrayFilter";
import { usePagination } from "hooks/usePagination";

export function useTagFilter() {
  const { sortQuery } = useSortByColumn('name');

  const { limit, offset } = usePagination();

  const search = useSearchParam();

  const {
    value: industries,
    updateValue: setIndustries,
    options: industriesOptions,
    graphqlParams: industriesWhere,
  } = useMultipleOfArrayFilter("industries", "industry_IN", UseCasesIndustries);

  const {
    value: problemDomain,
    updateValue: setProblemDomain,
    options: problemDomainOptions,
    graphqlParams: problemDomainWhere,
  } = useMultipleOfArrayFilter(
    "problemDomain",
    "problemDomain_IN",
    UseCasesProblemDomain
  );

  const {
    value: currentStatus,
    updateValue: setCurrentStatus,
    options: currentStatusOptions,
    graphqlParams: currentStatusWhere,
  } = useMultipleOfArrayFilter(
    "currentStatus",
    "currentStatus_IN",
    UseCasesCurrentStatus
  );

  const { loading, data: useCases } = useGraphQLUseCases({
    limit: Number(limit),
    offset: Number(offset),
    sort: sortQuery,
    where: {
      ...industriesWhere,
      ...problemDomainWhere,
      ...currentStatusWhere,
    },
    search: search,
  });

  const filterPanelData: ICheckedTags = {
    industries: industriesOptions,
    problemDomain: problemDomainOptions,
    currentStatus: currentStatusOptions,
  };

  const filters = {
    industries,
    problemDomain,
    currentStatus,
  };

  const onChangeFilter = useChangeFilterValue({
    industries: setIndustries,
    problemDomain: setProblemDomain,
    currentStatus: setCurrentStatus,
  });

  const loadingStatus = loading ? "loading" : "success";

  return {
    filterPanelData,
    filters,
    onChangeFilter,
    useCases,
    status: loadingStatus,
  };
}
