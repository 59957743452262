import { useMemo } from "react";
import { ICheckedTags, IFilterData } from "types";

export function useMapFilterPanelData(
  filterPanel: ICheckedTags,
  checkedTags: ICheckedTags
) {
  return useMemo(() => {
    if (!checkedTags) return;

    const arrayFilterPanel = Object.keys(checkedTags);
    const regularResponse: IFilterData[] = arrayFilterPanel.map((filterName) => {
      const filterOptions = filterPanel[filterName] || [];

      return {
        column: filterName,
        columnName: filterName
          .split("_")
          .map((str) => str.slice(0, 1).toUpperCase() + str.slice(1, str.length) + " ")
          .join(" ")
          .replace(/([a-z0-9])([A-Z])/g, "$1 $2"),
        data: filterOptions,
      };
    });

    regularResponse.push({
      column: "lastFundingDate",
      columnName: "Last funding date",
      data: [],
    });

    regularResponse.push({
      column: "dates",
      columnName: "Founded date range",
      data: [],
    });

    return regularResponse;
  }, [filterPanel, checkedTags]);
}
