import config from "config";
import { gql, useQuery } from "@apollo/client";
import { IAggregate, ICenterEntity, ICountryEntity } from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import {
  CREATE_CENTER_FIELDS,
  CREATE_CENTER_PARAMS,
  CENTER_FIELDS,
  UPDATE_CENTER_FIELDS,
  UPDATE_CENTER_PARAMS,
} from "./Center.constants";
import { ENTITY_STATUSES } from "const";

const GET_CENTERS = gql`
  query getCenters(
    $where: EntityWhere
    $sort: [EntitySort!]
    $limit: Int
    $offset: Int
    $phrase: String!
    $isAdmin: Boolean!
    $isAuth: Boolean!
  ) {
    centersAggregate: entitiesAggregate(where: $where, fulltext: { FullTextSearchFields: { phrase: $phrase } }) {
			count
		}
    centers: entities(
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      fulltext: { FullTextSearchFields: { phrase: $phrase } }
      ) {
      ${CENTER_FIELDS}
    }
    countries @include(if: $isAuth) {
      name
    }
    cityList @include(if: $isAuth)
  }
`;

type CenterVariableType = {
  where?: {
    name_CONTAINS?: string;
    id?: string;
  };
  sort?: {};
  limit?: number;
  offset?: number;
  search?: string | null;
  phrase?: string;
  isAdmin?: boolean;
};

export function useGraphQLCenters({
  limit,
  offset,
  sort,
  where,
  search,
}: CenterVariableType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<{
    centers: Array<ICenterEntity>;
    centersAggregate: IAggregate<{}>;
    countries: Partial<ICountryEntity>[];
    cityList: string[];
  }>(GET_CENTERS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        entityType_INCLUDES: "CENTER",
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
    },
  });

  return { loading, error, data };
}

export const ADD_CENTER = gql`
  mutation CreateCenter(${CREATE_CENTER_PARAMS}) {
    CreateCenter(${CREATE_CENTER_FIELDS}) {
      ${CENTER_FIELDS}
    }
  }
`;

export const UPDATE_CENTER = gql`
  mutation UpdateCenter(${UPDATE_CENTER_PARAMS}) {
    UpdateCenter(${UPDATE_CENTER_FIELDS}) {
      ${CENTER_FIELDS}
    }
  }
`;
