import classnames from "classnames";
import { useNavigate } from "react-router";

const PageSizeSelector = ({
  disabled,
  limit,
  params,
  sizes,
}) => {

  const navigate = useNavigate();

  let pageSizes = sizes || [30, 50, 100];

  return (
    <div
      className={classnames(
        "flex flex-row items-center over mx-4 mt-2 md:mt-0 text-[14px] font-normal",
      )}
    >
      <p className="flex md:self-center mr-2 text-[12px] text-primary">
        Show
      </p>
      <select
        disabled={disabled}
        className="w-[64px] h-[30px] text-[12px] text-primary bg-tablePrimary mx-2 py-0 px-1 inline-block border border-primary outline-none rounded-[4px]"
        value={limit}
        onChange={(e) => {
          // Pass limit in url
          params.delete("limit");
          params.append("limit", e.target.value);
          params.delete("offset");
          params.append("offset", "0");
          navigate({
            search: params.toString(),
          });
        }}
      >
        {pageSizes.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
      <p className="ml-2 text-[12px] text-primary">entries</p>
    </div>
  );
};

export default PageSizeSelector;