import { ChartOptions } from "chart.js";

export const barChartOptions: ChartOptions = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 0,
      borderRadius: 4,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "start",
      align: "end",
      formatter: function (_, context) {
        const currentLabel = String(
          context.chart.data.labels?.[context.dataIndex]
        );

        const barLabelWidth = context.chart.ctx.measureText(currentLabel).width;

        const { chart, datasetIndex, dataIndex } = context;
        const meta = chart.getDatasetMeta(datasetIndex);
        const model = meta.data[dataIndex];
        const { width: barWidth } = model.getProps(["width"], true);

        if (Math.round(Number(barWidth)) < 50) {
          return "";
        }

        if (Math.round(Number(barWidth)) < Math.round(barLabelWidth) + 6) {
          const maxLabelLength = Math.floor(Number(barWidth) / 10);
          return currentLabel.substring(0, maxLabelLength).trim() + "...";
        } else {
          return currentLabel;
        }
      },
      color: "#fff",
      font: {
        size: 12,
      },
    },
  },
  scales: {
    y: {
      display: false,
    },
    x: {
      grid: {
        display: true,
        borderDash: [4, 4],
        borderWidth: 1,
        lineWidth: 1,
      },
    },
  },
  datasets: {
    bar: {
      barThickness: 20,
      // barPercentage: 0.9,
    },
  },
};

export const lineChartOptions: ChartOptions = {
  elements: {
    point: {
      radius: 2,
    },
    line: {
      borderWidth: 1,
      tension: 0.2,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      grid: {
        display: true,
        borderDash: [4, 4],
        borderWidth: 1,
        lineWidth: 1,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

export const stackedBarChartOptions: ChartOptions = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: true,
      position: "bottom",
      align: "center",
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        padding: 14,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      grid: {
        display: true,
        borderDash: [4, 4],
        borderWidth: 1,
        lineWidth: 1,
      },
    },
  },
};

export const doughnutChartOptions: ChartOptions<"doughnut"> = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      labels: {
        value: {
          color: "white",
          font: { weight: "bold", size: 9 },
        },
      },
      formatter: (value: number, context: any) => {
        const label = context.chart.data.labels?.[context.dataIndex];
        const percentage = Math.round(
          (value /
            (context.dataset.data.reduce((a: number, b: number) => a + b, 0) ||
              1)) *
            100
        );
        return percentage > 5 ? `${label} \n(${percentage}%)` : "";
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

export const barChartColors = [
  "#F5C342",
  "#7EAB55",
  "#4FCD8A",
  "#10BBB9",
  "#56D9D9",
  "#56A2D9",
  "#3E76DC",
  "#6B32FF",
  "#AF56D9",
  "#F07FE5",
];

export const stackedBarChartColors = [
  "#00304B",
  "#004A73",
  "#00649C",
  "#1485C5",
  "#2FA9EE",
  "#57C2FF",
  "#7CD0FF",
  "#A1DDFF",
  "#D5EAF5",
  "#EEF8FE",
];
