import classnames from "classnames";
import { CSVLink } from "react-csv";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import config from "config";
import { ThreeDots } from "react-loader-spinner";
import exportCsvIcon from "assets/images/exportCsvIcon.svg";
import { ExportButtonProps } from "../TableExtended.types";
import { useCheckIsAdmin, useCheckSuper } from "utils";
import ReactTooltip from "react-tooltip";

const ExportButton = ({
  tableName,
  className,
  queryStatus,
  loadingExport,
  dataExport,
  getExportData,
  columnVisibility,
}: ExportButtonProps) => {
  const dataName = tableName
    ? tableName?.toLocaleLowerCase().replaceAll(" ", "_")
    : "export_data";
  const csvFilename = config.PLATFORM_NAME + "_" + dataName;

  const csvRef = useRef();
  const [isDataExported, setIsDataExported] = useState(false);

  const selectedColumns = useMemo(() => {
    if (columnVisibility) {
      return Object.keys(columnVisibility).filter(
        (key) => columnVisibility[key]
      );
    }
    return [];
  }, [columnVisibility]);

  const filteredData = useMemo(() => {
    return dataExport?.map((data) => {
      const filteredData = {};
      selectedColumns.forEach((column) => {
        filteredData[column] = data[column];
        if (column === "primaryClassification") {
          filteredData[column] =
            data?.inClassification?.[0]?.childOf?.[0]?.name ?? "";
        }
        if (column === "secondaryClassification") {
          filteredData[column] = data?.inClassification?.[0]?.name ?? "";
        }
        if (column === "region") {
          filteredData[column] = data?.inCountry?.[0]?.region?.[0]?.name ?? "";
        }
        if (column === "country") {
          filteredData[column] = data?.inCountry?.[0]?.name ?? "";
        }
        if (column === "investors") {
          const uniqueInvestors = new Set();
          data?.fundingRounds?.forEach((round) => {
            round?.investors?.forEach((inv) => {
              uniqueInvestors.add(inv.name);
            });
          });
          filteredData[column] = Array.from(uniqueInvestors).join(", ");
        }
        if (column === "organizationFacilitatesAccessTo") {
          const uniqueAccess = new Set();
          data?.organizationFacilitatesAccessTo?.forEach((access) => {
            uniqueAccess.add(access.name);
          });
          filteredData[column] = Array.from(uniqueAccess).join(", ");
        }
        if (column === "facilitatesAccessToOrganizations") {
          const uniqueAccess = new Set();
          data?.facilitatesAccessToOrganizations?.forEach((access) => {
            uniqueAccess.add(access.name);
          });
          filteredData[column] = Array.from(uniqueAccess).join(", ");
        }
        if (column === "raised") {
          const filteredRaised = data?.raised <= 2 ? "Unknown" : data?.raised;
          filteredData[column] = filteredRaised;
        }
        if (column === "partnerships") {
          const uniquePartnerships = new Set();
          data?.partnerships?.forEach((partnership) => {
            partnership?.entities?.forEach((entity) => {
              if (entity.name !== data.name) {
                uniquePartnerships.add(entity.name);
              }
            });
          });
          filteredData[column] = Array.from(uniquePartnerships).join(", ");
        }
      });
      return filteredData;
    });
  }, [dataExport, selectedColumns]);

  const csvExportData = useMemo(() => {
    let csvExportData: Record<string, string | number>[] = [];
    if (!filteredData) {
      return csvExportData;
    }
    filteredData?.forEach((data) => {
      const row: Record<string, string | number> = {};
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (value && typeof value === "string") {
          value = value.replace(/(\r\n|\n|\r)/gm, "").replace(/['"]+/g, "");
        }
        if (!value) {
          value = "-";
        }
        if (value.length && Array.isArray(value)) {
          value = value
            .map((item) => {
              if (item.name) {
                return item.name;
              }
              if (item.type) {
                return item.type;
              }
              return "-";
            })
            .join(", ");

          if (key === "primaryClassification" && data[key].length > 0) {
            value = data[key][0]?.childOf[0]?.name;
          }
          if (key === "organizationHasRound" && data[key].length > 0) {
            value = data[key][0]?.name;
          }
          if (key === "consortiumHasEntities" && data[key].length > 0) {
            value = data[key].map((item) => item.name).join(", ");
          }
          if (key === "entityType" && data[key].length > 0) {
            value = data[key][0];
          }
        }
        if (key !== "__typename") {
          row[key] = value;
        }
      });
      csvExportData.push(row);
    });
    return csvExportData;
  }, [filteredData]);

  useEffect(() => {
    if (csvExportData.length && !loadingExport) {
      (csvRef.current as any)?.link?.click();
      setIsDataExported(true);
    }
  }, [csvExportData, loadingExport]);

  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();

  return (
    <div
      className={classnames(
        "border border-solid max-h-[36px] min-h-[36px] h-[36px] px-3 gap-2 flex justify-center bg-tableSecondary text-primaryBlue font-bold !text-[12px] border-primary rounded-[4px]",
        className,
        { "!cursor-not-allowed": queryStatus === "loading" },
        { "!cursor-progress": loadingExport },
        { "hover:border-primaryBlue": !isDataExported }
      )}
    >
      {!isDataExported ? (
        <>
          {!isAdmin && !isSuper && (
            <ReactTooltip
              id="csv_export"
              className="max-w-[220px] p-1 text-[12px] text-center rounded !opacity-100"
            />
          )}
          <div
            data-for="csv_export"
            data-tip="Restricted to first 200 results. Please use the Ask a Question button for larger bespoke requests"
            className={classnames(
              "flex flex-row gap-2 items-center py-2 text-[12px] text-primaryBlue cursor-pointer items-center",
              {
                "pointer-events-none opacity-50 select-none":
                  loadingExport || queryStatus === "loading",
              }
            )}
            onClick={() => {
              if (getExportData) {
                getExportData();
              }
            }}
          >
            <img src={exportCsvIcon} alt="exportCsvIcon" className="w-[20px]" />

            {loadingExport ? (
              <div className="flex items-center p-2 text-[12px]">
                <ThreeDots color="#00BFFF" width={42} height={12} />
              </div>
            ) : (
              <span className="flex items-center">Export to CSV</span>
            )}
          </div>
          <CSVLink
            data={csvExportData || ""}
            filename={csvFilename}
            className="hidden"
            ref={csvRef}
          />
        </>
      ) : (
        <div className="flex py-2 text-[12px] text-secondary items-center select-none">
          The data has been exported
        </div>
      )}
    </div>
  );
};

export default memo(ExportButton);
