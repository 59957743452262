import { gql, useQuery } from "@apollo/client";
import { ENTITY_STATUSES } from "const";
import { IUseCasesEntity } from "types";
import { USECASES_FIELDS } from "./UseCases.constants";
import config from "config";
import { useCheckIsAdmin, useCheckSuper } from "utils";

const GET_USECASES = gql`
  query GetUseCases(
    $where: UseCaseWhere
    $sort: [UseCaseSort!]
    $limit: Int
    $offset: Int
  ) {
    useCasesAggregate (where: $where) {
			count
		}
    useCases (
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      ) {
      ${USECASES_FIELDS}
    }
  }
`;

type UseCasesVarsType = {
  limit?: number;
  offset?: number;
  sort?: {};
  where?: { status?: ENTITY_STATUSES; name_CONTAINS?: string; id?: string };
  search?: string | null;
};

export function useGraphQLUseCases({
  limit,
  offset,
  sort,
  where,
  search,
}: UseCasesVarsType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const { loading, error, data } = useQuery<{
    useCases: IUseCasesEntity[];
    useCasesAggregate: { count: number };
  }>(GET_USECASES, {
    variables: {
      limit: limit,
      where: {
        ...where,
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
    },
  });

  return { loading, error, data };
}
