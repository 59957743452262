import config from "config";
import { gql, QueryFunctionOptions, useQuery } from "@apollo/client";
import {
  IAggregate,
  ICountryEntity,
  IEnterpriseUserEntity,
  VariableType,
} from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import { ENTERPRISE_USER_FIELDS } from "./EnterpriseUser.constants";
import { ENTITY_STATUSES } from "const";

const GET_USERS = gql`
  query getEnterpriseUsers (
    $where: EntityWhere
    $sort: [EntitySort!]
    $limit: Int
    $offset: Int
    $phrase: String!
    $isAdmin: Boolean!
    $isAuth: Boolean!
  ){
    enterpriseUsersAggregate: entitiesAggregate (where: $where, fulltext: { FullTextSearchFields: { phrase: $phrase } }) {
			count
		}
    enterpriseUsers: entities(
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      fulltext: { FullTextSearchFields: { phrase: $phrase } }
      ) {
      ${ENTERPRISE_USER_FIELDS}
    }
    countries @include(if: $isAuth) {
      name
    }
    cityList @include(if: $isAuth)
  }
`;

export function useGraphQLEnterpriseUser({
  limit,
  offset,
  sort,
  where,
  search,
}: VariableType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();

  const { loading, error, data } = useQuery<
    {
      enterpriseUsers: Array<IEnterpriseUserEntity>;
      enterpriseUsersAggregate: IAggregate<{}>;
      countries: Partial<ICountryEntity>[];
      cityList: string[];
    },
    VariableType
  >(GET_USERS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        entityType_INCLUDES: "BRAND",
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
    },
  });

  return { loading, error, data };
}

type EnterpriseUsersVariableType = {
  where: {
    id: string | undefined;
    entityType_INCLUDES?: string;
    vertical_INCLUDES?: string;
  };
  isAdmin?: boolean;
  isAuth?: boolean;
};

export function useEnterpriseUser({
  id,
  ...rest
}: {
  id: string | undefined;
} & QueryFunctionOptions<
  {
    enterpriseUsers: Array<IEnterpriseUserEntity>;
  },
  EnterpriseUsersVariableType
>) {
  const { loading, error, data } = useQuery<
    {
      enterpriseUsers: Array<IEnterpriseUserEntity>;
    },
    EnterpriseUsersVariableType
  >(
    gql`
      query getEnterpriseUser($where: EntityWhere, $isAdmin: Boolean!, $isAuth: Boolean!) {
        enterpriseUsers: entities(where: $where) {
          ${ENTERPRISE_USER_FIELDS}
        }
      }
    `,
    {
      variables: {
        where: {
          id: id || "",
          entityType_INCLUDES: "BRAND",
          vertical_INCLUDES: config.VERTICAL,
        },
        isAdmin: true,
        isAuth: true,
      },
      ...rest,
    }
  );

  return { loading, error, data };
}
