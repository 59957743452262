import config from "config";
import { gql, useQuery } from "@apollo/client";
import { IEntity } from "modules/Dashboard/DashboardModules/DashboardLatestDateUpdates/DashboardLatestDateUpdates.types";
import { IGraphingTool, IRoundEntity } from "types";

const LATEST_UPDATES_ENTITY_TYPES = [
  "ORGANIZATION",
  ...(config.MODULES.includes("INVESTORS") ? ["INVESTOR"] : []),
  ...(config.MODULES.includes("GROUPS_AND_CENTERS") ? ["CENTER"] : []),
  ...(config.MODULES.includes("UNIVERSITIES") ? ["UNIVERSITY"] : []),
  ...(config.MODULES.includes("CONSORTIUMS") ? ["CONSORTIUM"] : []),
  ...(config.MODULES.includes("BRANDS") ? ["BRAND"] : []),
  ...(config.MODULES.includes("GOVERNMENTS")
    ? ["GOVERNMENT_AGENCY", "NATIONAL_LAB"]
    : []),
];

const GET_DATA_VERTICAL = gql`
  query getDashboardData {
    companyCount: entitiesAggregate(
      where: { entityType_INCLUDES: ORGANIZATION, vertical_INCLUDES: ${
        config.VERTICAL
      }, status: APPROVED }
    ) {
      count
    }
    investorCount: entitiesAggregate(
      where: { entityType_INCLUDES: INVESTOR, vertical_INCLUDES: ${
        config.VERTICAL
      }, status: APPROVED }
    ) {
      count
    }
    userCount: entitiesAggregate(
      where: { entityType_INCLUDES: BRAND, vertical_INCLUDES: ${
        config.VERTICAL
      }, status: APPROVED }
    ) {
      count
    }
    roundCount: fundingRoundsAggregate (
      where: {
        vertical_INCLUDES: ${config.VERTICAL}, 
        OR: [
          { isGovernment: false }
          { isGovernment: null }
        ], 
        status: APPROVED 
      }
    ) {
      count
    }
    fundingRounds(
      where: {
        vertical_INCLUDES: ${config.VERTICAL}, 
        OR: [
          { isGovernment: false }
          { isGovernment: null }
        ], 
        status: APPROVED 
      }
      options: { limit: 15, sort: { startDate: DESC } }
    ) {
      id
      organizationHasRound {
        id
        name
        logo
      }
      type
      fundingUsd
      startDate
      updatedAt
    }
    partnershipsCount: partnershipsAggregate(
      where: { vertical_INCLUDES: ${
        config.VERTICAL
      }, createdAt_GT: "1970-01-01" }
    ) {
      count
    }
    lastMonthFundingRoundsSum
    lastYearFundingRoundsSum
    allEntityCount
    totalRoundsUsd: fundingRoundsSum
    latestUpdates: entities(
      options: { limit: 15, sort: { updatedAt: DESC } }
      where: { 
        vertical_INCLUDES: ${config.VERTICAL}, 
        status: APPROVED, 
        OR: [
          ${LATEST_UPDATES_ENTITY_TYPES.map(
            (entityType) => `{ entityType_INCLUDES: ${entityType} }`
          ).join("\n")}
        ]
      }
    ) {
      id
      name
      updatedAt
      logo
      entityType
      vertical
    }
    graphingToolReport(
      startDate: "2014-01-01"
      endDate: "2025-01-01"
      period: "year"
      classification: "primaryClassification"
    ) {
      month
      classification
      total
    }
  }
`;

export function useGraphQLDashboard() {
  const { loading, error, data } = useQuery<{
    latestUpdates: IEntity[] | undefined;
    fundingRounds: Array<IRoundEntity>;
    graphingToolReport: Array<IGraphingTool>;
    allEntityCount: number;
    companyCount: { count: number };
    investorCount: { count: number };
    userCount: { count: number };
    roundCount: { count: number };
    partnershipsCount: { count: number };
    lastMonthFundingRoundsSum: string;
    lastYearFundingRoundsSum: string;
    totalRoundsUsd: string;
  }>(GET_DATA_VERTICAL);

  return { loading, error, data };
}
