import { AdvanceSelect } from "components/AdvancedSelectField";
import { useCallback, useMemo } from "react";
import { SingleValue, MultiValue } from "react-select";
import { ISelectOption } from "types";
import { usePatentsEntityDynamicProps } from "./PatentFilter.hooks";
import { useLocation, useNavigate } from "react-router";

const PatentFilterEntity = ({
  paramName,
  filterBy = "UO",
}: {
  paramName: "organization" | "inventor";
  filterBy?: "UO" | "INV";
}) => {
  const {
    options: entityOptions,
    makeSearch: entityMakeSearch,
    loading: isEntityLoading,
  } = usePatentsEntityDynamicProps({ filterBy: filterBy });

  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  return (
    <AdvanceSelect
      key={paramName}
      value={
        params.get(paramName)
          ? {
              value: params.get(paramName),
              label: params.get(paramName),
            }
          : null
      }
      options={entityOptions}
      makeSearch={entityMakeSearch}
      isLoading={isEntityLoading}
      debounceTime={800}
      onChange={useCallback(
        (
          value: MultiValue<ISelectOption> | SingleValue<ISelectOption> | null
        ) => {
          if (value && "value" in value) {
            if (value.value) {
              params.delete("offset");
              params.delete("limit");
              params.set(paramName, value.value);
              navigate({ search: params.toString() });
            }
          }
        },
        [navigate, paramName, params]
      )}
      onBlur={() => {}}
      placeholder={"Search for " + paramName}
      noOptionsMessage={() => {
        return "Try search for " + paramName;
      }}
      classNames={{
        input: "bg-primary h-[44px] rounded-md",
      }}
      className="!p-0 w-full"
      isSearchable
    />
  );
};
export default PatentFilterEntity;
