import classnames from "classnames";
import {
  useGraphQLPrimaryClassification,
  useGraphQLSecondaryClassification,
} from "graphql-requests";
import { useState } from "react";
import { FilterUpdateFunc } from "../Filter.types";

interface IProps {
  checkedTag: string;
  item: string;
  onCheckTagToggle: FilterUpdateFunc;
}

export default function FilterActive({
  item,
  checkedTag,
  onCheckTagToggle,
}: IProps) {
  const [showClose, setShowClose] = useState(false);

  const primaryClassifications = useGraphQLPrimaryClassification({});
  const secondaryClassifications = useGraphQLSecondaryClassification();

  let tagName = item;

  primaryClassifications.data?.primaryClassifications?.forEach((tags) => {
    if (item === tags?.id) {
      tagName = tags.name;
    }
  });

  secondaryClassifications.data?.secondaryClassifications?.forEach((tags) => {
    if (item === tags?.id) {
      tagName = tags.name;
    }
  });

  return (
    <div
      onMouseOut={() => {
        setShowClose(false);
      }}
      onMouseOver={() => {
        setShowClose(true);
      }}
      className={classnames(
        "inline-block bg-primaryBlue rounded-md m-1 p-1 min-w-[183px] w-max h-[40px] border border-primary text-white text-xs relative pointer-events-auto "
      )}
    >
      <div
        className={classnames(
          "absolute bg-primaryBlue bottom-6 -right-2 border border-primary w-[20px] h-[20px] rounded-full   text-center flex justify-center items-center z-50",
          {
            hidden: !showClose,
            "cursor-pointer": showClose,
          }
        )}
        onClick={() => {
          onCheckTagToggle(checkedTag, null, item, true);
        }}
      >
        X
      </div>
      <div className="flex items-center justify-center relative w-full h-full">
        <div className="flex flex-row items-center justify-center">
          <p className="text-[12px] text-center font-bold">{tagName}</p>
        </div>
      </div>
    </div>
  );
}
