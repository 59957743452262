import { useCallback, useEffect, useMemo, useState } from "react";
import {
  PatentDocs,
  PatentLinksData,
  PatentLoadingLinks,
  PatentLinksQuery,
  PlatformEntities,
} from "./Patents.types";
import {
  parsePatentJsonData,
  transformQueryString,
} from "./helpers/Patents.utils";
import ReactTooltip from "react-tooltip";
import { useLazyQuery } from "@apollo/client";
import { ThreeDots } from "react-loader-spinner";
import { PlatformEntitiesMatch } from "./helpers/PlatformEntitiesMatch";
import { GET_PATENT_LINKS } from "./Patents.queries";
import { useLocation } from "react-router";
import { addDays, format, parseISO } from "date-fns";
import { usePagination } from "hooks/usePagination";
import { useGraphQLSearchKeys } from "graphql-requests/SearchKey";

export function useMapPatentsData({
  patents,
  platformEntities,
}: {
  patents: PatentDocs[] | undefined;
  platformEntities: PlatformEntities["entities"] | undefined;
}) {
  const { pdfData, loadingLinks, isLinksLoading, handlePatentClick } =
    usePatentsLinks();

  const mappedPatents = useMemo(() => {
    if (!patents) return [];
    return patents?.map((patent, index) => {
      const {
        parsedTitles,
        parsedAbstracts,
        parsedPublicationDate,
        parsedInventors,
        parsedRelatedPatents,
        parsedStatus,
        parsedExpectedExpiry,
        parsedExpectedExpiryDate,
        parsedUltimateOwner,
        parsedApplicationDate,
      } = parsePatentJsonData(patent);

      return {
        col0: (
          <div
            key={index + "col0"}
            className="flex justify-start text-left align-middle max-h-[240px] w-full h-full overflow-y-auto text-primaryBlue font-medium"
          >
            <div
              data-for={"patent_" + index}
              data-tip={"Patent ID: " + patent?.id}
              className="h-fit w-full px-1 flex text-balance"
            >
              <ReactTooltip
                place="top"
                id={"patent_" + index}
                className="max-w-[220px] p-1 text-[12px] text-center rounded !opacity-100"
              />
              {parsedTitles && parsedTitles !== "-" ? (
                <>
                  {pdfData[patent.id]?.PdfURL === "NOT FOUND" ? (
                    <span className="text-primary">{parsedTitles}</span>
                  ) : (
                    <>
                      {pdfData[patent.id] ? (
                        <a
                          href={pdfData[patent.id]?.PdfURL || ""}
                          target="_blank"
                          rel="noreferrer"
                          className="text-primaryBlue font-medium hover:underline"
                        >
                          {parsedTitles}
                        </a>
                      ) : (
                        <div
                          className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                          onClick={() => handlePatentClick(patent.id, "pdf")}
                        >
                          {parsedTitles}
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <span className="text-primary">-</span>
              )}
            </div>
          </div>
        ),
        col1: (
          <div
            key={index + "col1"}
            className="flex justify-start text-left align-middle max-h-[240px] overflow-y-auto text-[12px]"
          >
            <div
              data-for={"abstracts_" + index}
              data-tip={parsedAbstracts}
              className="h-fit w-full pr-1 flex text-balance"
            >
              <ReactTooltip
                place="top"
                id={"abstracts_" + index}
                className="max-w-[400px] py-1 text-[12px] text-left rounded !opacity-100"
              />
              <span>
                {!!parsedAbstracts
                  ? parsedAbstracts?.slice(0, 250).trim() + "..."
                  : "-"}
              </span>
            </div>
          </div>
        ),
        col2: (
          <div
            key={index + "col2"}
            className="flex justify-start text-left align-middle"
          >
            {parsedPublicationDate || "-"}
          </div>
        ),
        col3: (
          <div key={index + "col3"} className="text-left min-w-[160px]">
            {parsedUltimateOwner?.length > 0 &&
              parsedUltimateOwner?.map((entity: string, index: number) => (
                <span key={index}>
                  <PlatformEntitiesMatch
                    entityName={entity}
                    platformEntities={platformEntities}
                  />
                  {index < parsedUltimateOwner?.length - 1 && ", "}
                </span>
              ))}
            {parsedUltimateOwner?.length === 0 && "-"}
          </div>
        ),
        col4: (
          <div
            key={index + "col4"}
            className="flex justify-start text-left align-middle min-w-[160px] max-h-[240px] overflow-y-auto"
          >
            {parsedInventors}
          </div>
        ),
        col5: (
          <div
            key={index + "col5"}
            className="flex justify-start text-left align-middle"
          >
            <>
              {pdfData[patent.id]?.PdfURL === "NOT FOUND" ? (
                "PDF Not Found"
              ) : (
                <>
                  {pdfData[patent.id] ? (
                    <a
                      href={pdfData[patent.id]?.PdfURL || ""}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primaryBlue font-medium hover:underline"
                    >
                      View Patent PDF
                    </a>
                  ) : (
                    <div
                      className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                      onClick={() => handlePatentClick(patent.id, "pdf")}
                    >
                      View Patent PDF
                    </div>
                  )}
                  {loadingLinks[patent.id] && (
                    <div className="flex flex-col items-center pl-2 pt-[2px] h-fit">
                      <ThreeDots color="#00649C" height={10} width={10} />
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        ),
        col6: (
          <div
            key={index + "col6"}
            className="text-left min-w-[160px] max-h-[240px] overflow-y-auto text-[12px]"
          >
            {parsedRelatedPatents?.length > 0
              ? parsedRelatedPatents?.map((patent, index) => (
                  <span key={index} className="text-primaryBlue font-medium">
                    {pdfData[patent]?.PdfURL === "NOT FOUND" ? (
                      <span className="text-primary">{patent}</span>
                    ) : (
                      <>
                        {pdfData[patent] ? (
                          <a
                            href={pdfData[patent]?.PdfURL || ""}
                            target="_blank"
                            rel="noreferrer"
                            className="text-primaryBlue font-medium hover:underline"
                          >
                            {patent}
                          </a>
                        ) : (
                          <span
                            className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                            onClick={() => handlePatentClick(patent, "pdf")}
                          >
                            {patent}
                          </span>
                        )}
                      </>
                    )}
                    {index < parsedRelatedPatents?.length - 1 && ", "}
                  </span>
                ))
              : "-"}
          </div>
        ),
        col7: (
          <div
            key={index + "col7"}
            className="flex justify-start text-left align-middle"
          >
            <>
              {pdfData[patent.id]?.FamilytreeURL === "NOT FOUND" ? (
                "Family Tree Not Found"
              ) : (
                <>
                  {pdfData[patent.id] ? (
                    <a
                      href={pdfData[patent.id]?.FamilytreeURL || ""}
                      target="_blank"
                      rel="noreferrer"
                      className="text-primaryBlue font-medium hover:underline"
                    >
                      Family Tree
                    </a>
                  ) : (
                    <div
                      className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                      onClick={() => handlePatentClick(patent.id, "tree")}
                    >
                      Family Tree
                    </div>
                  )}
                  {loadingLinks[patent.id] && (
                    <div className="flex flex-col items-center pl-2 pt-[2px] h-fit">
                      <ThreeDots color="#00649C" height={10} width={10} />
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        ),
        col8: (
          <div
            key={index + "col8"}
            className="flex justify-start text-left align-middle"
          >
            {parsedStatus || "-"}
          </div>
        ),
        col9: (
          <div
            key={index + "col9"}
            className="flex justify-start text-left align-middle"
          >
            {parsedExpectedExpiry?.["explanation-link"] &&
            parsedExpectedExpiry?.["explanation"] ? (
              <div
                data-for={"explanation_" + parsedExpectedExpiryDate}
                data-tip={parsedExpectedExpiry?.["explanation"]}
                className="h-fit"
              >
                <ReactTooltip
                  place="top"
                  id={"explanation_" + parsedExpectedExpiryDate}
                  className="max-w-[260px] py-1 text-[12px] text-left rounded !opacity-100"
                />
                <a
                  href={parsedExpectedExpiry?.["explanation-link"]}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primaryBlue font-medium hover:underline"
                >
                  {parsedExpectedExpiryDate || "-"}
                </a>
              </div>
            ) : (
              parsedExpectedExpiryDate || "-"
            )}
          </div>
        ),
        col10: (
          <div
            key={index + "col10"}
            className="flex justify-start text-left align-middle"
          >
            {parsedApplicationDate || "-"}
          </div>
        ),
      };
    });
  }, [handlePatentClick, loadingLinks, patents, pdfData, platformEntities]);

  return { mappedPatents, isLinksLoading };
}

export const usePatentFilters = ({
  customStartDate,
}: {
  customStartDate?: string;
}) => {
  const { limit, offset } = usePagination(10);
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const sortParam = params.get("sort");
  const startDateParam = params.get("startDate");
  const endDateParam = params.get("endDate");

  const [sortBy, setSortBy] = useState("pd_desc");
  const [startDate, setStartDate] = useState(
    startDateParam
      ? format(parseISO(startDateParam), "yyyyMMdd")
      : customStartDate
      ? customStartDate
      : // : format(addDays(new Date(), -366), "yyyyMMdd")
        null
  );

  const [endDate, setEndDate] = useState(
    endDateParam
      ? format(addDays(parseISO(endDateParam), 1), "yyyyMMdd")
      : format(addDays(new Date(), 1), "yyyyMMdd")
  );

  useEffect(() => {
    if (sortParam) {
      const parseSortBy =
        sortParam === "-publicationdate"
          ? "pd_desc"
          : sortParam === "publicationdate"
          ? "pd_asc"
          : sortParam === "-applicationdate"
          ? "ad_desc"
          : sortParam === "applicationdate"
          ? "ad_asc"
          : sortParam === "-ultimate_owner"
          ? "owner_desc"
          : sortParam === "ultimate_owner"
          ? "owner_asc"
          : sortParam === "score"
          ? "score"
          : "pd_desc";
      setSortBy(parseSortBy);
    } else {
      setSortBy("pd_desc");
    }
  }, [sortParam]);

  useEffect(() => {
    if (startDateParam) {
      setStartDate(format(parseISO(startDateParam), "yyyyMMdd"));
    } else {
      setStartDate(
        // customStartDate || format(addDays(new Date(), -366), "yyyyMMdd")
        customStartDate || null
      );
    }
    if (endDateParam) {
      setEndDate(format(parseISO(endDateParam), "yyyyMMdd"));
    } else {
      setEndDate(format(new Date(), "yyyyMMdd"));
    }
  }, [endDateParam, startDateParam, customStartDate]);

  const { keyString } = useGraphQLSearchKeys();

  const keyString_ = useMemo(() => {
    // return keyString.replace(/\*/g, "");
    // return keyString.replace(/['"]+/g, "");
    return keyString;
  }, [keyString]);

  const defaultKeywords = useMemo(() => {
    const query = transformQueryString(keyString_);
    return query;
  }, [keyString_]);

  const [filtersState, setFiltersState] = useState({
    entities: !params.get("restricted") ? defaultKeywords : [],
    keywords: "",
  });

  useEffect(() => {
    if (keyString_) {
      setFiltersState((prev) => {
        return {
          ...prev,
          entities: !params.get("restricted")
            ? transformQueryString(keyString_)
            : [],
        };
      });
    }
  }, [keyString_, params]);

  const pdParamFrom = startDate
    ? filtersState.entities.length > 0
      ? ` AND pd >= ${startDate}`
      : `pd >= ${startDate}`
    : "";
  const pdParamEnd = endDate
    ? filtersState.entities.length > 0 || startDate
      ? ` AND pd <= ${endDate}`
      : `pd <= ${endDate}`
    : "";

  const organizationParam =
    !!params.get("organization") &&
    encodeURIComponent(params.get("organization") as string);

  const organizationQuery = !!organizationParam
    ? filtersState.entities.length > 0 || startDate || endDate
      ? ` AND UO=(${organizationParam || ""})`
      : `UO=(${organizationParam || ""})`
    : "";

  const inventorsParam = params.get("inventor");
  const inventorsQuery = inventorsParam
    ? filtersState.entities.length > 0 ||
      startDate ||
      endDate ||
      !!organizationParam
      ? ` AND INV=(${inventorsParam || ""})`
      : `INV=(${inventorsParam || ""})`
    : "";

  return {
    pdParamFrom,
    pdParamEnd,
    organizationQuery,
    inventorsQuery,
    sortBy,
    filtersState,
    setFiltersState,
    limit,
    offset,
    defaultKeywords,
  };
};

export const usePatentsLinks = () => {
  const [getPatentLinks] = useLazyQuery<PatentLinksQuery>(GET_PATENT_LINKS);

  const [pdfData, setPdfData] = useState<PatentLinksData>({});
  const [loadingLinks, setLoadingLinks] = useState<PatentLoadingLinks>({});
  const [isLinksLoading, setIsLinksLoading] = useState(false);

  const handlePatentClick = useCallback(
    async (patentID: string, linkType: "pdf" | "tree") => {
      try {
        setLoadingLinks((prev) => {
          return {
            ...prev,
            [patentID]: true,
          };
        });
        setIsLinksLoading(true);
        await getPatentLinks({
          variables: {
            queryString: `id=${patentID}`,
          },
        }).then((res) => {
          setPdfData((prev) => ({
            ...prev,
            [patentID]: res.data?.patentLink,
          }));

          if (
            linkType === "pdf" &&
            res.data?.patentLink?.PdfURL !== "NOT FOUND"
          ) {
            window.open(res.data?.patentLink?.PdfURL, "_blank");
          } else if (
            linkType === "tree" &&
            res.data?.patentLink?.FamilytreeURL !== "NOT FOUND"
          ) {
            window.open(res.data?.patentLink?.FamilytreeURL, "_blank");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingLinks((prev) => {
          return {
            ...prev,
            [patentID]: false,
          };
        });
        setIsLinksLoading(false);
      }
    },
    [getPatentLinks, setPdfData]
  );

  return { pdfData, loadingLinks, isLinksLoading, handlePatentClick };
};
