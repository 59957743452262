import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { barChartOptions } from "./PatentsStats.constants";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { PatentsChartBarData } from "./PatentsStats.types";

export const PatentsStatsChartBar = ({
  barLabels,
  barDatasets,
  title,
}: PatentsChartBarData) => {
  return (
    <div className="flex flex-col w-[520px] h-[350px] bg-tablePrimary rounded p-3">
      <div className="text-primary text-[18px] font-medium p-1 mb-2">
        {title}
      </div>
      <div className="h-full">
        <Bar
          data={{
            labels: barLabels,
            datasets: barDatasets,
          }}
          options={barChartOptions as ChartOptions<"bar">}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
};
