import { IFilterType } from "types";
import { useEffect } from "react";
import { FilterUpdateFunc } from "../../../Filter.types";

export function useRangeDatesMapping(
  column: string,
  dateData: IFilterType[],
  minChecked: number | undefined,
  maxChecked: number | undefined,
  onCheck: FilterUpdateFunc,
  checkedTags: string[]
) {
  useEffect(() => {
    if (dateData && minChecked && maxChecked && checkedTags) {
      dateData
        .sort((a, b) =>
          Number(new Date(a.tag).getTime()) > Number(new Date(b.tag).getTime())
            ? 1
            : Number(new Date(b.tag).getTime()) >
            Number(new Date(a.tag).getTime())
              ? -1
              : 0
        )
        .filter((item) => {
          return (
            item.tag &&
            item.tag !== "NaN" &&
            !isNaN(new Date(item.tag).getTime()) &&
            Number(new Date(item.tag).getTime()) >= minChecked &&
            Number(new Date(item.tag).getTime()) <= maxChecked
          );
        })
        .forEach((element: any, i) => {
          if (!checkedTags[element.id]) {
            onCheck(column, String(element.id), String(element.tag));
          }
        });
    }
  }, [dateData, minChecked, maxChecked, checkedTags, onCheck, column]);
}
