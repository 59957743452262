import classnames from "classnames";
import { Input } from "components/Input";
import { FilterUpdateFunc } from "../../../Filter.types";

interface IProps {
  column: string;
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function RangeInputs({
  column,
  checkedTags,
  columnName,
  showSectorHeader = false,
  sectorHeader,
  onCheck,
}: IProps) {

  return (
    <div className="flex flex-col w-full h-full overflow-hidden mt-2">
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="flex mb-1 relative h-full">
        <div
          className={classnames("h-full w-full overflow-hidden  bg-primary ")}
        >
          <div className="text-primary text-[14px] font-bold pb-[6px]">
            {columnName}
          </div>
          <div
            className={classnames(
              "flex flex-row justify-start items-center bg-primary w-full mt-2"
            )}
          >
            <Input
              maxLength={4}
              placeholder="From"
              className="bg-primary border border-primary w-[340px] h-[40px] mr-[5px] rounded-md outline-none p-4 text-[#98A0A6] text-[12px]"
              type="number"
              value={checkedTags[0]}
              onChange={(e) => onCheck(column[0],'min', e.target.value)}
            />
            <Input
              maxLength={4}
              placeholder="To"
              type="number"
              value={checkedTags[1]}
              onChange={(e) => onCheck(column[1],'max', e.target.value)}
              className="bg-primary border border-primary w-[340px] h-[40px] ml-[14px] rounded-md outline-none p-4 text-[#98A0A6] text-[12px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
