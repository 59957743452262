import { ENTITY_STATUSES } from "const";
import { IProfile, ISelectOption, ISubscription } from "./common";

export interface IConsortiumEntity {
  id?: string;
  name: string;
  logo?: string | null | undefined;
  inCountry: ICountryEntity[];
  description: string;
  goals: string;
  consortiumHasEntities: IEntity[];
  website: string;
  lat: number | null;
  lng: number | null;
  city?: string | null;
  status: string | null | ENTITY_STATUSES | undefined;
  createdAt: string | null;
  updatedAt: string | null;
  partnerships: IPartnership[];
  __typename?: string;
}

interface IGrantEntity {
  id?: string;
  name: string;
  hasProjectProjects: IProjectEntity[];
  offeredAwardProjects: IProjectEntity[];
  year: number;
}

interface IProjectEntity {
  id?: string;
  name: string;
  governmentEntityLead: IGovernmentEntity[];
  grantsHasProject: IGrantEntity[];
  grantsOfferedAward: IGrantEntity[];
  organizationLead: IOrganizationEntity[];
  publicDescription: string;
  universityLead: IUniversityEntity[];
}

export interface IAggregate<T> {
  count: number;
  node: T;
}

export interface IFundingRoundEntity {
  startDate: string | null; //Date of funding*
  source: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  fxRate: number | null; //-
  status: ENTITY_STATUSES | null;
  id: string | null;
  type?: string | null; //Type of funding round* input
  investors: IInvestorEntity[];
  organization: string | null; //Name of company select?
  organizationHasRound: IOrganizationEntity[];
  localCurrency: string | null;
  fundingLocal: number | null;
  fundingUsd: number | null;
}

export interface IInvestorTypeEntity {
  id: string;
  name: string;
  description: string;
}

export interface IRegionEntity {
  id: string;
  name: string;
}

export interface IClassificationEntity {
  id: string | undefined;
  name: string | undefined;
  description?: string;
  childOf?: IPrimaryClassificationEntity[];
}

export interface IPrimaryClassificationEntity {
  id: string | undefined;
  name: string | undefined;
  description?: string;
  parentOf?: IClassificationEntity[];
}

export interface IBusinessTypeEntity {
  id: string;
  description?: string;
  organizations: IOrganizationEntity[];
  name: string;
}
export interface ITagEntity {
  id: string;
  name: string;
  description: string;
  vertical: string;
  tagType: string;
  organizations: IOrganizationEntity;
}

export interface IOffersTags {
  id: string;
  name: string;
  description: string;
}

export interface IQuantumTechInterest {
  id: string | undefined;
  name: string | undefined;
  description?: string;
  enterpriseUsers?: IEnterpriseUserEntity[];
}

export interface IPartnership {
  id: string;
  entities: IEntity[];
  status: string;
  vertical: string;
  date: string;
  description: string;
  source: string;
  updatedAt: string;
  createdAt: string;
  lastUpdatedBy: string;
}

export interface IEnterpriseUserEntity {
  id: string;
  name: string | null;
  city: string | null;
  inCountry: ICountryEntity[];
  description: string | null;
  email: string[];
  employeeRange: string | null;
  exampleInterest: string | null;
  exampleSources: string[] | null;
  founded: number | null;
  inRegion: IRegionEntity[];
  lat: number | null;
  lng: number | null;
  legalName: string | null;
  linkedIn: string | null;
  logo: string | null;
  phone: string | null;
  interests: IQuantumTechInterest[] | null;
  raised: string | null;
  sector: ISectorEntity[];
  source: string | null;
  website: string | null;
  memberOfConsortiums: IConsortiumEntity[];
  partnerships: IPartnership[];
  status: string | null | ENTITY_STATUSES | undefined;
  interestInVertical: string | null;
  lastUpdatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  __typename?: string;
  entityType: string[];
  keySources?: string[] | null;
  symbol?: string;
}

export interface IStateEntity {
  name: string;
  abbreviation: string;
  lat: number;
  lng: number;
  capital: string;
  country: ICountryEntity[];
}

export interface IOrganizationEntity {
  id: string;
  name: string;
  logo: string | null;
  acquiredBy: IOrganizationEntity[] | null;
  acquisitions: IOrganizationEntity[] | null;
  acquiredByConnection?: {
    edges: {
      node: IOrganizationEntity;
      date: string;
      description: string;
      source: string;
    }[];
  };
  city: string | null;
  state: string | null;
  inCountry: ICountryEntity[];
  description: string | null;
  linkedIn: string | null;
  founded: number | null;
  hasSubscription: ISubscription[];
  fundingRounds?: Partial<IRoundEntity>[];
  fundingRoundsAggregate: IAggregate<{
    fundingUsd: {
      average: string;
      max: string;
      min: string;
      sum: string;
    };
  }>;
  spinoutFrom: IUniversityEntity[];
  inClassification: Array<IClassificationEntity>;
  keySources: string[] | null;
  lastUpdatedBy: string | null;
  lat: number | null;
  lng: number | null;
  legalName: string | null;
  ofType: Partial<IBusinessTypeEntity | IInvestorTypeEntity>[];
  offers: Partial<IOffersTags>[];
  partnerships: IPartnership[];
  raised: number;
  raisedSorting: number | null;
  status: ENTITY_STATUSES | null | string;
  techDetail: string;
  website: string | null;
  employeeRange: string | null;
  employees: IProfile[];
  facilitatesAccessToOrganizations: IOrganizationEntity[];
  organizationFacilitatesAccessTo: IOrganizationEntity[];
  updatedAt: string;
  createdAt: number;
  cloudOffering: string;
  simulatorOffering: string;
  consultingOffering: string;
  cpuOffering: boolean;
  gpuOffering: boolean;
  softwareOffering: string;
  quantumLibrary: string;
  quantumAlgorithms: string;
  quantumCircuit: string;
  assemblyLanguage: string;
  hasQubitImplementations: IClassificationEntity[];
  offersQpu: boolean;
  offersQcaas: boolean;
  color: string | null | undefined;
  hasQubitUpdatesConnection: {
    edges: {
      year: number;
      node: {
        number: number;
      };
    }[];
  };
  hasQubitUpdates: IQubitNumber[];
  __typename?: string;
  entityType: string[];
  scrapedAs: ICompanyEntity[];
  symbol?: string;
  ofBusinessType: IBusinessType[]; // todo: update it
  ofInvestorType: IInvestorTypeEntity[]; // todo: update it
}

export interface IQubitNumber {
  id: string | null;
  number: number | null;
  year: number | string;
  source: string | null;
}

export interface IRegion {
  id: string;
  name: string;
}

export interface IBusinessType {
  id: string;
  organizations?: IOrganizationEntity[];
  type: string;
}

export type VariableType = {
  limit?: number;
  offset?: number;
  sort?: {};
  where?: any;
  search?: string | null;
  fulltext?: string;
  phrase?: string;
  updateType?: string;
  types?: string;
  isAdmin?: boolean;
  isAuth?: boolean;
};

export interface IGovernmentEntity {
  id?: string;
  name: string | null;
  city: string | null;
  inCountry: ICountryEntity[];
  description: string | null;
  email: string[];
  employeeRange: string | null;
  founded: number | null;
  lastUpdatedBy: string | null;
  inRegion: IRegion[];
  lat: number | null;
  lng: number | null;
  legalName: string | null;
  linkedIn: string | null;
  logo: string | null;
  parent: string | null;
  partnerships: IPartnership[];
  phone: string[];
  projects: IProjectEntity[]; //TODO
  raised: number;
  type: string | null;
  website: string | null;
  createdAt?: string | null;
  status: ENTITY_STATUSES | string;
  updatedAt: string;
  __typename?: string;
}

export interface IRoundEntity {
  id: string;
  name: string;
  status: string;
  source: string;
  startDate: string;
  fxRate: string;
  investors: IInvestorEntity[];
  organizationHasRound: IOrganizationEntity[];
  type: string;
  fundingLocal: number;
  lastUpdatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  fundingUsd: number;
  entityType: string[];
}

export interface ILatestUpdateEntity {
  city?: string;
  inCountry: ICountryEntity[];
  id: string;
  name: string;
  typename: string;
  updateType: string;
  updatedAt: string;
  lastUpdatedBy: string;
  logo?: string | null;
  __typename: string;
  entityType: string;
}

export interface IUniversityEntity {
  logo?: string | null | undefined;
  city: string | null;
  inCountry: ICountryEntity[];
  description: string | null;
  employeeRange: string | null;
  founded: number | null;
  id: string | null;
  inRegion: IRegionEntity[];
  lat: number | null;
  lng: number | null;
  leadOfProjects: IProjectEntity[];
  linkedIn: string | null;
  memberOfConsortiums: IConsortiumEntity[];
  name: string | null;
  spinouts: IOrganizationEntity[];
  raised: number | null;
  status: string | null;
  website: string | null;
  lastUpdatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  partnerships: IPartnership[];
  __typename?: string;
  parentOf: ICenterEntity[];
}

export interface IInvestorEntity {
  id: string;
  name: string;
  status: string | null;
  centerAndPartners: ICenterEntity[];
  city: string | null;
  inCountry: ICountryEntity[];
  createdAt: number | null;
  description: string | null;
  employeeRange: string | null;
  founded: number | null;
  investedIn: IFundingRoundEntity[];
  lastUpdatedBy: string | null;
  lat: number | null;
  lng: number | null;
  legalName: string | null;
  linkedin: string | null;
  logo: string | null | undefined;
  mandate: string | null;
  memberOfConsortiums: IConsortiumEntity[]; //IMemberofConsortiumsEntity
  partnerships: IPartnership[];
  ofType: IInvestorTypeEntity[];
  raised: number | null;
  stage: string | null;
  website: string | null;
  portfolio: IOrganizationEntity[];
  enterpriseUserParent: IEnterpriseUserEntity[];
  investors: IInvestorEntity[];
  updatedAt: string;
  __typename?: string;
  entityType: string[];
  investedInConnection?: {
    edges: [
      {
        isLead: number;
        node: {
          id: string;
        };
      }
    ];
  };
}

export interface IConnect<TNode = { id: string | null }> {
  connect: {
    where?: { node: TNode } | {};
  };
}

export interface IPartnerConnect<TNode = { id: string | null }> {
  connect: {
    Organization?: {
      where?: { node: TNode } | {};
    };
    University?: {
      where?: { node: TNode } | {};
    };
    Investor?: {
      where?: { node: TNode } | {};
    };
    GovernmentEntity?: {
      where?: { node: TNode } | {};
    };
    EnterpriseUser?: {
      where?: { node: TNode } | {};
    };
    Center?: {
      where?: { node: TNode } | {};
    };
  };
}

export class SelectOption {
  value: string = "";
  label: string = "";
  optionalData?: any = {};
  logo?: string;
  constructor(
    value: string,
    label: string,
    optionalData: any = null,
    logo?: string
  ) {
    this.label = label;
    this.value = value;
    this.optionalData = optionalData;
    this.logo = logo;
  }
}

export interface IInvestorRoundEntity {
  id: string;
  fundingUsd: number | null | string;
  createdAt: number;
  date: string;
  fxRate: string;
  investors: IInvestorEntity[];
  organization: IOrganizationEntity[];
  type: string;
}

export interface IFocusAreaEntity {
  id: string;
  name: string;
  centers: ICenterEntity[];
  createdAt: number;
  status: string;
  updatedAt: number;
}
export interface ICenterEntity {
  id?: string;
  name: string | null;
  city: string | null;
  inCountry: ICountryEntity[];
  lat: number | null;
  lng: number | null;
  description: string | null;
  focusAreas: IFocusAreaEntity[];
  founded: number | null;
  funding: number | null;
  fundingSrc: string[];
  inRegion: IRegionEntity[];
  logo: string | null;
  memberOfConsortiums: IConsortiumEntity[];
  parent: string | null;
  partnerships: IPartnership[];
  status: ENTITY_STATUSES | null | string;
  childOf: IUniversityEntity[];
  website: string | null;
  lastUpdatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  __typename?: string;
  keySources?: string[] | null;
}
export interface ICountryEntity {
  id: string;
  name: string;
  region: IRegion[];
  fundingRounds: IGovernmentFundingRoundEntity[];
  commentary: string;
  lat: number | null;
  lng: number | null;
  fundingUsdTotal: number;
  fundingLocalTotal: number;
  nationalStrategy: string;
  source: ISource[] | null;
  entities: Array<
    (
      | IUniversityEntity
      | IOrganizationEntity
      | IEnterpriseUserEntity
      | IGovernmentEntity
      | ICenterEntity
      | IInvestorEntity
    ) & { __typename: string }
  >;
  states: IStateEntity[] | null;
}
export interface IGovernmentFundingRoundEntity {
  id: string;
  createdAt: string;
  fundingUsd: number;
  fundingLocal: number;
  fxRate: number;
  lastUpdatedBy: string | null;
  lat: number | null;
  lng: number | null;
  source: string | null;
  updatedAt: string;
  localCurrency: ISelectOption | null | undefined;
  startDate: Date | null;
  fundingInfo: string | null;
  isActive: boolean | null;
  deliveryPeriod: number | null;
  vertical: string[];
}

export interface ISource {
  id: string;
  name: string;
  url: string;
  status: string;
}

export interface IGlobalSearchEntities {
  universities: {
    score: number;
    entity: Partial<IUniversityEntity>;
  }[];
  governmentEntities: {
    score: number;
    entity: Partial<IGovernmentEntity>;
  }[];
  centers: {
    score: number;
    entity: Partial<ICenterEntity>;
  }[];
  consortiums: {
    score: number;
    entity: Partial<IConsortiumEntity>;
  }[];
  organizations: {
    score: number;
    entity: Partial<IOrganizationEntity>;
  }[];
  investors: {
    score: number;
    entity: Partial<IInvestorEntity>;
  }[];
  enterpriseUsers: {
    score: number;
    entity: Partial<IEnterpriseUserEntity>;
  }[];
}
export interface ISectorEntity {
  id: string;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  enterpriseUsers: IEnterpriseUserEntity[];
}

export interface IGraphingTool {
  roundCount: number;
  month: string;
  classification: string;
  total: number;
}

export interface IAnalysisTool {
  year: number;
  classification: string;
  total: number;
}

export interface INewsFeed {
  status: number;
  numResults: number;
  articles: INewsFeedArticle[];
}

export interface INewsFeedArticle {
  addDate: string;
  articleId: string;
  authorsByLine: string;
  categories: {
    name: string;
  };
  claim: string;
  clusterId: string;
  companies: {
    id: string;
    name: string;
    domains: string[];
    symbols: string[];
  };
  content: string;
  country: string;
  description: string;
  entities: {
    data: string;
    type: string;
    mentions: number;
  };
  imageUrl: string;
  keywords: {
    name: string;
    weight: number;
  };
  labels: {
    name: string;
  };
  language: string;
  links: string[];
  locations: {
    city: string;
    country: string;
  };
  matchedAuthors: {
    id: string;
    name: string;
  };
  medium: string;
  people: {
    name: string;
    wikidataId: string;
  };
  places: {
    city: string;
    coordinates: {
      lat: number;
      lon: number;
    };
    country: string;
    countryCode: string;
    neighbourhood: string;
    osmId: string;
    state: string;
  };
  pubDate: string;
  refreshDate: string;
  reprint: number;
  reprintGroupId: string;
  score: number;
  sentiment: {
    negative: number;
    neutral: number;
    positive: number;
  };
  source: {
    domain: string;
  };
  summary: string;
  title: string;
  topics: {
    name: string;
  };
  translation: string;
  url: string;
  verdict: string;
}

export type IEntity = IOrganizationEntity &
  IInvestorEntity &
  ICenterEntity &
  IUniversityEntity &
  IGovernmentEntity &
  IEnterpriseUserEntity &
  IGovernmentFundingRoundEntity &
  IConsortiumEntity;

export interface IUseCasesEntity {
  id: string;
  name: string | null;
  description: string | null;
  industry: string | null;
  problemDomain: string | null;
  approaches: string[];
  hardware: string[];
  currentStatus: string | null;
  companies: IOrganizationEntity[];
  mainSource: string | null;
  status: ENTITY_STATUSES;
}

export interface IArtifactEntity {
  title: string;
  URL: string;
  date: string;
  description: string;
  cited_by_count: number;
  source: "epo | us_ped | google_scholar_publication";
  author: IResearcherEntity[];
  assignees: ICompanyEntity[];
  inventors: IResearcherEntity[];
  assignors: IResearcherEntity[];
  applicants: ICompanyEntity[];
}

export interface IResearcherEntity {
  r_id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  papers: IArtifactEntity[];
  patents: IArtifactEntity[];
}

export interface ICompanyEntity {
  c_id: number;
  appliedForArtifacts: IArtifactEntity[];
  assignedArtifacts: IArtifactEntity[];
  basename: string;
  type: string;
  researchers: IResearcherEntity[];
}

export interface TopResearchersReport {
  first_name: string;
  last_name: string;
  total_citations: number;
}
