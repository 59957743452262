import { useMemo } from "react";
import { ClassificationType } from "types";
import { useClassifications } from "./Classification.queries";

export function useClassificationsData(startRunning = true) {
  const { data, loading } = useClassifications({ canStart: startRunning });

  return useMemo(() => {
    const classificationData: Array<ClassificationType> | undefined =
      data?.primaryClassifications.map((primary) => {
        return {
          id: primary.id,
          name: primary.name,
          description: primary.description,
          secondaryClassifications: data.secondaryClassifications
            .filter((secondary) => {
              if (secondary.childOf && secondary.childOf[0]) {
                return secondary?.childOf[0]?.id === primary.id;
              }
              return false;
            })
            .map((secondary) => ({
              id: secondary.id,
              name: secondary.name,
              description: secondary.description,
            })),
        };
      });

    return { classificationData, loading };
  }, [data?.primaryClassifications, data?.secondaryClassifications, loading]);
}
