import config from "config";

export const USECASES_FIELDS = `
  id
  name
  description
  industry
  problemDomain
  approaches
  hardware
  currentStatus
  companies (where: {vertical_INCLUDES: ${config.VERTICAL}}) {
    id
    name
    logo
    entityType
  }
  mainSource
`;
