import { Circles } from "react-loader-spinner";
import { usePatentsStats } from "./PatentsStats.hooks";
import { PatentsStatsChartBar } from "./PatentsStatsChartBar";
// import { PatentsStatsLineBar } from "./PatentsStatsChartLine";
import { PatentsStatsChartBarStacked } from "./PatentsStatsChartBarStacked";
import { ChartDataset } from "chart.js";
import { PatentsStatsCounts } from "./PatentsStatsCounts";
import { PatentsStatsPanel } from "./PatentsStatsPanel";
import { usePatentFilters } from "../Patents.hooks";
import { transformQueryObject } from "../helpers/Patents.utils";
// import { addYears, format } from "date-fns";
import { useLocation } from "react-router";
import { PatentsStatsDoughnut } from "./PatentsStatsDoughnut";
import { addYears, format } from "date-fns";

const PatentsStats = () => {
  const {
    pdParamFrom,
    pdParamEnd,
    organizationQuery,
    inventorsQuery,
    filtersState,
    setFiltersState,
    defaultKeywords,
  } = usePatentFilters({
    customStartDate: format(addYears(new Date(), -10), "yyyyMMdd"),
  });

  const currentKeywords = transformQueryObject(filtersState.entities);

  const {
    companyDatasets,
    countryDatasets,
    countryAndYearDatasets,
    // yearDatasets,
    loading,
    patentsCountOverall,
    organizationsCount,
    deadAliveDatasets,
  } = usePatentsStats({
    currentKeywords,
    pdParamFrom,
    pdParamEnd,
    organizationQuery,
    inventorsQuery,
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const organizationParam = params.get("organization");

  const deadAliveSum = deadAliveDatasets?.dataset[0]?.data?.reduce(
    (acc, curr) => acc + curr,
    0
  );

  return (
    <div className="flex flex-col w-full bg-primary">
      <div className="my-2 mx-4">
        <PatentsStatsPanel
          filtersState={filtersState}
          setFiltersState={setFiltersState}
          handleSubmitData={() => {}}
          defaultKeywords={defaultKeywords}
          patentsLoading={loading}
        />
        {loading ? (
          <div className="flex flex-col items-left pl-2 pt-[2px] h-fit">
            <Circles color="#00649C" height={22} width={22} />
          </div>
        ) : (
          <div className="flex flex-row gap-6 flex-wrap my-2 max-w-[800px] lg:max-w-[1200px]">
            {companyDatasets?.labels?.length > 0 && !organizationParam && (
              <PatentsStatsChartBar
                barLabels={companyDatasets.labels}
                barDatasets={companyDatasets.dataset}
                title="TOP 10 Organizations"
              />
            )}
            {countryDatasets?.labels?.length > 0 && (
              <PatentsStatsChartBar
                barLabels={countryDatasets.labels}
                barDatasets={countryDatasets.dataset}
                title="TOP 10 Countries"
              />
            )}
            {countryAndYearDatasets?.labels?.length > 0 && (
              <PatentsStatsChartBarStacked
                barLabels={countryAndYearDatasets.labels as string[]}
                barDatasets={
                  countryAndYearDatasets.datasets as ChartDataset<
                    "bar",
                    number[]
                  >[]
                }
                title="Patent Publications by Country and Year"
              />
            )}
            {deadAliveDatasets?.dataset[0]?.data?.length > 0 &&
              deadAliveSum > 0 && (
                <PatentsStatsDoughnut
                  barLabels={deadAliveDatasets?.labels}
                  barDatasets={deadAliveDatasets?.dataset}
                />
              )}
            {(countryAndYearDatasets?.labels?.length > 0 ||
              organizationsCount) && (
              <PatentsStatsCounts
                patentsCount={patentsCountOverall}
                orgsCount={organizationsCount}
                lastYear={countryAndYearDatasets?.labels.slice(-1)[0] as string}
              />
            )}
            {/* {yearDatasets?.labels?.length > 0 && (
              <PatentsStatsLineBar
                barLabels={yearDatasets.labels}
                barDatasets={yearDatasets.dataset}
              />
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatentsStats;
