import React, { memo, useState } from "react";
import CrownWhiteIcon from "assets/images/crown-white-icon.svg";
import { Button } from "components/Button";
import classnames from "classnames";
import { useOutletContext } from "react-router";
import { IOutletAuth } from "types";
import { HubspotForm } from "components/HubspotForm";
import { handleHubspotClick } from "components/HubspotForm/HubspotForm";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

function PremiumButton({ className }: IProps) {
  const authContext = useOutletContext<IOutletAuth>();
  const isAuth = authContext.isAuth;
  const classes = classnames(
    { "bg-[#28C18A] hover:bg-[#28C18A]": !isAuth },
    className,
    "w-[160px] h-[38px] py-[12px] justify-center font-bold rounded-[4px] text-[12px] items-center text-center whitespace-no-wrap align-middle select-none text-base leading-normal text-[#FFFFFF] bg-[#28C18A] hover:text-white hover:bg-[#31414E] hover:border-primary align-self-center opacity-100"
  );
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        customPaddings
        className={classes}
        onClick={function (): void {
          return handleHubspotClick(setOpen);
        }}
      >
        <img src={CrownWhiteIcon} alt="CrownWhiteIcon" className="mr-2" />
        Premium Access
      </Button>{" "}
      <HubspotForm key="PremiumButton" open={open} setOpen={setOpen} />
    </>
  );
}
export default memo(PremiumButton);
