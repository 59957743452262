import {
  default as ReactSelect,
  GroupBase,
  MenuListProps,
  Props,
} from "react-select";
import { ISelectOption } from "types";
import { components, OptionProps } from "react-select";
import classnames from "classnames";
import { Label } from "components/Label";
import { CustomCheckbox } from "components/CustonCheckbox";
import { getStylesForReactSelectInputs } from "utils";
import CreatableSelect from "react-select/creatable";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { useDebouncedHandler } from "hooks/useDebounce";

function Option(props: OptionProps<any>) {
  return (
    <div className="mt-2">
      <components.Option {...props}>
        <CustomCheckbox isSelected={props.isSelected} />
        <label className="ml-4">{props.label}</label>
      </components.Option>
    </div>
  );
}

function MenuListWrapper(props: MenuListProps<any>) {
  return (
    <div className="">
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
}

export interface IAdvanceFieldProps extends Props<ISelectOption> {
  label?: string;
  message?: string;
  className?: string;
  classNames?: {
    input?: string; // TODO
    message?: string;
    label?: string;
  };
  require?: boolean;
  required?: boolean;
  isCreatable?: boolean;
  disableOption?: boolean;
  bottomMenuListComponent?: React.ReactNode;
  components?:
    | Partial<
        SelectComponents<ISelectOption, boolean, GroupBase<ISelectOption>>
      >
    | undefined;

  isSearchable?: boolean;
  makeSearch?: (value: string) => void;
  isLoading?: boolean;
  debounceTime?: number;
  formatCreateLabel?: (inputValue: any) => string;
}

function getMessage(
  message: string | string[] | any[] | null | number | undefined | object
) {
  if (Array.isArray(message)) {
    return message
      .map((item) => {
        if (typeof item === "string") {
          return item;
        }
        if (typeof item === "object") {
          if (item.value) {
            return item.value;
          }
          return JSON.stringify(item);
        }
        return item;
      })
      .join(", ");
  }
  if (typeof message === "object") {
    return JSON.stringify(message);
  }
  return message;
}

export function AdvanceSelect({
  message,
  className,
  classNames,
  label,
  require,
  required,
  bottomMenuListComponent,
  isCreatable,
  disableOption = false,
  components,
  debounceTime = 600,
  isSearchable = false,
  makeSearch,
  closeMenuOnSelect = false,
  ...rest
}: IAdvanceFieldProps) {
  const preparedMessage = getMessage(message);

  const Select = isCreatable ? CreatableSelect : ReactSelect;
  const MenuList = bottomMenuListComponent
    ? (props: MenuListProps) =>
        MenuListWrapper({
          ...props,
          children: (
            <>
              {props.children}
              {bottomMenuListComponent}
            </>
          ),
        })
    : MenuListWrapper;

  const buildedComponents = {
    //@ts-ignore
    MenuList,
    ...components,
  };

  if (!disableOption) {
    buildedComponents.Option = Option;
  }

  rest.onInputChange = useDebouncedHandler((value: string) => {
    if (isSearchable) {
      makeSearch && makeSearch(value);
    }
  }, debounceTime);

  return (
    <div
      className={classnames("flex text-[12px] flex-col", className, {
        "pb-4": !preparedMessage,
      })}
    >
      {label && (
        <Label
          name={label}
          className={classNames?.label || "text-primary"}
          required={require || required}
        />
      )}
      <Select
        menuPlacement="auto"
        styles={getStylesForReactSelectInputs({
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#11ffee00", // full opacity
          }),
          option: () => ({
            display: "flex",
            alignItems: "center",
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 30,
          }),
          control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            paddingRight: "5px",
            borderColor: "#E6EFF5",
            ":hover": {
              borderColor: "#00649c",
            },
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#98A0A6",
            fontSize: "12px",
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: "8px",
            paddingBottom: "12px",
            paddingLeft: "24px",
            paddingRight: "24px",
          }),
          ...rest?.styles,
        })}
        className="darkmode-input"
        classNamePrefix="react-select"
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        //@ts-ignore
        components={buildedComponents}
        {...rest}
      />
      <div className={classnames("text-xs", classNames?.message)}>
        {preparedMessage}
      </div>
    </div>
  );
}
