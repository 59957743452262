import { useCallback, useState } from "react";

export function useToggleModal() {
  const [isModalShowing, setIsModalShowing] = useState(false);

  const toggleModal = useCallback(() => {
    return setIsModalShowing((state) => !state);
  }, [setIsModalShowing]);
  return { isModalShowing, toggleModal };
}
