import config from "config";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { ISectorEntity } from "types";
import { useEffect } from "react";

const GET_TAGS = gql`
  query {
    sectors: tags(
      where: { tagType_INCLUDES: SECTOR, vertical_INCLUDES: ${config.VERTICAL} }
    ) {
      id
      name
      description
    }
  }
`;

export function useSectors({ startRunning = true } = {}) {
  const [runQuery, { loading, error, data }] = useLazyQuery<{
    sectors: Array<ISectorEntity>;
  }>(GET_TAGS);

  useEffect(() => {
    if (startRunning) runQuery().then();
  }, [startRunning]);

  return { loading, error, data };
}
