import classnames from "classnames";
import { memo } from "react";

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  className?: string;
  classNames?: {
    title?: string;
    titleText?: string;
    childrenWrapper?: string;
    titleContainer?: string;
    headerChildren?: string;
  };
  headerChildren?: React.ReactNode;
  titleChildren?: React.ReactNode;
  beforeTitle?: React.ReactNode;
}

function Card({
  className = "flex flex-col rounded  shadow-lg max-w-[100%]",
  classNames,
  children,
  title,
  headerChildren,
  titleChildren,
  beforeTitle,
  ...rest
}: ICardProps) {
  return (
    <div className={classnames(className)} {...rest}>
      <div
        className={classnames(classNames?.title, {
          "flex justify-between py-[10px] gap-[40px]": !!headerChildren,
        })}
      >
        <div
          className={classnames(
            classNames?.titleContainer,
            "flex",
            "title-container"
          )}
        >
          <div className="flex items-center self-center justify-center">
            <div
              className={classnames(
                classNames?.titleText,
                "text-[16px] text-primary font-medium",
                "flex flex-row items-center gap-2"
              )}
            >
              {beforeTitle}
              {title}
            </div>
          </div>
          <div>{titleChildren}</div>
        </div>
        <div
          className={classnames(classNames?.headerChildren, "header-children")}
        >
          {headerChildren}
        </div>
      </div>
      <div className={classnames("", classNames?.childrenWrapper)}>
        {children}
      </div>
    </div>
  );
}

export default memo(Card);
