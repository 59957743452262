import { useCallback, useMemo, useState } from "react";

import { IFilterType } from "types";
import { ISort } from "./Filter.types";
import { sortData } from "./Filter.utils";

const employeeRange = [
  {
    id: 1,
    resultsCont: 1,
    tag: "1-10",
  },
  {
    id: 2,
    resultsCont: 1,
    tag: "11-50",
  },
  {
    id: 3,
    resultsCont: 1,
    tag: "51-100",
  },
  {
    id: 4,
    resultsCont: 1,
    tag: "101-500",
  },
  {
    id: 5,
    resultsCont: 1,
    tag: "501-1000",
  },
  {
    id: 6,
    resultsCont: 1,
    tag: "1001-5000",
  },
  {
    id: 7,
    resultsCont: 1,
    tag: "5001-10000",
  },
  {
    id: 8,
    resultsCont: 1,
    tag: "10001+",
  },
];

export function useSortAndFilters(data: IFilterType[]) {
  const [query, setQuery] = useState("");
  const [sortType, setType] = useState<ISort>({});
  const onSearchHandler = useCallback((e: any) => {
    setQuery(e.target.value);
  }, []);

  const transformedData = useMemo(() => {
    const newData = [...data];
    return newData
      .filter((item) => {
        if (item.tag) {
          return item.tag.toLowerCase().includes(query.toLowerCase());
        } else {
          return null;
        }
      })
      .sort(sortData(sortType));
  }, [data, query, sortType]);

  const employeeRangeData = useMemo(() => {
    const newData = employeeRange;
    return newData
      .filter((item) => {
        if (item.tag) {
          return item.tag.toLowerCase().includes(query.toLowerCase());
        } else {
          return null;
        }
      })
      .sort(sortData(sortType));
  }, [query, sortType]);

  const dateTimeData = useMemo(() => {
    const newData = [...data];

    return newData;
  }, [data]);

  const onChangeTitleSortHandler = useCallback(() => {
    if (sortType["title"] && sortType["title"] === "descending") {
      return setType({});
    }
    if (sortType["title"] && sortType["title"] === "ascending") {
      return setType({ title: "descending" });
    }
    return setType({ title: "ascending" });
  }, [sortType]);

  const onChangeCountSortHandler = useCallback(() => {
    if (sortType["count"] && sortType["count"] === "descending") {
      return setType({});
    }
    if (sortType["count"] && sortType["count"] === "ascending") {
      return setType({ count: "descending" });
    }
    return setType({ count: "ascending" });
  }, [sortType]);

  return {
    query,
    setQuery,
    transformedData,
    employeeRangeData,
    dateTimeData,
    onSearchHandler,
    onChangeTitleSortHandler,
    onChangeCountSortHandler,
  };
}
