import { gql, useQuery } from "@apollo/client";
import { ISubscription } from "types";
import { SUBSCRIPTION_FIELDS } from "./Subscriptions.constants";
import config from "config";

const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions(
    $where: SubscriptionInstanceWhere
    $sort: [SubscriptionInstanceSort!]
    $limit: Int
    $offset: Int
    $phrase: String!
  ) {
    subscriptionInstancesAggregate (
      where: $where, 
       fulltext: { SubscriptionInstanceFullText: { phrase: $phrase } }
    ) {
      count
    }
    subscriptionInstances (
      options: {
        limit: $limit,
        offset: $offset,
         sort: $sort
      },
      where: $where,
      fulltext: { SubscriptionInstanceFullText: { phrase: $phrase } }
    ) {
      ${SUBSCRIPTION_FIELDS}
    }
  }
`;

type SubscriptionsVarsType = {
  limit?: number;
  offset?: number;
  sort?: {};
  where?: { id?: string };
  search?: string | null;
};

export function useGraphQLSubscriptions({
  limit,
  offset,
  sort,
  where,
  search,
}) {
  const { loading, error, data } = useQuery<{
    subscriptionInstances: ISubscription[];
    subscriptionInstancesAggregate: { count: number };
  }>(GET_SUBSCRIPTIONS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        vertical_INCLUDES: config.VERTICAL,
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
    },
    context: { api: "userProfile" },
  });

  return { loading, error, data };
}
