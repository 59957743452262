import { useState } from "react";
import PatentFilterPopup from "./PatentFilterPopup";
import engineFilterIcon from "assets/images/table-filters-icon.svg";
import { IconButton } from "@mui/material";
import { PatentFilterRestricted } from "./PatentFilterRestricted";

const PatentsFilter = ({
  handleSubmitData,
  showSort,
  filtersState,
  setFiltersState,
  defaultKeywords,
  customStartDate,
}: {
  handleSubmitData: () => void;
  showSort: boolean;
  filtersState: {
    entities: {
      value: string;
      label: string;
    }[];
    keywords: string;
  };
  setFiltersState: React.Dispatch<
    React.SetStateAction<{
      entities: {
        value: string;
        label: string;
      }[];
      keywords: string;
    }>
  >;
  defaultKeywords: {
    value: string;
    label: string;
  }[];
  customStartDate?: Date;
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => {
          setIsFilterOpen(true);
        }}
        className="h-[38px] hidden md:flex flex-row !rounded !bg-tablePrimary !ml-0 !mr-0 !px-4 gap-1 items-center !justify-start hover:opacity-80"
      >
        <img
          className="engineFilterIcon w-[16px] mr-1"
          src={engineFilterIcon}
          alt="engineFilterIcon"
        />
        <span className="text-primaryBlue text-[12px] font-bold">Filters</span>
      </IconButton>
      <PatentFilterRestricted
        filtersState={filtersState}
        setFiltersState={setFiltersState}
        defaultKeywords={defaultKeywords}
      />
      <PatentFilterPopup
        openFilters={isFilterOpen}
        onApplyFilters={handleSubmitData}
        onClose={() => {
          setIsFilterOpen(false);
        }}
        showDateFilter={true}
        showSort={showSort}
        showOrganizationFilter={true}
        showInventorFilter={true}
        setFiltersState={setFiltersState}
        defaultKeywords={defaultKeywords}
        customStartDate={customStartDate}
      />
    </>
  );
};

export default PatentsFilter;
