import { PatentDocs } from "../Patents.types";

export const parsePatentJsonData = (patent: PatentDocs) => {
  const parsedTitles: string =
    !!patent?.titles_mt && patent?.titles_mt.length > 0
      ? JSON.parse(patent?.titles_mt)[0]
          ?.match(/>(.*)</)?.[1]
          ?.replaceAll(/<[^>]*>?/gm, "")
      : !!patent?.titles && patent?.titles.length > 0
      ? JSON.parse(patent?.titles)[0]
          ?.match(/>(.*)</)?.[1]
          ?.replaceAll(/<[^>]*>?/gm, "")
      : "-";
  const parsedAbstracts: string | null =
    !!patent?.abstracts_mt && patent?.abstracts_mt.length > 0
      ? JSON.parse(patent?.abstracts_mt)
          ?.map((abstracts_mt: string | null) =>
            abstracts_mt?.match(/>(.*)</)?.[1]?.replaceAll(/<[^>]*>?/gm, "")
          )
          .join(", ")
      : !!patent?.abstracts && patent?.abstracts.length > 0
      ? JSON.parse(patent?.abstracts)
          ?.map((abstract: string | null) =>
            abstract?.match(/>(.*)</)?.[1]?.replaceAll(/<[^>]*>?/gm, "")
          )
          .join(", ")
      : null;
  const year = patent.publicationdate?.slice(0, 4);
  const month = patent.publicationdate?.slice(4, 6);
  const day = patent.publicationdate?.slice(6, 8);
  const parsedPublicationDate: string = !!patent.publicationdate
    ? `${day}/${month}/${year}`
    : "-";
  const parsedInventors: string =
    JSON.parse(patent?.inventors)
      ?.map((inventor) => inventor.name)
      .join(", ") || "-";
  const parsedRelatedPatentsList: string =
    JSON.parse(patent?.familydata)
      ?.["simple-family-data"]?.publications?.filter(
        (pub) => pub?.ucid !== patent?.id
      )
      ?.map((publication) => publication.ucid)
      .join(", ") || "-";
  const parsedRelatedPatents: string[] =
    JSON.parse(patent?.familydata)
      ?.["simple-family-data"]?.publications?.filter(
        (pub) => pub?.ucid !== patent?.id
      )
      ?.map((publication) => publication?.ucid) || [];
  const parsedStatus: string = patent?.patentstatuses
    ? Object.entries(JSON.parse(patent?.patentstatuses))
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ")
    : "-";
  const parsedExpectedExpiry: {
    "expiry-date": string;
    explanation: string;
    "explanation-link": string;
  } = patent?.expectedexpiryexplanation
    ? JSON.parse(patent?.expectedexpiryexplanation)
    : "-";
  const parsedExpectedExpiryDate: string = parsedExpectedExpiry["expiry-date"]
    ? `${parsedExpectedExpiry["expiry-date"]?.slice(
        6,
        8
      )}/${parsedExpectedExpiry["expiry-date"]?.slice(
        4,
        6
      )}/${parsedExpectedExpiry["expiry-date"]?.slice(0, 4)}`
    : "-";

  const parsedUltimateOwner: string[] = Array.isArray(
    JSON.parse(patent?.ultimate_owner)
  )
    ? JSON.parse(patent?.ultimate_owner)
    : [];

  const parsedApplicationDate: string = !!patent.applicationdate
    ? `${patent.applicationdate.slice(6, 8)}/${patent.applicationdate.slice(
        4,
        6
      )}/${patent.applicationdate.slice(0, 4)}`
    : "-";

  return {
    parsedTitles,
    parsedAbstracts,
    parsedPublicationDate,
    parsedInventors,
    parsedRelatedPatentsList,
    parsedRelatedPatents,
    parsedStatus,
    parsedExpectedExpiry,
    parsedExpectedExpiryDate,
    parsedUltimateOwner,
    parsedApplicationDate,
  };
};

export const transformQueryString = (str: string) => {
  const tokens: string[] = [];
  let currentToken = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "(" || char === ")") {
      if (currentToken) {
        tokens.push(currentToken);
        currentToken = "";
      }
      tokens.push(char);
    } else if (char === '"' || char === "'") {
      if (currentToken) {
        tokens.push(currentToken);
        currentToken = "";
      }
      const closingQuoteIndex = str.indexOf(char, i + 1);
      if (closingQuoteIndex !== -1) {
        tokens.push(str.slice(i, closingQuoteIndex + 1));
        i = closingQuoteIndex;
      }
    } else if (char === " " && !currentToken) {
      continue;
    } else if (char === " ") {
      tokens.push(currentToken);
      currentToken = "";
    } else {
      currentToken += char;
    }
  }

  if (currentToken) {
    tokens.push(currentToken);
  }

  const objects = tokens.map((token) => ({ value: token, label: token }));

  return objects;
};

export const transformQueryObject = (
  arr: {
    value: string;
    label: string;
  }[]
) => {
  return arr
    ?.map((item) => {
      return item["value"];
    })
    .join(" ");
};
