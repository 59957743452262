import { Modal } from "components/Modal";
import { FilterTab } from "./FilterTab";
import { memo } from "react";
import { useNavigate } from "react-router";

import { IFilterData, IFilteredTags, ISearchTabArray } from "types";
import { FilterUpdateFunc } from "./Filter.types";

interface IProps {
  filterData: IFilterData[];
  isShowing: boolean;
  onHide: () => void;
  checkedTags: IFilteredTags;
  childrenTabs: ISearchTabArray;
  onCheckTagToggle: FilterUpdateFunc;
}

function Filter({
  filterData,
  isShowing,
  onHide,
  childrenTabs,
  checkedTags,
  onCheckTagToggle,
}: IProps) {
  const navigate = useNavigate();
  const onClearTags = () => {
    navigate({ search: "" });
  };

  return (
    <Modal
      isShowing={isShowing}
      onHide={onHide}
      showHeader={false}
      showSearchSidebar={true}
      customMaxWidth
      classNames={{
        popup: "w-full max-w-[1046px] pb-0  h-[568px]",
        popupContainer: "overflow-y-hidden",
        modalHeader: "justify-between",
        popupInner: " h-full  px-0 mt-0",
        childrenContainer: " w-full h-full",
      }}
      title="Graphing Tool"
    >
      <div className="`grid grid-cols-1 auto-rows-min gap-2`">
        <FilterTab
          onClearTags={onClearTags}
          onHide={onHide}
          childrenTabs={childrenTabs}
          checkedTags={checkedTags}
          onCheckTagToggle={onCheckTagToggle}
          childrenPanels={filterData}
        />
      </div>
    </Modal>
  );
}

export default memo(Filter);
