import { useCallback, useMemo, useState } from "react";

import { IOutletAuth } from "types";
import { getRootRoleAccessLevel } from "utils";
import { useLocation, useNavigate } from "react-router";
import { useSortByColumn } from "hooks/useSortByColumn";
import { ColumnDef } from "@tanstack/react-table";

interface IProps<D extends object> {
  columns: ColumnDef<D>[];
  roles?: IOutletAuth["roles"] | undefined;
}
export function useColumnStructure<D extends object>({
  columns,
  roles,
}: IProps<D>) {
  return useMemo(() => {
    const rootRole = getRootRoleAccessLevel(roles);
    return columns.filter((item: any) => {
      if (typeof item?.role === "string") {
        return getRootRoleAccessLevel([item?.role]) <= rootRole;
      }
      return true;
    });
  }, [columns, roles]);
}

export function useCompanyColumnVisibilityModal() {
  const [isColumnVisibilityShowing, setIsModalShowing] = useState(false);

  const toggleColumnVisibilityModal = useCallback(() => {
    return setIsModalShowing((state) => !state);
  }, [setIsModalShowing]);
  return { isColumnVisibilityShowing, toggleColumnVisibilityModal };
}

export function useSorting() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { sortCol, sortDesc } = useSortByColumn();

  const [sorting, setSorting] = useState([
    {
      id: sortCol,
      desc: sortDesc,
    },
  ]);

  const onSortingChange = (sortingUpdater) => {
    const newSortVal = sortingUpdater(sorting);

    const sortDir = newSortVal?.[0]?.desc ? "-" : "";
    const sortBy = sortDir + newSortVal[0].id;

    setSorting(newSortVal);

    params.set("sort", sortBy);

    navigate({
      search: params.toString(),
    });
  };

  return {
    sorting,
    onSortingChange,
  };
}
