import config from "config";

export const ORGANIZATION_FIELDS = `
id
acquiredBy @include(if: $isAuth) {
  id
  name
  logo
}
acquisitions(where: {vertical_INCLUDES:${config.VERTICAL}}) @include(if: $isAuth) {
  vertical
  id
  name
  logo
}
acquiredByConnection @include(if: $isAuth) {
  edges {
    node {
      name
    }
    date
    description
    source
  }
}
city
state
inCountry {
  name
  region {
    id
    name
  }
  states {
    name
    abbreviation
  }
}
createdAt
description
employeeRange
founded
${config.KEY_SOURCES_FIELD}
lat
linkedIn @include(if: $isAuth)
lng
logo
name
raised @include(if: $isAuth)
${config.RAISED_SORTING_FIELD} @include(if: $isAuth)
${config.COMPANY_TECH_DETAIL_FIELD} @include(if: $isAuth)
website @include(if: $isAuth)
cloudOffering @include(if: $isAuth)
simulatorOffering @include(if: $isAuth)
consultingOffering @include(if: $isAuth)
cpuOffering
gpuOffering
offersQcaas
offersQpu
softwareOffering @include(if: $isAuth)
quantumLibrary @include(if: $isAuth)
quantumAlgorithms @include(if: $isAuth)
quantumCircuit @include(if: $isAuth)
assemblyLanguage @include(if: $isAuth)
memberOfConsortiums @include(if: $isAuth) {
  id
  name
}
hasQubitImplementations @include(if: $isAuth) {
  id
  name
}
organizationFacilitatesAccessTo {
  id
  name
}
inClassification(where: {vertical_INCLUDES:${config.VERTICAL}}) @include(if: $isAuth) {
  id
  name
  description
  childOf {
    id
    name
    description
  }
}
facilitatesAccessToOrganizations {
  id
  name
}
partnerships(where: {vertical_INCLUDES: ${config.VERTICAL}}) @include(if: $isAuth) {
  id
  date
  description
  source
  updatedAt
  createdAt
  status
  vertical
  entities(where: {vertical_INCLUDES: ${config.VERTICAL}}) {  
    id
    name
    logo
    entityType
    vertical
  }
}
spinoutFrom @include(if: $isAuth) {
  id
  name
  logo
}
lastUpdatedBy @include(if: $isAdmin)
updatedAt
ofType @include(if: $isAuth) {
  id
  name
  description
}
offers @include(if: $isAuth) {
  id
  name
  description
}
lastUpdatedBy @include(if: $isAdmin)
fundingRounds (
  options: { sort: { startDate: DESC } }
  where: $fundingRoundsWhere
) {
  investors {
    id
    name
  }
  id
  type
  startDate
  source
  fundingUsd
  status
}
fundingRoundsAggregate(where: $fundingRoundsWhere) {
  node {
    fundingUsd {
      sum
    }
  }
}
vertical
scrapedAs {
  researchers {
    papers {
      title
      description
      date
      source
      cited_by_count
      author {
        first_name
        middle_name
        last_name
      }
    }
  }
  assignedArtifacts {
    title
    description
    URL
    date
    cited_by_count
    source
    assignees {
      basename
    }
    inventors {
      first_name
      middle_name
      last_name
    }
    assignors {
      first_name
      middle_name
      last_name
    }
    applicants {
      basename
      type
    }
  }
}
status
symbol
`;
