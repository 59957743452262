import config from "config";
import { gql, useQuery } from "@apollo/client";
import {
  IBusinessTypeEntity,
  IFocusAreaEntity,
  IInvestorTypeEntity,
  IOffersTags,
  IQuantumTechInterest,
  ISectorEntity,
} from "types";

const GET_TAGS = gql`
  query GetTags {
    offeringTags:tags(where: {vertical_INCLUDES: ${config.VERTICAL}, tagType:OFFERING}, options: {sort: {name:ASC}}) {
      name
      description
    }
    investorTags:tags(where: {vertical_INCLUDES: ${config.VERTICAL}, tagType:INVESTOR_TYPE}, options: {sort: {name:ASC}}) {
      name
      description
    }
    businessTags:tags(where: {vertical_INCLUDES: ${config.VERTICAL}, tagType:BUSINESS_TYPE}, options: {sort: {name:ASC}}) {
      name
      description
    }
    techTags:tags(where: {vertical_INCLUDES: ${config.VERTICAL}, tagType:TECH_INTEREST}, options: {sort: {name:ASC}}) {
      name
      description
    }
    focusTags:tags(where: {vertical_INCLUDES: ${config.VERTICAL}, tagType:FOCUS_AREA}, options: {sort: {name:ASC}}) {
      name
      description
    }
    sectorTags:tags(where: {vertical_INCLUDES: ${config.VERTICAL}, tagType:SECTOR}, options: {sort: {name:ASC}}) {
      name
      description
    }
  }
`;

export function useGraphQLTags() {
  const { loading, error, data } = useQuery<{
    offeringTags: Array<IOffersTags>;
    investorTags: Array<IInvestorTypeEntity>;
    businessTags: Array<IBusinessTypeEntity>;
    techTags: Array<IQuantumTechInterest>;
    focusTags: Array<IFocusAreaEntity>;
    sectorTags: Array<ISectorEntity>;
  }>(GET_TAGS);

  return { loading, error, data };
}
