import { gql, useMutation } from "@apollo/client";
import classNames from "classnames";
import Modal from "components/Modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "const";
import { renderDate, renderText } from "utils";

type columnsAdminDashboardProps = {
  allIds: string[];
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  tableType: "Entities" | "Rounds";
};

const BULK_APPROVE = gql`
  mutation BulkApprove($ids: [String!]!) {
    bulkApprove(ids: $ids)
  }
`;

export const useColumnsAdminDashboard = ({
  allIds,
  selectedIds,
  setSelectedIds,
  tableType,
}: columnsAdminDashboardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [isApproving, setIsApproving] = useState(false);
  const [error, setError] = useState("");

  const handleCheckAll = () => {
    const selectAll = allIds.length === selectedIds.length ? [] : allIds;
    setSelectedIds(selectAll);
  };

  const [bulkApprove] = useMutation(BULK_APPROVE);

  const handleApproveClick = () => {
    if (selectedIds.length > 0) {
      setIsModalOpen(true);
      setIsApproving(true);
      bulkApprove({
        variables: { ids: selectedIds },
      })
        .then((result) => {
          setApprovedCount(result.data.bulkApprove);
          setIsApproving(false);
          setSelectedIds([]);
        })
        .catch((err) => {
          setError(err.message);
          setIsApproving(false);
        });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  return [
    {
      header: () => (
        <div className="flex flex-row gap-2 w-full">
          <div
            className="flex approve-area px-4"
            onClick={(e) => {
              e.stopPropagation();
              handleCheckAll();
            }}
          >
            <input
              type="checkbox"
              className="hover:cursor-pointer"
              checked={allIds.length === selectedIds.length}
            />
          </div>
          {selectedIds.length > 0 && (
            <div
              className="font-bold bg-primaryBlue text-white px-2 rounded hover:bg-[#3383B0]"
              onClick={handleApproveClick}
            >
              {isApproving ? "Approving..." : "Approve"}
            </div>
          )}
          <Modal
            isShowing={isModalOpen}
            onHide={handleCloseModal}
            title={(isApproving ? "Approving " : "Approved ") + tableType}
            classNames={{
              borderLine: "hidden",
              modalHeader: "justify-between items-center my-2 text-primary",
              childrenContainer: "text-primary !px-4 pb-4 text-[14px]",
              popup: "max-w-[300px] !p-0",
              popupInner: "!p-0 !m-0 rounded",
              closeButton: "flex",
            }}
            className={classNames({
              "pointer-events-none hover:cursor-not-allowed": isApproving,
            })}
          >
            {!error && (
              <p>
                {isApproving
                  ? "Loading..."
                  : `Successfully approved: ${approvedCount} item(s)`}
              </p>
            )}
            {error && <p className="text-[#EB4E4E] text-[12px]">{error}</p>}
            <button
              className={classNames(
                "h-[28px] w-full mt-2 px-4 hover:bg-primaryBlue hover:text-white hover:border-primaryBlue border border-primary bg-tablePrimary text-primaryBlue items-center justify-center rounded flex text-[12px]",
                { "hover:cursor-not-allowed pointer-events-none": isApproving }
              )}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </Modal>
        </div>
      ),
      accessorKey: "id",
      name: "approve",
      label: "Approve",
      enableSorting: false,
      show: true,
      style: {
        width: 200,
      },
      cell: (cell) => {
        return (
          <div className="flex justify-start text-left align-middle px-4">
            <input
              type="checkbox"
              checked={selectedIds.includes(cell.getValue() || "")}
              onChange={() => handleCheckboxChange(cell.getValue() || "")}
            />
          </div>
        );
      },
    },
    {
      header: "Type",
      accessorKey: "type",
      name: "type",
      label: "Type",
      enableSorting: false,
      show: true,
      size: 200,
      cell: (cell) => {
        const entity = cell.row.original;
        const link =
          tableType === "Entities"
            ? APP_ROUTES.ENTITY.replace(":entityId", String(entity?.id))
            : APP_ROUTES.FUNDING_ROUND.replace(
                ":fundingRoundId",
                String(entity?.id)
              );
        const type =
          tableType === "Entities"
            ? entity?.entityType && entity?.entityType.join(", ")
            : "ROUND";
        return (
          <div className="flex justify-start text-left align-middle">
            <Link
              className="text-primaryBlue font-medium text-[12px]"
              to={link}
            >
              {type}
            </Link>
          </div>
        );
      },
    },
    {
      header: "Name",
      accessorKey: "name",
      name: "name",
      label: "Name",
      enableSorting: false,
      show: true,
      size: 350,
      cell: (cell) => {
        const entity = cell.row.original;
        const name =
          tableType === "Entities"
            ? entity?.name
            : entity?.organizationHasRound[0]?.name;
        return name;
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      name: "status",
      label: "Status",
      enableSorting: false,
      show: true,
      size: 200,
      cell: renderText,
    },
    {
      header: "Created at",
      accessorKey: "createdAt",
      name: "created_at",
      label: "Created at",
      enableSorting: false,
      show: true,
      size: 200,
      cell: renderDate,
    },
  ];
};
