import Tab from "components/Tab";
import { APP_ROUTES } from "const";
import { Link } from "react-router-dom";
import { ITableColumn } from "types";
import {
  renderCountry,
  renderEntity,
  renderLogo,
  renderRegion,
  renderRelations,
  renderText,
  useCheckPremium
} from "utils";
import checkTrue from "assets/images/check-blue.svg";
import checkFalse from "assets/images/cross-red-circle.png";

export const columnsQcaas: ITableColumn = [
  {
    header: "",
    accessorKey: "logo",
    id: "logo",
    label: "Logo",
    show: true,
    enableSorting: false,
    size: 60,
    minSize: 60,
    cell: cell => renderLogo(cell, APP_ROUTES.COMPANY_PROFILE),
  },
  {
    header: "Company",
    accessorKey: "name",
    id: "name",
    label: "Company",
    show: true,
    enableSorting: true,
    cell: cell => renderEntity(cell, APP_ROUTES.COMPANY_PROFILE),
  },
  {
    header: "Facilitates access to",
    accessorKey: "facilitatesAccessToOrganizations",
    id: "facilitatesAccessToOrganizations",
    label: "Facilitates access to",
    show: true,
    enableSorting: false,
    minSize: 150,
    size: 285,
    cell: cell => renderRelations(cell, APP_ROUTES.COMPANY_PROFILE),
  },
  {
    header: "Cloud offering",
    accessorKey: "cloudOffering",
    id: "cloudOffering",
    label: "Cloud offering",
    show: true,
    enableSorting: true,
    size: 300,
    minSize: 250,
    cell: renderText,
  },
  {
    header: "Simulator offering",
    accessorKey: "simulatorOffering",
    id: "simulatorOffering",
    label: "Simulator offering",
    show: true,
    enableSorting: true,
    size: 300,
    minSize: 250,
    cell: renderText,
  },
  {
    header: "Consulting offering?",
    accessorKey: "consultingOffering",
    id: "consultingOffering",
    label: "Consulting offering?",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "CPU offering?",
    accessorKey: "cpuOffering",
    id: "cpuOffering",
    label: "CPU offering?",
    show: true,
    enableSorting: true,
    cell: cell => {
      return (
        <div className="flex justify-center text-center align-middle">
          <p>
            <img
              src={cell.getValue() ? checkTrue : checkFalse}
              alt="cpuOffering"
              className="w-[14px] h-[14px]"
            />
          </p>
        </div>
      )
    },
  },
  {
    header: "GPU offering?",
    accessorKey: "gpuOffering",
    id: "gpuOffering",
    label: "GPU offering?",
    show: true,
    enableSorting: true,
    cell: cell => {
      return (
        <div className="flex justify-center text-center align-middle">
          <p>
            <img
              src={cell.getValue() ? checkTrue : checkFalse}
              alt="cpuOffering"
              className="w-[14px] h-[14px]"
            />
          </p>
        </div>
      )
    },
  },
  {
    header: "Country",
    accessorKey: "inCountry",
    id: "country",
    label: "country",
    show: true,
    enableSorting: false,
    cell: renderCountry,
  },
  {
    header: "Region",
    accessorKey: "inCountry",
    id: "region",
    label: "Region",
    show: true,
    enableSorting: false,
    cell: renderRegion,
  },
  {
    header: "Overall software offering",
    accessorKey: "softwareOffering",
    id: "softwareOffering",
    label: "Overall software offering",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "Quantum Library",
    accessorKey: "quantumLibrary",
    id: "quantumLibrary",
    label: "Quantum Library",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "Quantum Algorithms",
    accessorKey: "quantumAlgorithms",
    id: "quantumAlgorithms",
    label: "Quantum Algorithms",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "Quantum Circuit",
    accessorKey: "quantumCircuit",
    id: "quantumCircuit",
    label: "Quantum Circuit",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "Assembly Language",
    accessorKey: "assemblyLanguage",
    id: "assemblyLanguage",
    label: "Assembly Language",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
];

export const QcaasQpuTabs = () => {
  const isPremium = useCheckPremium();
  return (
    <div className="flex flex-row gap-2">
      <Tab
        path={APP_ROUTES.QCAAS}
        className="!max-w-auto !w-auto !h-[36px] !max-h-[36px] !min-h-[36px] hover:bg-primaryBlue hover:text-white hover:border-primaryBlue border border-primary"
      >
        <Link
          className="h-full w-full text-center text-[12px] font-normal flex justify-center items-center px-4 py-3 !max-h-[36px] !min-h-[36px]"
          to={APP_ROUTES.QCAAS}
        >
          QCaaS providers
        </Link>
      </Tab>
      <Tab
        path={APP_ROUTES.QPU}
        className="!max-w-auto !w-auto !h-[36px] !max-h-[36px] !min-h-[36px] hover:bg-primaryBlue hover:text-white hover:border-primaryBlue border border-primary"
      >
        <Link
          className="h-full w-full text-center text-[12px] !font-normal flex justify-center items-center px-4 py-3 !max-h-[36px] !min-h-[36px]"
          to={APP_ROUTES.QPU}
        >
          QPU providers
        </Link>
      </Tab>
      {isPremium && (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.google.com/spreadsheets/u/5/d/e/2PACX-1vSxrkP1xEYuGA0Toj5Z8vMcN3BLHszW-rxj2WC44WVa8fjxFsreOjHprB-Lrr6FVg/pubhtml#"
          className="h-[36px] py-3 !max-h-[36px] !min-h-[36px] px-4 hover:bg-primaryBlue hover:text-white hover:border-primaryBlue border border-primary bg-tablePrimary text-primaryBlue items-center justify-center rounded flex text-[12px]"
        >
          QPU Benchmarking
        </a>
      )}
    </div>
  );
};
