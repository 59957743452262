import classnames from "classnames";

import { IFilterType } from "types";

import { useSortAndFilters } from "../../Filter.hooks";
import { FilterUpdateFunc } from "../../Filter.types";

interface IProps {
  column: string;
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function FilterBlockCheckbox({
  data = [],
  checkedTags,
  column,
  showSectorHeader = false,
  sectorHeader,
  onCheck,
  columnName,
}: IProps) {
  const { transformedData } = useSortAndFilters(data);
  return (
    <div className="flex flex-col overflow-auto mt-2">
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="flex mb-3">
        <p className="border-none text-primary text-[14px] rounded-[2px] font-bold outline-none   ">
          {columnName}
        </p>
      </div>
      <div className="grid grid-cols-2 auto-rows-min  h-full  ">
        {checkedTags &&
          transformedData.map((item, i) => {
            let tagCompare: number | string = item.tag;
            if (
              column === "primaryClassification" ||
              column === "secondaryClassification"
            ) {
              tagCompare = item.id;
            }

            return (
              <div
                className={classnames(
                  "flex justify-start cursor-pointer px-3 py-1"
                )}
                key={item.tag + i}
                onClick={() => {
                  onCheck(column, String(item.id), String(item.tag));
                }}
              >
                <div className="flex justify-center items-center align-middle">
                  <span
                    className={classnames(
                      "mr-2 flex justify-center align-middle items-center bg-[#F5FCFF] text-center border  rounded-[4px] w-auto min-w-[20px] max-w-[20px] max-h-[20px] min-h-[20px] text-white text-xs",
                      {
                        "border-primaryBlue ": Object.values(checkedTags).find(
                          (tag) => String(tag) === String(tagCompare)
                        ),
                        "border-[#D5E2EA] ": Object.values(checkedTags).find(
                          (tag) => String(tag) !== String(tagCompare)
                        ),
                      }
                    )}
                  >
                    {Object.values(checkedTags).find(
                      (tag) => String(tag) === String(tagCompare)
                    ) ? (
                      <span className="h-[10px] w-[10px] bg-primaryBlue rounded-[2px]" />
                    ) : null}
                  </span>
                </div>
                <div className="flex justify-center items-center align-middle text-left text-[14px] text-primary">
                  {item.tag}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
