import CheckButton from "./components/CheckButton.component";
import Popup from "./components/Popup";
import RangeLine from "./components/RangeLine";
import RangeInputs from "./components/RangeInputs";
import RangeDates from "./components/RangeDates";
import FilterBlockCheckbox from "./components/PlainCheckbox.component";
import SliderComponent from "./components/Slider.component";
import React from "react";
import PlainInput from "./components/PlainInput.component";

export const FilterMapper = ({
  column,
  columnName,
  data,
  checkedData,
  groupStyle,
  onCheckTagToggle,
}) => {
  if (groupStyle === "input") {
    return (
      <PlainInput
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "row") {
    return (
      <CheckButton
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "col") {
    return (
      <Popup
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "range") {
    return (
      <RangeLine
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "rangeInputs") {
    return (
      <RangeInputs
        column={column}
        columnName={columnName}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "rangeDates") {
    return (
      <RangeDates
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "check") {
    return (
      <FilterBlockCheckbox
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }

  if (groupStyle === "slider") {
    return (
      <SliderComponent
        column={column}
        columnName={columnName}
        data={data}
        checkedTags={checkedData}
        onCheck={onCheckTagToggle}
      />
    );
  }
  return null;
}