import classnames from "classnames";

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  isSelected: boolean;
}

export function CustomCheckbox(props: IProps) {
  return (
    <input
      style={{
        appearance: "none",
        width: "12px",
        height: "12px",
      }}
      className={classnames(
        "border border-solid relative cursor-pointer rounded-[4px] flex justify-center items-center flex-col box-content flex-shrink-0",
        {
          "border-pattensBlue": !props.isSelected,
          "border-primaryBlue": props.isSelected,
          "before:block before:bg-primaryBlue before:w-[8px] before:h-[8px] before:rounded-[2px] before:absolute":
            props.isSelected,
        }
      )}
      type="checkbox"
      checked={props.isSelected}
      onChange={() => null}
    />
  );
}
