import config from "config";
import { gql, useQuery } from "@apollo/client";
import { IAggregate, IConsortiumEntity } from "types";
import { CONSORTIUM_FIELDS } from "./Consortium.constants";
import { useCheckIsAdmin, useCheckSuper } from "utils";
import { ENTITY_STATUSES } from "const";

const GET_CONSORTIUMS = gql`
  query getConsortiums(
      $where: EntityWhere
  ) {
    consortiumsAggregate: entitiesAggregate(
        where: $where
    ) {
			count
		}
    consortiums: entities(
      where: $where
    ) {
      ${CONSORTIUM_FIELDS}
    }
  }
`;

type ConsortiumVariableType = {
  where?: {
    name_IN?: string[];
    id?: string;
    status?: string;
  };
};

export function useGraphQLConsortiums({ where = {} }: ConsortiumVariableType) {
  const filtersData = where || {};
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();

  const { loading, error, data } = useQuery<{
    consortiums: Array<IConsortiumEntity>;
    consortiumsAggregate: IAggregate<{}>;
  }>(GET_CONSORTIUMS, {
    variables: {
      where: {
        ...filtersData,
        entityType_INCLUDES: "CONSORTIUM",
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
    },
  });

  return { loading, error, data };
}
