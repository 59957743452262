import { useLocation } from "react-router";

export function usePagination(defaultLimit = 30) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const limit: number = +(params.get("limit") || defaultLimit);
  const offset: number = +(params.get("offset") || 0);

  return {
    limit,
    offset,
  };
}