import { ACCESS_LEVEL, APP_ROUTES } from "const";
import { ISearchTabArray, ITableColumn } from "types";
import { renderList, renderRelations, renderText } from "utils";
import { Link } from "react-router-dom";
import React from "react";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";

export const columnsUseCases: ITableColumn = [
  {
    header: "Possible Use Case",
    accessorKey: "name",
    id: "name",
    label: "Possible Use Case",
    show: true,
    enableSorting: true,
    size: 220,
    cell: cell => {
      const name = cell.getValue();
      const mainSource = cell.row.original.mainSource;
      return (
        <div>
          {mainSource ? (
            <a
              target="_blank"
              href={mainSource}
              rel="noreferrer"
              className="text-primaryBlue font-bold text-[12px]"
            >
              {name}
            </a>
          ) : (
            name
          )}
        </div>
      )
    },
  },
  {
    header: "Problem Description",
    accessorKey: "description",
    id: "description",
    label: "Problem Description",
    show: true,
    enableSorting: true,
    size: 400,
    cell: renderText,
  },
  {
    header: "Industry",
    accessorKey: "industry",
    id: "industry",
    label: "Industry",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "Problem Domain",
    accessorKey: "problemDomain",
    id: "problemDomain",
    label: "Problem Domain",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
  {
    header: "Approaches",
    accessorKey: "approaches",
    id: "approaches",
    label: "Approaches",
    show: true,
    enableSorting: false,
    cell: renderList,
  },
  {
    header: "Hardware",
    accessorKey: "hardware",
    id: "hardware",
    label: "Hardware",
    show: true,
    enableSorting: false,
    cell: renderList,
  },
  {
    header: "Status",
    accessorKey: "currentStatus",
    id: "currentStatus",
    label: "Status",
    show: true,
    enableSorting: true,
    role: ACCESS_LEVEL.ADMIN,
    cell: renderText,
  },
  {
    header: "Example Companies",
    accessorKey: "companies",
    id: "companies",
    label: "Example Companies",
    show: true,
    enableSorting: false,
    cell: cell => renderRelations(cell, undefined),
  },
  {
    header: "Edit",
    accessorKey: "id",
    id: "Edit",
    label: "Edit",
    enableSorting: false,
    show: true,
    style: {
      width: 80
    },
    role: ACCESS_LEVEL.ADMIN,
    cell: cell => {
      return (
        <Link
          title="Edit"
          className="h-full w-full flex items-center justify-center"
          to={APP_ROUTES.USE_CASE.replace(":useCaseId", cell.getValue())}
        >
          <EditIcon />
        </Link>
      );
    },
  },
];

export const useCasesSearchTabsStructure: ISearchTabArray = [
  {
    tabId: 0,
    tabLabel: "",
    tagGroupFilter: [
      {
        groupName: "",
        groupStyle: "row",
        showHeader: false,
        hint: "",
        filterNames: ["Industries", "Problem domain", "Status"],
        filterIds: ["industries", "problemDomain", "currentStatus"],
      },
    ],
  },
];

export const UseCasesIndustries = [
  "Finance",
  "Multiple Industries",
  "Automotive",
  "Airline and Aerospace",
  "Energy",
  "Transport",
  "Chemicals and Pharmaceuticals",
  "Life Sciences",
  "Advertising",
  "Logistics",
  "Manufacturing",
  "E-Commerce",
  "Telecommunications",
  "Scientific Research",
  "Quantum Computing",
  "Material Science",
  "Music",
  "Cybersecurity",
  "Meteorology",
  "Navigation",
];

export const UseCasesProblemDomain = [
  "Optimization",
  "Machine Learning",
  "Simulation",
  "Search",
  "Random Number Generation",
  "Linear Algebra",
];

export const UseCasesCurrentStatus = [
  "Demonstrations on realistic data",
  "Toy demonstrations",
  "Theoretical",
  "Problem Formulation",
  "Speculation",
  "Classical Simulations",
  "Resource Estimation",
  "In deployment",
];
