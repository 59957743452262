import { APP_ROUTES, ACCESS_LEVEL } from "const";

import { lazy, Suspense, useEffect } from "react";

import { Navigate, useOutletContext } from "react-router-dom";
import { ICustomRouteObject, IOutletAuth, UserRoles } from "types";
import { LoaderComponent } from "components/Loader";
import { checkRoleOnPermission } from "utils";
import Qcaas from "modules/Qcaas";
import Qpu from "modules/Qpu";
import { UseCases } from "modules/UseCases";
import PatentsLayout from "layouts/PatentsLayoutLayout";
import Patents from "modules/Patents";
import PatentsStats from "modules/Patents/PatentsStats/PatentsStats";
import AdminDashboardEntities from "modules/AdminDashboard/AdminDashboardEntities";
import AdminDashboardRounds from "modules/AdminDashboard/AdminDashboardRounds";

const PrivateLayout = lazy(() => import("../layouts/PrivateLayout"));
const PublicLayout = lazy(() => import("../layouts/PublicLayout"));
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const NotFound = lazy(() => import("../layouts/NotFoundLayout"));

const Dashboard = lazy(() => import("../modules/Dashboard"));
const Profile = lazy(() => import("../modules/Profile"));
const Login = lazy(() => import("../modules/Login"));
const LatestUpdates = lazy(() => import("../modules/LatestUpdates"));

const PartnershipsLayout = lazy(() => import("../layouts/PartnershipsLayout"));
const Partnerships = lazy(() => import("../modules/Partnerships"));
const PartnershipsGraph = lazy(() => import("../modules/GraphPartnerships"));

const Companies = lazy(() => import("../modules/Companies"));
const CompanyLayout = lazy(() => import("../layouts/CompanyLayout"));
const GovernmentEntitiesLayout = lazy(
  () => import("../layouts/GovernmentEntitiesLayout")
);
const ConsortiumLayout = lazy(() => import("../layouts/ConsortiumLayout"));

const RoadMap = lazy(() => import("../modules/RoadMap"));
const CompanyProfile = lazy(
  () => import("../modules/Companies/CompanyProfile")
);
const CompanyFunding = lazy(
  () => import("../modules/Companies/CompanyFunding")
);
const CompanyPatents = lazy(
  () => import("../modules/Companies/CompanyPatents")
);
const SearchPage = lazy(() => import("../modules/FullTextSearch"));
const Entity = lazy(() => import("../modules/EntityForm"));
const UseCase = lazy(() => import("../modules/UseCase"));
const ChangePassword = lazy(() => import("../modules/ChangePassword"));
const Forum = lazy(() => import("../modules/Forum"));
const FundingRounds = lazy(() => import("../modules/FundingRounds"));
const FundingRound = lazy(() => import("../modules/FundingRound"));

const GovernmentEntities = lazy(() => import("../modules/GovernmentEntities"));

const GovernmentFundings = lazy(
  () => import("../layouts/GovernmentFundingsLayout")
);
const GovernmentFundingTable = lazy(
  () => import("../modules/GovernmentFundings")
);
const GovernmentFundingMap = lazy(
  () => import("../modules/GovernmentFundings/GovernmentFundingsMap")
);
const GroupsAndCenters = lazy(() => import("../modules/GroupsAndCenters"));

const Consortiums = lazy(() => import("../layouts/ConsortiumsLayout"));
const ConsortiumsTable = lazy(
  () => import("../modules/Consortiums/ConsortiumsTable.component")
);
const ConsortiumsMap = lazy(
  () => import("../modules/Consortiums/ConsortiumsMap/ConsortiumsMap")
);

const ConsortiumProfile = lazy(
  () => import("../modules/Consortiums/ConsortiumProfile")
);
const ConsortiumMembers = lazy(
  () => import("../modules/Consortiums/ConsortiumMembers")
);

const Investors = lazy(() => import("../modules/Investors"));
const News = lazy(() => import("../modules/News"));

const EnterpriseUsers = lazy(() => import("../modules/EnterpriseUsers"));
const Taxonomy = lazy(() => import("../modules/Taxonomy"));
const Reports = lazy(() => import("../modules/Reports"));
const Universities = lazy(() => import("../modules/Universities"));

const InvestorLayout = lazy(() => import("../layouts/InvestorLayout"));
const InvestorProfile = lazy(
  () => import("../modules/Investors/InvestorProfile")
);

const UniversityLayout = lazy(() => import("../layouts/UniversityLayout"));
const UniversityProfile = lazy(
  () => import("../modules/Universities/UniversityProfile")
);
const GovernmentEntitiesProfile = lazy(
  () => import("../modules/GovernmentEntities/GovernmentEntitiesProfile")
);

const EnterpriseUsersLayout = lazy(
  () => import("../layouts/EnterpriseUsersLayout")
);
const EnterpriseUsersProfile = lazy(
  () => import("../modules/EnterpriseUsers/EnterpriseUsersProfile")
);

const GroupsAndCentersLayout = lazy(
  () => import("../layouts/GroupsAndCentersLayout")
);
const GroupsAndCentersProfile = lazy(
  () => import("../modules/GroupsAndCenters/GroupsAndCentersProfile")
);

const AdminDashboard = lazy(() => import("../modules/AdminDashboard"));
const ManageAdmins = lazy(() => import("../modules/ManageAdmins"));
const MarketMapLayout = lazy(() => import("../layouts/MarketMapLayout"));
const VisualisationLayout = lazy(
  () => import("../layouts/VisualisationLayout")
);
const QcaasLayout = lazy(() => import("../layouts/QcaasLayout"));
const MarketMapCompanies = lazy(
  () => import("../modules/MarketMap/MarketMapCompanies")
);
const MarketMapInvestors = lazy(
  () => import("../modules/MarketMap/MarketMapInvestors")
);
const MarketMapUsers = lazy(
  () => import("../modules/MarketMap/MarketMapUsers")
);
const AnalysisTool = lazy(() => import("../modules/AnalysisTool"));
const GraphingTool = lazy(() => import("../modules/GraphingTool"));
const GraphingToolRounds = lazy(() => import("../modules/GraphingToolRounds"));

const SummaryLayout = lazy(() => import("../layouts/SummaryLayout"));
const Summary = lazy(() => import("../modules/Summary"));
const Heatmap = lazy(() => import("../modules/Heatmap"));

const GraphExplorerLayout = lazy(
  () => import("../layouts/GraphExplorerLayout")
);
const GraphExplorerComponent = lazy(() => import("../modules/GraphExplorer"));
const GovernmentFundingEntity = lazy(
  () => import("../modules/GovernmentFunding")
);
const AddEditPartnership = lazy(() => import("../modules/Partnership"));

const ResetPassword = lazy(() => import("../modules/ResetPassword"));
const ChangePasswordReset = lazy(
  () => import("../modules/ChangePasswordReset")
);
const NewPasswordRequired = lazy(
  () => import("../modules/NewPasswordRequired")
);

const SearchEngineLayout = lazy(() => import("../layouts/SearchEngineLayout"));
const SearchEngine = lazy(() => import("../modules/SearchEngine/SearchEngine"));
const SearchEngineHome = lazy(
  () => import("../modules/SearchEngine/SearchEngineHome")
);
const SearchEngineStories = lazy(
  () => import("../modules/SearchEngine/SearchEngineStories")
);

const MediaMonitoringLayout = lazy(
  () => import("../layouts/MediaMonitoringLayout")
);
const MediaMonitoring = lazy(
  () => import("../modules/SearchEngine/MediaMonitoring")
);
const MediaMonitoringAnalysis = lazy(
  () => import("../modules/SearchEngine/MediaMonitoringAnalysis")
);

const UseCasesLayout = lazy(() => import("../layouts/UseCasesLayout"));

const AnalysisGraph = lazy(
  () => import("../modules/SearchEngine/AnalysisGraph")
);

const AISummary = lazy(() => import("../modules/SearchEngine/AISummary"));

const BulkUpload = lazy(() => import("../modules/BulkUpload"));

interface IRouteAccess {
  children: any;
  redirectTo?: string;
}

function PublicAccess({ children, redirectTo = "#" }: IRouteAccess) {
  const authContext = useOutletContext<IOutletAuth>();
  useEffect(() => {
    authContext.setAccessType("public");
  }, [authContext]);

  return authContext?.isAuth ? <Navigate to={redirectTo} /> : children;
}

// function PrivateAccess({ children, redirectTo = "#" }: IRouteAccess) {
//   const authContext = useOutletContext<IOutletAuth>();
//   useEffect(() => {
//     authContext.setAccessType("private");
//   }, [authContext]);

//   return authContext?.isAuth ? children : <Navigate to={redirectTo} />;
// }

function MainAccess({ children }: IRouteAccess) {
  const authContext = useOutletContext<IOutletAuth>();
  useEffect(() => {
    authContext.setAccessType("public");
  }, [authContext]);

  return children;
}

function CommonAccess({ children }: IRouteAccess) {
  const authContext = useOutletContext<IOutletAuth>();
  useEffect(() => {
    authContext.setAccessType("common");
  }, [authContext]);

  return children;
}

interface IAuthorizationCheckProps {
  accessLevel: UserRoles | string;
  children: React.ReactNode[] | React.ReactNode;
  redirectIfNotEnoughAccessLevel?: APP_ROUTES;
}
function AuthorizationCheck({
  accessLevel,
  redirectIfNotEnoughAccessLevel = APP_ROUTES.DASHBOARD,
  children,
}: IAuthorizationCheckProps) {
  const authContext = useOutletContext<IOutletAuth>();
  if (
    !checkRoleOnPermission({
      roles: authContext.roles,
      requiredRole: accessLevel,
    })
  ) {
    return <Navigate to={redirectIfNotEnoughAccessLevel} />;
  }
  return <>{children}</>;
}

const routes: ICustomRouteObject[] = [
  {
    element: <MainLayout />,
    children: [
      {
        element: (
          <MainAccess>
            <PrivateLayout />
          </MainAccess>
        ),
        children: [
          {
            path: "/",
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Dashboard />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.CHANGE_PASSWORD,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <ChangePassword />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.DASHBOARD,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Dashboard />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.LATEST_UPDATES,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <LatestUpdates />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.SEARCH_PAGE,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <SearchPage />
              </Suspense>
            ),
          },
          // {
          //   path: APP_ROUTES.SEARCH_ENGINE,
          //   element: (
          //     <Suspense fallback={<LoaderComponent />}>
          //       <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
          //         <SearchEngine />
          //       </AuthorizationCheck>
          //     </Suspense>
          //   ),
          // },
          {
            path: APP_ROUTES.PROFILE,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.USER}>
                  <Profile />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.COMPANIES,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Companies />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.ENTITY,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <Entity />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.ENTITY_NEW,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <Entity />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.USE_CASE,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <UseCase />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.USE_CASE_NEW,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <UseCase />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.FORUM,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Forum />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.FUNDING_ROUNDS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <FundingRounds />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.FUNDING_ROUND,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <FundingRound />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.FUNDING_ROUND_NEW,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <FundingRound />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.GOVERNMENTS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <GovernmentEntities />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.GROUPS_AND_CENTERS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <GroupsAndCenters />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.INVESTORS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Investors />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.NEWS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <News />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.ENTERPRISE_USERS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <EnterpriseUsers />
              </Suspense>
            ),
          },
          {
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                  <Consortiums />
                </AuthorizationCheck>
              </Suspense>
            ),
            children: [
              {
                path: APP_ROUTES.CONSORTIUMS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <ConsortiumsTable />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.CONSORTIUMS_MAP,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <ConsortiumsMap />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <GovernmentFundings />
              </Suspense>
            ),
            children: [
              {
                path: APP_ROUTES.GOVERNMENT_FUNDINGS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <GovernmentFundingTable />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.GOVERNMENT_FUNDINGS_MAP,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <GovernmentFundingMap />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                  <ConsortiumLayout />
                </AuthorizationCheck>
              </Suspense>
            ),
            children: [
              {
                path: APP_ROUTES.CONSORTIUM_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <ConsortiumProfile />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.CONSORTIUM_MEMBERS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <ConsortiumMembers />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: APP_ROUTES.TAXONOMY,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Taxonomy />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.REPORTS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Reports />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.UNIVERSITIES,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Universities />
              </Suspense>
            ),
          },
          {
            element: <InvestorLayout />,
            children: [
              {
                path: APP_ROUTES.INVESTOR_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <InvestorProfile />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <UniversityLayout />,
            children: [
              {
                path: APP_ROUTES.UNIVERSITY_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <UniversityProfile />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <GovernmentEntitiesLayout />,
            children: [
              {
                path: APP_ROUTES.GOVERNMENT_ENTITIES_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <GovernmentEntitiesProfile />
                  </Suspense>
                ),
              },
            ],
          },

          {
            element: <EnterpriseUsersLayout />,
            children: [
              {
                path: APP_ROUTES.ENTERPRISE_USERS_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <EnterpriseUsersProfile />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <GroupsAndCentersLayout />,
            children: [
              {
                path: APP_ROUTES.GROUPS_AND_CENTERS_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <GroupsAndCentersProfile />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <AdminDashboard />,
            children: [
              {
                path: APP_ROUTES.ADMIN_DASHBOARD,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                      <AdminDashboardEntities />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.ADMIN_DASHBOARD_ROUNDS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                      <AdminDashboardRounds />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: APP_ROUTES.MANAGE_ADMINS,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.SUPER_ADMIN}>
                  <ManageAdmins />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.BULK_UPLOAD,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <BulkUpload />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            element: <MarketMapLayout />,
            children: [
              {
                path: APP_ROUTES.MARKET_MAP,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <MarketMapCompanies />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.MARKET_MAP_INVESTORS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <MarketMapInvestors />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.MARKET_MAP_USERS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <MarketMapUsers />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <VisualisationLayout />,
            children: [
              {
                path: APP_ROUTES.GRAPHING_TOOL,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <GraphingTool />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.GRAPHING_TOOL_ROUNDS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <GraphingToolRounds />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.ANALYSIS_TOOL,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AnalysisTool />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.ROAD_MAP,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <RoadMap />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                <GraphExplorerLayout />
              </AuthorizationCheck>
            ),
            children: [
              {
                path: APP_ROUTES.GRAPH_EXPLORER,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <GraphExplorerComponent />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.GRAPH_EXPLORER_ENTITY,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <GraphExplorerComponent />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                <SummaryLayout />
              </AuthorizationCheck>
            ),
            children: [
              {
                path: APP_ROUTES.SUMMARY_MAP,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <Summary />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.HEATMAP,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <Heatmap />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                <SearchEngineLayout />
              </AuthorizationCheck>
            ),
            children: [
              {
                path: APP_ROUTES.SEARCH_ENGINE_HOME,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <SearchEngineHome />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.SEARCH_ENGINE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <SearchEngine />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.ANALYSIS_GRAPH,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <AnalysisGraph />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.AI_SUMMARY,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <AISummary />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.SEARCH_ENGINE_STORIES,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <SearchEngineStories />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                <MediaMonitoringLayout />
              </AuthorizationCheck>
            ),
            children: [
              {
                path: APP_ROUTES.MEDIA_MONITORING,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <MediaMonitoring />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.MEDIA_MONITORING_ANALYSIS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <MediaMonitoringAnalysis />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <QcaasLayout />,
            children: [
              {
                path: APP_ROUTES.QCAAS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <Qcaas />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.QPU,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <Qpu />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: (
              <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                <PatentsLayout />
              </AuthorizationCheck>
            ),
            children: [
              {
                path: APP_ROUTES.PATENTS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <Patents />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.PATENTS_LIST_VIEW,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <Patents />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.PATENTS_STATS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <PatentsStats />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <UseCasesLayout />,
            children: [
              {
                path: APP_ROUTES.USE_CASES,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <UseCases />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: APP_ROUTES.GOVERNMENT_FUNDING,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <GovernmentFundingEntity />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.GOVERNMENT_FUNDING_NEW,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <GovernmentFundingEntity />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.PARTNERSHIP,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <AddEditPartnership />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.PARTNERSHIP_EDIT,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <AuthorizationCheck accessLevel={ACCESS_LEVEL.ADMIN}>
                  <AddEditPartnership />
                </AuthorizationCheck>
              </Suspense>
            ),
          },
          {
            element: <CompanyLayout />,
            children: [
              {
                path: APP_ROUTES.COMPANY_PROFILE,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <CompanyProfile />
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.COMPANY_FUNDING,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <CompanyFunding />
                  </Suspense>
                ),
              },
              // {
              //   path: APP_ROUTES.COMPANY_RESEARCH,
              //   element: (
              //     <Suspense fallback={<LoaderComponent />}>
              //       <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
              //         <CompanyResearch />
              //       </AuthorizationCheck>
              //     </Suspense>
              //   ),
              // },
              {
                path: APP_ROUTES.COMPANY_PATENTS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <CompanyPatents />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <PartnershipsLayout />,
            children: [
              {
                path: APP_ROUTES.PARTNERSHIPS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <Partnerships />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.GRAPH_PARTNERSHIPS,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <PartnershipsGraph />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
              {
                path: APP_ROUTES.GRAPH_PARTNERSHIPS_ENTITY,
                element: (
                  <Suspense fallback={<LoaderComponent />}>
                    <AuthorizationCheck accessLevel={ACCESS_LEVEL.PREMIUM}>
                      <PartnershipsGraph />
                    </AuthorizationCheck>
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        element: (
          <PublicAccess redirectTo={APP_ROUTES.DASHBOARD}>
            <PublicLayout />
          </PublicAccess>
        ),
        children: [
          {
            path: APP_ROUTES.LOGIN,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <Login />
              </Suspense>
            ),
          },
          // {
          //   path: APP_ROUTES.SIGN_UP,
          //   element: (
          //     <Suspense fallback={<LoaderComponent />}>
          //       <Registration />
          //     </Suspense>
          //   ),
          // },
          {
            path: APP_ROUTES.RESET_PASSWORD,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <ResetPassword />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.CHANGE_PASSWORD_RESET,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <ChangePasswordReset />
              </Suspense>
            ),
          },
          {
            path: APP_ROUTES.NEW_PASSWORD_REQUIRED,
            element: (
              <Suspense fallback={<LoaderComponent />}>
                <NewPasswordRequired />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: (
          <CommonAccess redirectTo={APP_ROUTES.DASHBOARD}>
            <NotFound />
          </CommonAccess>
        ),
        path: "*",
      },
      {
        path: APP_ROUTES.NOT_FOUND,
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
