import queryString from "query-string";

export const getNewestValue = (fieldName: string) => {
  const queryStr = queryString.parse(window.location.search);
  const value = queryStr?.[fieldName] || [];
  return Array.isArray(value) ? value : [value];
};

export const updateValueInQueryString = (
  fieldName: string,
  value: string | number | null | undefined
) => {
  const queryStr = queryString.parse(window.location.search);

  // clear pagination values (limit and offset) when filter is changed
  delete queryStr.limit;
  delete queryStr.offset;

  const newQueryStr = { ...queryStr, [fieldName]: value };
  return queryString.stringify(newQueryStr);
};
