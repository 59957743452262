import { ISort } from "./Filter.types";
import { IFilterType } from "types";

export function sortData(sortType: ISort) {
  return (dataA: IFilterType, dataB: IFilterType) => {
    if (sortType["count"]) {
      if (dataA.resultsCont > dataB.resultsCont) {
        return sortType.count === "ascending" ? 1 : -1;
      }
      if (dataA.resultsCont < dataB.resultsCont) {
        return sortType.count === "descending" ? 1 : -1;
      }
      return 0;
    }
    if (sortType["title"]) {
      if (dataA.tag.toLowerCase() > dataB.tag.toLowerCase()) {
        return sortType.title === "ascending" ? 1 : -1;
      }
      if (dataA.tag.toLowerCase() < dataB.tag.toLowerCase()) {
        return sortType.title === "descending" ? 1 : -1;
      }
      return 0;
    }
    return 0;
  };
}
