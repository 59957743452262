import { gql, useQuery } from "@apollo/client";
import { ISearchKey } from "types";

const GET_SEARCH_KEYS = gql`
  query getSearchKeys { 
    searchKeys {
      id
      keyString
      vertical
    }
  }
`;

export const useGraphQLSearchKeys = () => {
  const { loading, error, data } = useQuery<{
    searchKeys: Array<ISearchKey>;
  }>(GET_SEARCH_KEYS);

  return {
    keyString: data?.searchKeys?.[0]?.keyString || '',
    loading,
    error,
  };
};
