import patentsGraphsOrgsCount from "assets/images/patentsGraphsOrgsCount.svg";
import patentsGraphsCount from "assets/images/patentsGraphsCount.svg";
import { useLocation } from "react-router";
import classNames from "classnames";

export const PatentsStatsCounts = ({
  patentsCount,
  orgsCount,
}: {
  patentsCount: number;
  orgsCount: number;
  lastYear?: string;
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const organizationParam = params.get("organization");

  return (
    <div
      className={classNames(
        "flex flex-col p-8 w-[248px] h-[350px] bg-tablePrimary rounded justify-between",
        {
          "!justify-center items-center": organizationParam,
        }
      )}
    >
      {patentsCount && (
        <div className="flex flex-col gap-6">
          <span className="text-primary text-[18px] font-medium">Patents</span>
          <div className="flex flex-row gap-6">
            <img src={patentsGraphsCount} alt="Patents Count" />
            <div className="flex flex-col justify-center">
              <span className="text-primary text-[22px] font-bold">
                {patentsCount}
              </span>
              <span className="text-secondary text-[12px]">Overall</span>
            </div>
          </div>
        </div>
      )}
      {!organizationParam && !!orgsCount && (
        <>
          <hr className="border-primary mt-4" />
          <div className="flex flex-col gap-6">
            <span className="text-primary text-[18px] font-medium">
              Organizations
            </span>
            <div className="flex flex-row gap-6">
              <img src={patentsGraphsOrgsCount} alt="Organizations Count" />
              <div className="flex flex-col justify-center">
                <span className="text-primary text-[22px] font-bold">
                  {orgsCount < 999 ? orgsCount : "999+"}
                </span>
                <span className="text-secondary text-[12px]">
                  Over {new Date().getFullYear()} year
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
