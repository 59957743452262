import classnames from "classnames";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../TableExtended.styles.css";
import { useRef, useState } from "react";
import { flexRender } from "@tanstack/react-table";

const THeadItem = ({
  visibleColumns,
  setColumnOrder,
  headerGroup,
  classNames,
  premiumAccess,
  isSortable,
  isPremium,
  setIsResizeStarted,
}) => {

  const currentColOrder = useRef<any>();
  // const [isDragging, setIsDragging] = useState(false);
  const [isResized, setIsResized] = useState(false);

  return (
    <DragDropContext
      onDragEnd={() => {
        // setIsDragging(false);
        // setIsResizeStarted(true);
      }}
      onDragStart={() => {
        // setIsDragging(true);
        currentColOrder.current = visibleColumns.map(col => col.id);
      }}
      onDragUpdate={(dragUpdateObj) => {
        const colOrder = [...currentColOrder.current];
        const sIndex = dragUpdateObj.source.index;
        const dIndex =
          dragUpdateObj.destination && dragUpdateObj.destination.index;

        if (typeof dIndex === "number") {
          colOrder.splice(sIndex, 1);
          colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
          setColumnOrder(colOrder);
        }
      }}
    >
      <Droppable
        isDropDisabled={isResized}
        droppableId="droppable"
        direction="horizontal"
      >
        {(droppableProvided) => (
          <tr
            key={headerGroup.id}
            ref={droppableProvided.innerRef}
            className="min-h-[44px] row header-group"
            role="row"
            style={{
              display: "flex",
              flex: "1 0 auto",
              minWidth: "1110px",
            }}
          >
            {headerGroup.headers
              .map((header, index) => (
                <Draggable
                  key={header.id}
                  draggableId={header.id}
                  index={index}
                  isDragDisabled={
                    //@ts-ignore
                    isResized || !isPremium || !header.column.columnDef.accessorKey
                  }
                >
                  {(provided, snapshot) => {
                    return (
                      <th
                        role="columnheader"
                        style={{
                          boxSizing: "border-box",
                          position: "relative",
                          padding: "12px",
                          minWidth: header.column.columnDef?.minSize,
                          maxWidth: header.column.columnDef?.maxSize,
                          ...header.column.columnDef.style,
                          flex: `${header.column.getSize()} 0 auto`,
                          width: header.column.getSize(),
                        }}
                        colSpan={header.colSpan}
                        key={header.id}
                        className={classnames(
                          classNames,
                          "cell header !flex flex-row flex-wrap content-center",
                          {
                            "cursor-pointer": header.column.getCanSort() && isPremium,
                          }
                        )}
                      >
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={`w-full h-full table-extended-draggable ${
                            !snapshot.isDragging ? "isDragging" : ""
                          } ${snapshot.dropAnimation ? "isDropAnimating" : ""}`}
                        >
                          <div
                            onClick={() => {
                              if (
                                premiumAccess &&
                                isPremium &&
                                header.column.getCanSort()
                              ) {
                                header.column.toggleSorting();
                              }
                            }}
                            className="flex flex-row justify-between w-full items-center"
                          >
                            <div className="h-full font-normal">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                            <div className="h-full flex justify-center items-center pl-[6px] pr-[12px]">
                              <span
                                className={classnames(
                                  "self-center text-[12px]",
                                  {
                                    " hidden":
                                      !isSortable ||
                                      //@ts-ignore
                                      !header.column.getCanSort(),
                                  }
                                )}
                              >
                                <div
                                  className={classnames(
                                    "flex flex-row justify-end text-secondary "
                                  )}
                                >
                                  <p className={`opacity-${header.column.getIsSorted() === "asc" ? '50' : '100'} mr-[1px]`}>↑</p>
                                  <p className={`opacity-${header.column.getIsSorted() === "desc" ? '50' : '100'}`}>↓</p>
                                </div>

                              </span>
                            </div>
                          </div>
                          <div
                            onMouseOver={() => setIsResized(true)}
                            onMouseOut={() => setIsResized(false)}
                            onMouseUp={() => setIsResizeStarted(true)}
                            className={classnames(
                              `resizer cursor-col-resize ${
                                header.column.getIsResizing() ? "isResizing" : ""
                              } ${
                                index === visibleColumns?.length - 1 ||
                                header.column.columnDef.label === "Logo" ||
                                !isPremium
                                  ? "resizingHidden"
                                  : ""
                              }`
                            )}
                            onDoubleClick={() => header.column.resetSize()}
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                          />
                        </div>
                      </th>
                    );
                  }}
                </Draggable>
              ))}
            {droppableProvided.placeholder}
          </tr>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default THeadItem;
