export const ROUND_FIELDS = `
vertical
id
startDate
fxRate
fundingUsd @include(if: $isAuth)
fundingLocal @include(if: $isAuth)
type
status
source @include(if: $isAuth)
organization
organizationHasRound {
    vertical
    id
    name
    city
    inCountry {
        name
        region {
            id
            name
        }
    }
    logo
    inClassification @include(if: $isAuth) {
        id
        name
        childOf {
            id
            name
        }
    }
}
investors @include(if: $isAuth) {
    id
    name
    ofType {
        id
        name
    }
    investedInConnection {
        edges {
            isLead
            node {
                id
            }
        }
    }
}
lastUpdatedBy @include(if: $isAdmin)
updatedAt
createdAt
isGovernment
`;
