import { memo, useCallback } from "react";

import { QcaasQpuTabs, columnsQcaas } from "./Qcaas.constants";
import { useTagFilter } from "./Qcaas.hooks";
import TableExtendedDraggable from "components/TableExtended/TableExtendedDraggable.component";
import { useNavigate } from "react-router";
import { useCheckPremium } from "utils";
import { APP_ROUTES } from "const";

function Qcaas() {
  const isPremium = useCheckPremium();

  const { qcaas, status } = useTagFilter();

  const navigate = useNavigate();

  return (
    <div className="office flex flex-col w-full bg-primary">
      <div className="pb-6 w-full">
        <div className="flex flex-wrap w-full">
          <div className="overflow-auto w-full">
            <TableExtendedDraggable
              classNames={{
                table: "text-[14px]",
                headTh:
                  "items-center text-[12px] text-center bg-tablePrimary text-secondary",
                tr: "min-h-[48px] max-h-full",
                td: "text-primary",
                cardClassName: "max-h-[calc(100vh-140px)]",
              }}
              premiumAccess={isPremium}
              showPageSizeSelector={isPremium}
              showFiltering={false}
              queryStatus={status}
              showColumnVisibility={true}
              showHeader={true}
              enableSearch={true}
              showPagination
              showEntriesCount
              isSortable
              totalCount={qcaas?.organizationsAggregate?.count}
              data={qcaas?.organizations || []}
              columns={columnsQcaas}
              tableName="QCaaS"
              showTableName={false}
              showPendingSwitch={false}
              onAdd={useCallback(() => {
                navigate(APP_ROUTES.ENTITY_NEW);
              }, [navigate])}
              beforeTitle={QcaasQpuTabs()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(Qcaas);
