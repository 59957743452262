import config from "config";
import { gql, useQuery } from "@apollo/client";
import {
  ICenterEntity,
  IConsortiumEntity,
  IGovernmentEntity,
  IInvestorEntity,
  IOrganizationEntity,
  IEnterpriseUserEntity,
  IUniversityEntity,
} from "types";

const GET_DATA = gql`
  query getFullTextSearch($phrase: String!) {
    organizations: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: {
          vertical_INCLUDES: ${config.VERTICAL}
          entityType_INCLUDES: ORGANIZATION
        }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
        fundingRounds {
          type
          startDate
          fundingUsd
          organizationHasRound {
            id
            name
            logo
          }
        }
      }
    }

    investors: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: { vertical_INCLUDES: ${config.VERTICAL}, entityType_INCLUDES: INVESTOR }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
      }
    }

    enterpriseUsers: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: { vertical_INCLUDES: ${config.VERTICAL}, entityType_INCLUDES: BRAND }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
      }
    }

    centers: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: { vertical_INCLUDES: ${config.VERTICAL}, entityType_INCLUDES: CENTER }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
      }
    }

    universities: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: { vertical_INCLUDES: ${config.VERTICAL}, entityType_INCLUDES: UNIVERSITY }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
      }
    }

    governmentEntities: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: {
          vertical_INCLUDES: ${config.VERTICAL}
          OR: [
            { entityType_INCLUDES: GOVERNMENT_AGENCY },
            { entityType_INCLUDES: NATIONAL_LAB },
          ]
        }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
      }
    }

    consortiums: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: { vertical_INCLUDES: ${config.VERTICAL}, entityType_INCLUDES: CONSORTIUM }
      }
      sort: { score: DESC }
      limit: 10
    ) {
      score
      entity {
        id
        name
        description
        logo
        entityType
      }
    }
  }
`;

export function useGraphQLGlobalSearch(phrase: string, skip?: boolean) {
  const { loading, error, data } = useQuery<{
    organizations: {
      score: number;
      entity: Partial<IOrganizationEntity>;
    }[];
    investors: {
      score: number;
      entity: Partial<IInvestorEntity>;
    }[];
    enterpriseUsers: {
      score: number;
      entity: Partial<IEnterpriseUserEntity>;
    }[];
    centers: {
      score: number;
      entity: Partial<ICenterEntity>;
    }[];
    universities: {
      score: number;
      entity: Partial<IUniversityEntity>;
    }[];
    governmentEntities: {
      score: number;
      entity: Partial<IGovernmentEntity>;
    }[];
    consortiums: {
      score: number;
      entity: Partial<IConsortiumEntity>;
    }[];
  }>(GET_DATA, {
    variables: {
      phrase: phrase,
    },
    skip: !phrase || phrase === "undefined*" || skip,
  });

  return { loading, error, data };
}

const SEARCH_ENTITIES = gql`
  query SearchEntities($phrase: String!) {
    entities: entitiesFulltextFullTextSearchFields(
      phrase: $phrase
      where: {
        score: { min: 0.5 }
        entity: {
          vertical_INCLUDES: ${config.VERTICAL}
          entityType_INCLUDES: ORGANIZATION
        }
      }
      sort: { score: ASC }
      limit: 10
    ) {
      entity {
        name
      }
    }
  }
`;
export function useGraphQLSearchEntities(phrase: string) {
  const { loading, error, data } = useQuery<{
    entities: {
      entity: {
        name: string;
      };
    }[];
  }>(SEARCH_ENTITIES, {
    variables: {
      phrase: phrase,
    },
    skip: !phrase || phrase === "undefined*",
  });

  return { loading, error, data };
}
