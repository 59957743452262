export const DEFAULT_INVESTOR_STATE = {
  isLeadInvestor: true,
  type: null,
  entity: null,
};

export const DEFAULT_GOV_FUNDING_ROUND_STATE = {
  id: undefined,
  fundingUsd: 0,
  fundingLocal: 0,
  fxRate: 0,
  localCurrency: null,
  source: null,
  isActive: false,
  fundingInfo: null,
  deliveryPeriod: null,
  startDate: null,
};

export const DEFAULT_QUBIT_NUMBERS_STATE = {
  id: null,
  source: "",
  year: "",
  number: null,
};

export const DEFAULT_CLASSIFICATIONS_STATE = {
  id: "",
  name: "",
  description: "",
  parentOf: [],
};

export const DEFAULT_SECONDARY_CLASSIFICATIONS_STATE = {
  id: "",
  name: "",
  description: "",
};

export const DEFAULT_TAGS_STATE = {
  id: "",
  name: "",
  description: "",
};

export const DEFAULT_BUSINESS_TYPE_STATE = {
  id: "",
  type: "",
};

export const DEFAULT_INVESTOR_TYPE_STATE = {
  id: "",
  name: "",
  description: "",
};

type RelationDataType = Array<string> | string | null | undefined;

export class Relation {
  connect: {
    where?:
      | {
          node: { id: string | null } | { id_IN: string[] };
        }
      | {};
  } = {};
  constructor(data: RelationDataType, isName?: boolean) {
    if (Array.isArray(data)) {
      this.addManyConnections(data);
    } else {
      this.addOneConnection(data, isName);
    }
  }
  addOneConnection(
    data: string | undefined | null = "",
    isName: boolean = false
  ) {
    // IMPORTANT! if we don't have value - we have to send empty string,
    // if we send with id: undefined, or without id { where: { node: {  } } }
    // then entity will connect all items from dependent entity
    if (!isName) {
      this.connect = { where: { node: { id: data } } };
    } else {
      this.connect = { where: { node: { name: data } } };
    }
  }
  addManyConnections(data: string[]) {
    this.connect = { where: { node: { id_IN: data } } };
  }
}

export const TrimStrings = (original: any) => {
  const wrapped: any = function (...args: any[]) {
    console.log("Modified constructor is called");

    const builded = new original(...args);
    Object.keys(builded).forEach(field => {
      if (typeof builded[field] === "string") {
        builded[field] = builded[field].trim();
      }
    });

    return builded;
  };

  wrapped.prototype = original.prototype;

  return wrapped;
}