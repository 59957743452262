import config from "config";
import { gql, useQuery } from "@apollo/client";
import {
  IAggregate,
  ICountryEntity,
  IInvestorEntity,
  IRoundEntity,
  VariableType,
} from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import { ROUND_FIELDS } from "./Round.constants";
import { ENTITY_STATUSES } from "const";

const GET_ROUNDS = gql`
  query getFundingRounds(
    $where: FundingRoundWhere, 
    $sort: [FundingRoundSort!], 
    $limit: Int, 
    $offset: Int, 
    $isAdmin: Boolean!, 
    $isAuth: Boolean!
    #$phrase: String!
  ) {
    fundingRoundsAggregate(
      where: $where
      #fulltext: {
        #FundingRoundOrganizationFullText: {
          #phrase: $phrase
        #}
      #}
    ) {
      count
      __typename
    }
    fundingRounds(
      options: {limit: $limit, offset: $offset, sort: $sort}
      where: $where
      #fulltext: {
        #FundingRoundOrganizationFullText: {
          #phrase: $phrase
        #}
      #}
    ) {
      ${ROUND_FIELDS}
    }
    countries @include(if: $isAuth) {
      name
      __typename
    }
    cityList @include(if: $isAuth)
    investors: entities (where: {
      entityType_INCLUDES: INVESTOR
      vertical_INCLUDES: ${config.VERTICAL}
    }) @include(if: $isAuth) {
      name
    }
  }
`;

export function useGraphQLFundingRounds({
  limit,
  offset,
  sort,
  where,
  search,
}: VariableType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<
    {
      fundingRounds: Array<IRoundEntity>;
      fundingRoundsAggregate: IAggregate<{}>;
      countries: Partial<ICountryEntity>[];
      cityList: string[];
      investors: Array<IInvestorEntity>;
    },
    VariableType
  >(GET_ROUNDS, {
    variables: {
      limit: limit,
      where: {
        ...where,
        // status_IN: ["APPROVED", "PENDING"],
        vertical_INCLUDES: config.VERTICAL,
        OR: [{ isGovernment: false }, { isGovernment: null }],
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      // phrase: search
      //   ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
      //   : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
    },
  });

  return { loading, error, data };
}

export function useGraphQLFundingRoundsAggregated() {
  const { loading, error, data } = useQuery<
    {
      latestFundingRound: Array<IRoundEntity>;
      InvestorCount: IAggregate<{}>;
      OrganizationCount: IAggregate<{}>;
      fundingRoundsSum;
    },
    VariableType
  >(
    gql`
    query getFundingRoundsAggregated {
      fundingRoundsSum
      latestFundingRound: fundingRounds(
        options: {limit: 1, sort: { startDate: DESC }}
        where: { startDate_GTE: "1900-01-01", vertical_INCLUDES: ${config.VERTICAL}, status_IN: [APPROVED, PENDING] }
      ) {
        id
        startDate
        status
        vertical
      }
      InvestorCount: entitiesAggregate(
        where: { entityType_INCLUDES: INVESTOR, vertical_INCLUDES: ${config.VERTICAL} }
      ) {
        count
      }
      OrganizationCount: entitiesAggregate(
        where: { entityType_INCLUDES: ORGANIZATION, vertical_INCLUDES: ${config.VERTICAL} }
      ) {
        count
      }
    }
  `
  );

  return { loading, error, data };
}
