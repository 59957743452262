import { ITableColumn } from "types";
import { APP_ROUTES } from "const";
import { renderEntity, renderLogo, renderRelations, renderText } from "utils";

export const columnsQpu: ITableColumn = [
  {
    header: "",
    accessorKey: "logo",
    id: "logo",
    label: "Logo",
    show: true,
    enableSorting: false,
    size: 60,
    minSize: 60,
    cell: cell => renderLogo(cell, APP_ROUTES.COMPANY_PROFILE),
  },
  {
    header: "Company",
    accessorKey: "name",
    id: "name",
    label: "Company",
    show: true,
    enableSorting: true,
    cell: cell => renderEntity(cell, APP_ROUTES.COMPANY_PROFILE),
  },
  {
    header: "Cloud offering",
    accessorKey: "cloudOffering",
    id: "cloudOffering",
    label: "Cloud offering",
    show: true,
    enableSorting: true,
    size: 300,
    minSize: 250,
    cell: renderText,
  },
  {
    header: "Simulator offering",
    accessorKey: "simulatorOffering",
    id: "simulatorOffering",
    label: "Simulator offering",
    show: true,
    enableSorting: true,
    size: 300,
    minSize: 250,
    cell: renderText,
  },
  {
    header: "Accessible via",
    accessorKey: "organizationFacilitatesAccessTo",
    id: "organizationFacilitatesAccessTo",
    label: "Accessible via",
    show: true,
    enableSorting: false,
    size: 220,
    cell: cell => renderRelations(cell, APP_ROUTES.COMPANY_PROFILE),
  },
  {
    header: "Consulting offering?",
    accessorKey: "consultingOffering",
    id: "consultingOffering",
    label: "Consulting offering?",
    show: true,
    enableSorting: true,
    cell: renderText,
  },
];
