import { Link } from "react-router-dom";
import { EntityLogo } from "components/EntityLogo";
import { APP_ROUTES } from "const";
import format from "date-fns/format";
import { adjustDateForUTC } from "./validation";
import { PremiumLinkWithIcon } from "../components/PremiumCards";
import { getLinkByEntity } from "./urls";

export const renderText = (cell) => {
  const value = cell.getValue();
  return (
    <div className="flex justify-start text-left align-middle">
      <p>{value ? cell.getValue() : ""}</p>
    </div>
  );
};

export const renderList = (cell) => {
  const values = cell.getValue();
  return (
    <div>
      {values.length &&
        values.map((val, index) => (
          <div className="inline" key={index}>
            <span>{val}</span>
            {index + 1 !== values.length && ", "}
          </div>
        ))}
    </div>
  );
};

export const renderLogo = (cell, route: APP_ROUTES | string, logoSize = 35) => {
  const row = cell.row.original;
  return (
    <div className="flex justify-center">
      <Link
        className="text-primaryBlue font-bold text-[14px]"
        to={route.replace(":entityId", `${row.id}`)}
      >
        {row?.name && (
          <EntityLogo
            key={row.name}
            entityName={row.name}
            logoWidth={logoSize}
            logoFromDB={cell.getValue()}
          />
        )}
      </Link>
    </div>
  );
};

export const renderEntity = (cell, route: APP_ROUTES) => {
  return (
    <div className="flex justify-start text-left align-middle">
      <Link
        className="text-primaryBlue font-bold text-[14px]"
        to={route.replace(":entityId", `${cell.row.original.id}`)}
      >
        {cell.getValue()}
      </Link>
    </div>
  );
};

export const renderRelation = (cell, route: APP_ROUTES) => {
  const entity = cell.getValue()?.[0];
  return (
    <div className="flex pl-2  pr-5 justify-start text-left align-middle">
      {entity && (
        <div className="inline">
          <a
            className="text-primaryBlue font-semibold"
            href={route.replace(":entityId", entity.id)}
            rel="noreferrer"
          >
            {entity.name}
          </a>
        </div>
      )}
    </div>
  );
};

export const renderRelations = (cell, forcedRoute?: APP_ROUTES) => {
  const entities = cell.getValue();

  if (!entities?.length) return <span />;
  const uniqueEntities = [...new Set(entities)];

  return (
    <div className="flex justify-start text-left align-middle">
      <div>
        {uniqueEntities.map((item: any, i) => {
          const route = forcedRoute
            ? forcedRoute.replace(":entityId", item.id)
            : getLinkByEntity(item);
          return (
            <span key={"relation" + item.name}>
              <Link
                className="text-primaryBlue font-medium text-[14px]"
                // @ts-ignore
                to={route}
              >
                {item.name}
              </Link>

              {i < uniqueEntities.length - 1 ? "; " : null}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export const renderCountry = (cell) => {
  return (
    <div className="flex justify-start text-left align-middle">
      {cell.getValue()?.[0]?.name}
    </div>
  );
};

export const renderRegion = (cell) => {
  return (
    <div className="flex justify-start text-left align-middle">
      {cell.getValue()?.[0]?.region[0]?.name}
    </div>
  );
};

export const renderDate = (cell) => {
  const value = cell.getValue();
  return (
    <div className="flex justify-start text-left align-middle px-2">
      {!value ? "" : `${format(adjustDateForUTC(value), "yyyy-MM-dd")}`}
    </div>
  );
};

export const renderFunding = (cell, hide: boolean) => {
  const funding = cell.getValue();
  return !hide ? (
    <div className="flex  pl-2 pr-5 justify-start text-left align-middle">
      {funding !== null && +funding !== 0 && +funding !== 1
        ? new Intl.NumberFormat("en-EN").format(Number(funding))
        : "Unknown"}
    </div>
  ) : (
    <div className="flex   justify-start text-left ">
      <PremiumLinkWithIcon />
    </div>
  );
};
