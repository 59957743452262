import { gql, useQuery } from "@apollo/client";
import { IAggregate, ICountryEntity, IUniversityEntity } from "types";
import { useCheckIsAdmin, useCheckPremium, useCheckSuper } from "utils";
import { UNIVERSITY_FIELDS } from "./University.constants";
import config from "config";
import { ENTITY_STATUSES } from "const";

const GET_UNIVERSITIES = gql`
  query getUniversities(
    $where: EntityWhere
    $sort: [EntitySort!]
    $limit: Int
    $offset: Int
    $phrase: String!
    $isAdmin: Boolean!
    $isAuth: Boolean!
  ) {
    universitiesAggregate: entitiesAggregate(where: $where, fulltext: { FullTextSearchFields: { phrase: $phrase } }) {
			count
		}
    universities: entities(
      options: {
        limit: $limit,
        offset: $offset,
        sort: $sort
      },
      where: $where
      fulltext: { FullTextSearchFields: { phrase: $phrase } }
      ) {
      ${UNIVERSITY_FIELDS}
    }    
    countries @include(if: $isAuth) {
      name
    }
    cityList @include(if: $isAuth)
  }
`;

type UniversityVariableType = {
  where?: {
    name_CONTAINS?: string;
    id?: string;
    status?: string;
    entityType_INCLUDES?: string;
    vertical_INCLUDES?: string;
  };
  sort?: {};
  limit?: number;
  offset?: number;
  search?: string | null;
  phrase?: string;
  isAdmin?: boolean;
  isAuth?: boolean;
};

export function useGraphQLUniversities({
  limit,
  offset,
  sort,
  where,
  search,
}: UniversityVariableType) {
  const isAdmin = useCheckIsAdmin();
  const isSuper = useCheckSuper();
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<
    {
      universities: Array<IUniversityEntity>;
      universitiesAggregate: IAggregate<{}>;
      countries: Partial<ICountryEntity>[];
      cityList: string[];
    },
    UniversityVariableType
  >(GET_UNIVERSITIES, {
    variables: {
      limit: limit,
      where: {
        ...where,
        entityType_INCLUDES: "UNIVERSITY",
        vertical_INCLUDES: config.VERTICAL,
        ...(!isSuper &&
          !isAdmin && {
            status_IN: ENTITY_STATUSES.APPROVED,
          }),
      },
      sort: sort,
      offset: offset,
      phrase: search
        ? "+" + search.replaceAll("-", " ").replaceAll(" ", " +") + "*"
        : "*",
      isAdmin: isAdmin,
      isAuth: isPremium,
    },
  });

  return { loading, error, data };
}
