import classnames from "classnames";

interface ILabelProps {
  name: string;
  className?: string;
  required?: boolean;
}

export function Label({ name, className, required }: ILabelProps) {
  return (
    <label className={classnames(className, "text-sm mb-[10px] font-medium")}>
      {name}
      {required && <span className="text-green-500 text-sm ml-1">*</span>}
    </label>
  );
}
