import ReactTooltip from "react-tooltip";
import { PatentDocs, PlatformEntities } from "../Patents.types";
import { parsePatentJsonData } from "../helpers/Patents.utils";
import { usePatentsLinks } from "../Patents.hooks";
import { PlatformEntitiesMatch } from "../helpers/PlatformEntitiesMatch";
import { PatentsListViewPagination } from "./PatentsListViewPagination";

const PatentsListView = ({
  patents,
  platformEntities,
  totalCount,
  topPanel,
  isLoading,
}: {
  patents: PatentDocs[] | undefined;
  platformEntities: PlatformEntities["entities"] | undefined;
  totalCount: number;
  topPanel: React.ReactNode;
  isLoading: boolean;
}) => {
  const { pdfData, loadingLinks, handlePatentClick } = usePatentsLinks();
  return (
    <>
      <div className="max-h-[calc(100vh-200px)] overflow-auto">
        <div className="flex justify-start flex-row items-center gap-2 my-[3px] flex-wrap">
          {topPanel}
        </div>
        {!isLoading &&
          patents &&
          patents?.length > 0 &&
          patents?.map((patent, index) => {
            const {
              parsedTitles,
              parsedAbstracts,
              parsedPublicationDate,
              parsedUltimateOwner,
              parsedInventors,
              parsedRelatedPatents,
              parsedStatus,
              parsedExpectedExpiry,
              parsedExpectedExpiryDate,
              parsedApplicationDate,
            } = parsePatentJsonData(patent);
            return (
              <div
                key={index}
                className="flex flex-row gap-2 bg-tablePrimary w-full mt-3 p-4 rounded"
              >
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row items-center gap-2 leading-[100%]">
                    <div className="w-[20px] h-[14px] bg-primary rounded"></div>
                    <div className="text-[12px] text-primary">{patent?.id}</div>
                  </div>
                  <div className="text-[12px] font-normal text-secondary">
                    <div
                      data-for={"patent_" + index}
                      data-tip={"Patent ID: " + patent?.id}
                      className="h-fit w-full flex text-balance"
                    >
                      <ReactTooltip
                        place="top"
                        id={"patent_" + index}
                        className="max-w-[220px] p-1 text-[12px] text-center rounded !opacity-100"
                      />
                      {parsedTitles && parsedTitles !== "-" ? (
                        <>
                          {pdfData[patent.id]?.PdfURL === "NOT FOUND" ? (
                            <span className="text-primary">{parsedTitles}</span>
                          ) : (
                            <>
                              {pdfData[patent.id] ? (
                                <a
                                  href={pdfData[patent.id]?.PdfURL || ""}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-primaryBlue font-medium hover:underline"
                                >
                                  {parsedTitles}
                                </a>
                              ) : (
                                <div className="flex flex-row items-center leading-[120%]">
                                  <div
                                    className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                                    onClick={() =>
                                      handlePatentClick(patent.id, "pdf")
                                    }
                                  >
                                    {parsedTitles}
                                  </div>
                                  {loadingLinks[patent.id] && (
                                    <div className="text-[10px] font-normal text-secondary ml-2">
                                      Loading files...
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="text-[12px] font-normal flex flex-col w-[150px]">
                      <span className="text-secondary">Organizations</span>
                      <div className="text-primary">
                        {parsedUltimateOwner?.length > 0 &&
                          parsedUltimateOwner?.map(
                            (entity: string, index: number) => (
                              <div key={"entity" + index}>
                                <PlatformEntitiesMatch
                                  entityName={entity}
                                  platformEntities={platformEntities}
                                />
                                {index < parsedUltimateOwner?.length - 1 &&
                                  ", "}
                              </div>
                            )
                          )}
                        {parsedUltimateOwner?.length === 0 && "-"}
                      </div>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[100px]">
                      <span className="text-secondary">Publication Date</span>
                      <span className="text-primary">
                        {parsedPublicationDate || "-"}
                      </span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[100px]">
                      <span className="text-secondary">Application Date</span>
                      <span className="text-primary">
                        {parsedApplicationDate || "-"}
                      </span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[100px]">
                      <span className="text-secondary">Patent PDF</span>
                      <span className="text-primary">
                        {" "}
                        {pdfData[patent.id]?.PdfURL === "NOT FOUND" ? (
                          "PDF Not Found"
                        ) : (
                          <>
                            {pdfData[patent.id] ? (
                              <a
                                href={pdfData[patent.id]?.PdfURL || ""}
                                target="_blank"
                                rel="noreferrer"
                                className="text-primaryBlue font-medium hover:underline"
                              >
                                View Patent PDF
                              </a>
                            ) : (
                              <div
                                className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                                onClick={() =>
                                  handlePatentClick(patent.id, "pdf")
                                }
                              >
                                View Patent PDF
                              </div>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[100px]">
                      <span className="text-secondary">Patent Family Tree</span>
                      <span className="text-primary">
                        {pdfData[patent.id]?.FamilytreeURL === "NOT FOUND" ? (
                          "Family Tree Not Found"
                        ) : (
                          <>
                            {pdfData[patent.id] ? (
                              <a
                                href={pdfData[patent.id]?.FamilytreeURL || ""}
                                target="_blank"
                                rel="noreferrer"
                                className="text-primaryBlue font-medium hover:underline"
                              >
                                Family Tree
                              </a>
                            ) : (
                              <div
                                className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                                onClick={() =>
                                  handlePatentClick(patent.id, "tree")
                                }
                              >
                                Family Tree
                              </div>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[180px]">
                      <span className="text-secondary">Inventors</span>
                      <span className="text-primary">{parsedInventors}</span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[220px]">
                      <span className="text-secondary">Related Patents</span>
                      <span className="text-primary">
                        {parsedRelatedPatents?.length > 0
                          ? parsedRelatedPatents?.map((patent, index) => (
                              <span
                                key={index}
                                className="text-primaryBlue font-medium"
                              >
                                {pdfData[patent]?.PdfURL === "NOT FOUND" ? (
                                  <span className="text-primary">{patent}</span>
                                ) : (
                                  <>
                                    {pdfData[patent] ? (
                                      <a
                                        href={pdfData[patent]?.PdfURL || ""}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primaryBlue font-medium hover:underline"
                                      >
                                        {patent}
                                      </a>
                                    ) : (
                                      <span
                                        className="text-primaryBlue font-medium hover:underline hover:cursor-pointer"
                                        onClick={() =>
                                          handlePatentClick(patent, "pdf")
                                        }
                                      >
                                        {patent}
                                      </span>
                                    )}
                                  </>
                                )}
                                {index < parsedRelatedPatents?.length - 1 &&
                                  ", "}
                              </span>
                            ))
                          : "-"}
                      </span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[100px]">
                      <span className="text-secondary">Status</span>
                      <span className="text-primary">
                        {parsedStatus || "-"}
                      </span>
                    </div>
                    <div className="text-[12px] font-normal flex flex-col w-[100px]">
                      <span className="text-secondary">Expiry Date</span>
                      <span className="text-primary">
                        {parsedExpectedExpiry?.["explanation-link"] &&
                        parsedExpectedExpiry?.["explanation"] ? (
                          <div
                            data-for={"explanation_" + parsedExpectedExpiryDate}
                            data-tip={parsedExpectedExpiry?.["explanation"]}
                            className="h-fit"
                          >
                            <ReactTooltip
                              place="top"
                              id={"explanation_" + parsedExpectedExpiryDate}
                              className="max-w-[260px] py-1 text-[12px] text-left rounded !opacity-100"
                            />
                            <a
                              href={parsedExpectedExpiry?.["explanation-link"]}
                              target="_blank"
                              rel="noreferrer"
                              className="text-primaryBlue font-medium hover:underline"
                            >
                              {parsedExpectedExpiryDate || "-"}
                            </a>
                          </div>
                        ) : (
                          parsedExpectedExpiryDate || "-"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="text-[12px] font-normal flex flex-col">
                    <span className="text-secondary">Abstract</span>
                    <span className="text-primary">
                      {parsedAbstracts || "-"}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {!isLoading && <PatentsListViewPagination totalCount={totalCount} />}
    </>
  );
};

export default PatentsListView;
