import { Fragment, ReactNode } from "react";
import classNames from "classnames";

const PatentsCheck = ({
  index,
  isChecked,
  onCheckboxChange,
  className,
  optionLabel,
}: {
  index: number;
  isChecked: boolean;
  onCheckboxChange: (event: any) => void;
  className?: string;
  optionLabel: string | ReactNode;
}) => {
  let inputClasses = classNames([
    "qti_checkbox_ newsFeedSearchInput",
    className,
  ]);

  if (
    optionLabel === "OR" ||
    optionLabel === "AND" ||
    optionLabel === "NOT" ||
    optionLabel === "(" ||
    optionLabel === ")"
  ) {
    inputClasses = classNames([
      "qti_checkbox_ newsFeedSearchInput",
      className,
      "checkboxOperator",
    ]);
  }

  return (
    <Fragment>
      <label
        htmlFor={"key_" + index}
        // onClick={onCheckboxChange}
        className="cursor-pointer select-none z-20 truncate max-w-[200px]"
      >
        <input
          type="checkbox"
          id={"key_" + index}
          onChange={onCheckboxChange}
          defaultChecked={isChecked}
          className={inputClasses}
        />
        {optionLabel}
      </label>
    </Fragment>
  );
};

export default PatentsCheck;
