import { gql, useQuery } from "@apollo/client";
import { IGraphingTool } from "types";

const GET_TAGS = gql`
  query getGraphingTool(
    $period: String
    $classification: String
    $startDate: Date
    $endDate: Date
  ) {
    graphingToolReport(
      period: $period
      classification: $classification
      startDate: $startDate
      endDate: $endDate
    ) {
      roundCount
      month
      classification
      total
    }
  }
`;

type GraphingToolVars = {
  startDate?: string;
  endDate?: string;
  classification?: string;
  period?: string;
  skip?: boolean;
};

export function useGraphQLGraphingTool({
  startDate,
  endDate,
  classification,
  period,
  skip,
}: GraphingToolVars) {
  const { loading, error, data } = useQuery<{
    graphingToolReport: Array<IGraphingTool>;
  }>(GET_TAGS, {
    variables: {
      startDate: startDate,
      endDate: endDate,
      classification: classification,
      period: period,
    },
    skip: skip || false,
  });

  return { loading, error, data };
}
