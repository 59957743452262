import { addYears } from "date-fns";
import PatentsFilter from "../filters/PatentsFilter";
import PatentsKeywordsSearch from "../input/PatentsKeywordsSearch";
import { useLocation } from "react-router";

export const PatentsStatsPanel = ({
  filtersState,
  setFiltersState,
  handleSubmitData,
  patentsLoading,
  defaultKeywords,
}: {
  filtersState: {
    entities: {
      value: string;
      label: string;
    }[];
    keywords: string;
  };
  setFiltersState: React.Dispatch<
    React.SetStateAction<{
      entities: {
        value: string;
        label: string;
      }[];
      keywords: string;
    }>
  >;
  handleSubmitData: () => void;
  patentsLoading: boolean;
  defaultKeywords: {
    value: string;
    label: string;
  }[];
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const organizationParam = params.get("organization");
  const inventorParam = params.get("inventor");

  return (
    <div>
      <div className="flex justify-start flex-row items-center gap-2 mt-[3px] mb-3 flex-wrap">
        <PatentsKeywordsSearch
          filtersState={filtersState}
          setFiltersState={setFiltersState}
          handleSubmitData={handleSubmitData}
          isLoading={patentsLoading}
        />
        <PatentsFilter
          handleSubmitData={handleSubmitData}
          showSort={false}
          filtersState={filtersState}
          setFiltersState={setFiltersState}
          defaultKeywords={defaultKeywords}
          customStartDate={addYears(new Date(), -10)}
        />
      </div>
      {(!!organizationParam || !!inventorParam) && (
        <div className="flex flex-col mb-3">
          {!!organizationParam && (
            <div className="text-secondary text-[12px] font-normal flex flex-row m-1 gap-1">
              <span>Filtered by organization:</span>
              <span className="font-medium">{organizationParam}</span>
            </div>
          )}
          {!!inventorParam && (
            <div className="text-secondary text-[12px] font-normal flex flex-row m-1 gap-1">
              <span>Filtered by inventor:</span>
              <span className="font-medium">{inventorParam}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
