import { useEffect, useMemo, useState } from "react";
import { IFilterType } from "types";
import { useLocation, useNavigate } from "react-router";
import TooltipSlider from "components/TooltipSlider/TooltipSlider";
import { FilterUpdateFunc } from "../../Filter.types";

interface IProps {
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  column: string;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function SliderComponent({
  data = [],
  checkedTags,
  showSectorHeader,
  sectorHeader,
  columnName,
  onCheck,
}: IProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const currentYear = (new Date()).getFullYear();

  const founded_GTE = Number(params.get("founded_GTE")) || 0;
  const founded_LTE = Number(params.get("founded_LTE")) || currentYear;

  const [sliderValue, setSliderValue] = useState<number | number[] | undefined>(
    [founded_GTE, founded_LTE]
  );

  useEffect(() => {
    setSliderValue([founded_GTE, founded_LTE]);
  }, [founded_GTE, founded_LTE, params]);

  const handleSliderChange = (value: number | number[]) => {
    setSliderValue(value);
    params.delete("offset");
    params.delete("founded_GTE");
    params.append("founded_GTE", value[0]);
    params.delete("founded_LTE");
    params.append("founded_LTE", value[1]);
    navigate({ search: params.toString() });
  };

  const style = {
    fontSize: "14px",
    marginTop: "5px",
  };

  const marks = {
    1900: { label: "1900", style },
    1910: { label: "1910", style },
    1920: { label: "1920", style },
    1930: { label: "1930", style },
    1940: { label: "1940", style },
    1950: { label: "1950", style },
    1960: { label: "1960", style },
    1970: { label: "1970", style },
    1980: { label: "1980", style },
    1990: { label: "1990", style },
    2000: { label: "2000", style },
    2010: { label: "2010", style },
    [currentYear]: { label: currentYear, style },
  };

  return (
    <div className="flex flex-col h-full overflow-hidden my-8 mb-4">
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="text-primary text-[14px] font-bold pb-[6px] mb-6">
        {columnName}
      </div>
      <div className="border border-primary rounded pb-6 pt-3 px-3">
        <TooltipSlider
          range
          min={1900}
          max={currentYear}
          marks={marks}
          value={sliderValue}
          onChange={handleSliderChange}
          tipProps={{
            visible: true,
            overlayClassName: "rc-custom-tooltip",
          }}
        />
      </div>
    </div>
  );
}
