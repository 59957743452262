import "./Checkbox.style.css";
import columnVisibilityBars from "assets/images/column-visibility-bars.svg";
import classnames from "classnames";

export interface ICheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
    > {
  id?: string;
  inputName?: string;
  isSelected: boolean;
  onChange?: any;
  label: string;
  className?: string;
  classNames?: {
    inputLabel?: string;
    inputWrapper?: string;
    input?: string;
  };
  disabled?: boolean;
}

export default function Checkbox({
  id,
  label,
  isSelected,
  onChange,
  inputName,
  classNames,
  disabled,
}: ICheckboxProps) {
  return (
    <div
      className={classnames(
        classNames?.inputWrapper,
        "flex flex-row md:justify-between h-full w-full"
      )}
    >
      <div className="flex justify-center text-center align-center items-center">
        <div
          id={id}
          className={classnames("sqBox", {
            "!border-primaryBlue": isSelected,
          })}
        >
          <label>
            <input
              type="checkbox"
              name={inputName || label}
              checked={isSelected}
              onChange={onChange}
              className="cursor-pointer hidden"
              disabled={disabled}
            />
            <span></span>
          </label>
        </div>
        <span
          onClick={!disabled && onChange}
          className={classnames(
            classNames?.inputLabel,
            "ml-2 mr-4 w-full min-w-max text-[16px] text-primary font-normal align-middle cursor-pointer"
          )}
        >
          {label}
        </span>
      </div>
      <div className="justify-self-end">
        <div className="flex items-center justify-center text-center w-full h-full">
          <img
            className="min-w-[18px] hidden min-h-[8px]"
            alt="bars"
            src={columnVisibilityBars}
          />
        </div>
      </div>
    </div>
  );
}
