import classnames from "classnames";

import { IFilterType } from "types";

import { useSortAndFilters } from "../../../Filter.hooks";

import { useMemo, useState } from "react";
import { useLocation } from "react-router";
import { employeeRangeArray } from "const";
import { useDetectCheckedRange } from "./RangeLine.hooks";
import { inRange } from "./RangeLine.utils";
import { FilterUpdateFunc } from "../../../Filter.types";

interface IProps {
  column: string;
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function RangeLine({
  column,
  data = [],
  checkedTags,
  onCheck,
}: IProps) {
  const { employeeRangeData } = useSortAndFilters(data);

  const [checkedLimits, setCheckedLimits] = useState({ first: NaN, last: NaN });
  const detectRange = useDetectCheckedRange(checkedLimits, setCheckedLimits);

  const location = useLocation();

  // const paramsNumbers = params.getAll("employeeRange").map((i) => Number(i));

  const employeeIds = useMemo(() => {
    const ids: number[] = [];
    const params = new URLSearchParams(location.search);
    const paramsArray = params.getAll("employeeRange");

    employeeRangeArray.forEach((data) => {
      paramsArray.forEach((param) => {
        if (data.tag === param) {
          ids.push(data.id);
        }
      });
    });
    return ids;
  }, [location]);

  const minChecked = useMemo(() => {
    return Number(Math.min.apply(null, employeeIds));
  }, [employeeIds]);
  const maxChecked = useMemo(() => {
    return Number(Math.max.apply(null, employeeIds));
  }, [employeeIds]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden mt-2">
      <div className="flex mb-1 relative h-full">
        <div
          className={classnames("h-full w-full overflow-hidden  bg-primary ")}
        >
          <div
            className={classnames(
              "grid grid-flow-col auto-cols-fr bg-primary w-full mr-[-32px]"
            )}
          >
            {checkedTags &&
              employeeRangeData.slice(0, 8).map((item, i) => {
                return (
                  <div
                    className={classnames(
                      "flex flex-col rounded  cursor-pointer  py-1"
                    )}
                    key={item.tag + i}
                    onClick={() => {
                      onCheck(column, String(item.id), String(item.tag));
                      detectRange(Number(item.id));
                    }}
                  >
                    <div
                      className={classnames("flex flex-row items-center", {
                        "justify-start ": item.id > 7,
                        "justify-center": item.id > 0 && item.id < 7,
                      })}
                    >
                      <span
                        className={classnames(
                          "rounded-full min-h-[16px] max-h-[16px] max-w-[16px] min-w-[16px] border-[5px]",
                          {
                            "border-primaryBlue": inRange(
                              Number(item.id),
                              minChecked,
                              maxChecked
                            ),
                          }
                        )}
                      ></span>
                      <span
                        className={classnames("w-full h-[4px] ", {
                          "bg-primaryBlue":
                            inRange(
                              Number(item.id),
                              minChecked,
                              maxChecked - 1
                            ) && checkedTags.length > 1,
                          "bg-tableSecondary":
                            !inRange(
                              Number(item.id),
                              minChecked,
                              maxChecked - 1
                            ) ||
                            checkedTags.length < 1 ||
                            (inRange(
                              Number(item.id),
                              minChecked,
                              maxChecked - 1
                            ) &&
                              checkedTags.length < 2),
                          hidden: item.id > 7 || item.id < 1,
                        })}
                      />
                    </div>

                    <div
                      className={classnames(
                        "text-[#98A0A6] text-[12px] mt-[4px]",
                        {
                          "items-end": item.id < 2,
                        }
                      )}
                    >
                      {item.tag}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
