import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { ColumnVisibilityModalProps } from "../TableExtended.types";

export default function ColumnVisibilityModal({
  isShowing,
  onHide,
  showAllColumns,
  hideAllColumns,
  children,
}: ColumnVisibilityModalProps) {
  return (
    <Modal
      isShowing={isShowing}
      onHide={onHide}
      customMaxWidth
      showTitle={false}
      closeLight
      headerChildren={
        <div className="flex flex-col md:flex-row gap-2 w-full justify-start maxMd:items-start maxMd:ml-10 maxMd:mr-10  md:justify-between mt-0 mb-0 md:mt-8 md:mb-6 ">
          <div className="md:ml-8 min-w-max">
            <p className="text-primary text-[18px] font-bold leading-[26px]">
              Select Columns
            </p>
          </div>
          <div className="flex flex-col md:flex-row  maxMd:items-start gap-4 w-full justify-start md:justify-end ">
            <Button
              customPaddings
              className="flex justify-center items-center text-center rounded-[4px] w-[143px] border border-primaryBlue text-primaryBlue h-[36px] hover:bg-primaryBlue hover:text-white text-[14px] font-bold"
              onClick={hideAllColumns}
            >
              Deselect all
            </Button>
            <Button
              customPaddings
              className="flex justify-center items-center text-center rounded-[4px] w-[143px] border border-primaryBlue text-primaryBlue h-[36px] hover:bg-primaryBlue hover:text-white text-[14px] font-bold md:mr-6"
              onClick={showAllColumns}
            >
              Select all
            </Button>
          </div>
        </div>
      }
      classNames={{
        popup: "w-full max-w-[900px] h-[500px] md:h-[600px] rounded-lg mt-2",
        popupContainer: "pt-16 overflow-y-hidden h-[500px] md:h-[600px]",
        popupInner: " mt-6 pt-8 pb-0 md:pb-8 overflow-y-auto ",
        modalHeader: "justify-end  mt-5",
        borderLine: "hidden",
        closeButtonContainer: "mr-2 ",
      }}
      title="Select Columns"
    >
      <div>{children}</div>
    </Modal>
  );
}
