import classNames from "classnames";
import PageSizeSelector from "components/TableExtended/components/PageSizeSelector";
import PaginationBlock from "components/TableExtended/components/PaginationBlock";
import { useLocation, useNavigate } from "react-router";
import { useCheckPremium } from "utils";
import { usePatentFilters } from "../Patents.hooks";

export const PatentsListViewPagination = ({
  totalCount,
}: {
  totalCount: number;
}) => {
  const isPremium = useCheckPremium();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const { limit, offset } = usePatentFilters({});

  return (
    <>
      {isPremium && (
        <div
          className={classNames(
            "flex justify-between bg-tablePrimary py-2 border-t-[1px] border-primary flex-wrap md:flex-nowrap"
          )}
        >
          <div
            className={classNames(
              "flex flex-row items-center mx-4 md:w-[280px]"
            )}
          >
            {isPremium ? (
              <div className="text-[12px] text-[#98A0A6]">
                {totalCount && totalCount > 0 ? (
                  <p>
                    Showing {offset === 0 ? 1 : offset} to{" "}
                    {Number(limit) + Number(offset)} of {totalCount} entries
                  </p>
                ) : (
                  <p>No records to show</p>
                )}
              </div>
            ) : (
              <div className="font-medium text-[14px] text-secondary">
                {totalCount && `Showing 1 to 10 of ${totalCount} entries`}
              </div>
            )}
          </div>

          <PageSizeSelector
            disabled={!isPremium}
            limit={limit}
            params={params}
            sizes={[10, 20, 30]}
          />

          <PaginationBlock
            premiumAccess={isPremium}
            showPagination={true}
            previous={false}
            params={params}
            offset={offset}
            limit={limit}
            isPreviousData={false}
            currentPage={
              offset === 0 || limit === 0
                ? 1
                : offset === limit
                ? 2
                : Number(offset) / Number(limit) + 1
            }
            next={true}
            canPreviousPage={
              offset === 0 || limit === 0
                ? false
                : offset === limit
                ? false
                : true
            }
            canNextPage={
              totalCount === 0
                ? false
                : totalCount === undefined
                ? false
                : totalCount === Number(offset) + Number(limit)
                ? false
                : true
            }
            previousPage={
              offset === 0 || limit === 0
                ? () => {}
                : offset === limit
                ? () => {}
                : () => {
                    params.delete("offset");
                    params.append(
                      "offset",
                      String(Number(offset) - Number(limit))
                    );
                    navigate({
                      search: params.toString(),
                    });
                  }
            }
            totalCount={totalCount || 0}
          />
        </div>
      )}
    </>
  );
};
