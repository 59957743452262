import { ImageType } from "react-images-uploading";
import { IEnterpriseUserEntity, IInvestorEntity } from "types";

export function isEnterpriseUser(
  data: any | IEnterpriseUserEntity
): data is IEnterpriseUserEntity {
  const qu = data as IEnterpriseUserEntity;
  return !!(qu?.name && qu?.sector); // TODO - add more specific condition
}

export function isInvestor(
  data: any | IInvestorEntity
): data is IInvestorEntity {
  const inv = data as IInvestorEntity;
  return !!inv?.name; // TODO - add more specific condition
}

// ImageType type guard
export function isImageType(data: any | ImageType): data is ImageType {
  if (!data) return false;
  const img = data as ImageType;
  return !!(img["data_url"] || img.dataURL);
}

// // Image file type guard
// export function isImageFileType(data: any | ImageType): data is ImageType {
//   if (!data) return false;
//   const img = data as ImageType;
//   return !!(img["data_url"] && img.file);
// }
