import { useLocation } from "react-router";

export function useSortByColumn(defaultSorting?: string, defaultDesc?: boolean) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let sortCol: string | undefined;
  let sortDesc: boolean| undefined;

  const sort = params.get('sort');
  if (sort?.charAt(0) === '-') {
    sortCol = sort.slice(1);
    sortDesc = true;
  } else if (sort) {
    sortCol = sort;
    sortDesc = false;
  } else {
    sortCol = defaultSorting || 'id';
    sortDesc = defaultDesc || false;
  }

  return {
    sortCol,
    sortDesc,
    sortQuery: { [sortCol]: sortDesc ? "DESC" : "ASC" },
  };
}
