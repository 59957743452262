import { useEffect } from "react";

export function useOnClickOutside(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  refButton: React.MutableRefObject<HTMLDivElement | null>,
  handler: any
) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      if (!refButton.current || refButton.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, refButton]);
}
