import { useSortByColumn } from "hooks/useSortByColumn";
import { useGraphQLQcaas } from "graphql-requests/Qcaas";
import { useSearchParam } from "hooks/useSearchParam";
import { usePagination } from "hooks/usePagination";

export function useTagFilter() {
  const { sortQuery } = useSortByColumn('name');
  const { limit, offset } = usePagination();

  const search = useSearchParam();

  const { loading, data: organizations } = useGraphQLQcaas({
    limit: Number(limit),
    offset: Number(offset),
    sort: sortQuery,
    search: search,
  });

  const loadingStatus = loading ? "loading" : "success";

  return {
    qcaas: organizations,
    status: loadingStatus,
  };
}
