import classnames from "classnames";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { IFilterType } from "types";

import { useSortAndFilters } from "../../../Filter.hooks";
import { useOnClickOutside } from "hooks/useOnClickOutside";

import searchIcon from "assets/images/search-icon.svg";
import { useRef, useState } from "react";
import "./Popup.styles.css";
import { FilterUpdateFunc } from "../../../Filter.types";

interface IProps {
  column: string;
  data?: IFilterType[];
  checkedTags: string[];
  onCheck: FilterUpdateFunc;
  columnName: string;
  showSectorHeader?: boolean;
  sectorHeader?: string;
}

export default function Popup({
  column,
  data = [],
  checkedTags,
  onCheck,
  columnName,
  showSectorHeader = false,
  sectorHeader,
}: IProps) {
  const { query, setQuery, transformedData, onSearchHandler } =
    useSortAndFilters(data);

  const [showPopup, setShowPopup] = useState(false);
  const filterBlockPopupRef = useRef<HTMLDivElement | null>(null);
  const filterBlockButtonRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const filterInnerBlockPopupRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(filterBlockPopupRef, filterBlockButtonRef, () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
      setQuery("");
    }
    setShowPopup(false);
  });

  return (
    <div
      className={classnames("flex flex-col w-full h-full mt-2", {
        hidden: columnName === "Shares investors with", // TODO
      })}
    >
      {showSectorHeader && <div>{sectorHeader}</div>}
      <div className="mb-1 relative mb-[12px]">
        <div className="relative" ref={filterBlockButtonRef}>
          <div className="text-primary text-[14px] font-bold pb-[6px]">
            {columnName}
          </div>
          <Button
            className={classnames(
              "bg-primary rounded-md text-[#98A0A6] border border-primary !text-[12px] pl-[12px] flex w-full h-[40px] max-w-[340px]"
            )}
            customPaddings
            onClick={() => {
              setShowPopup(true);
            }}
          >
            Select{" "}
            <span className="hidden lowercase pl-[3px]">
              {columnName.toLocaleLowerCase().trim() === "portfolio"
                ? "company"
                : columnName}
            </span>
          </Button>
        </div>

        <div
          ref={filterBlockPopupRef}
          className={classnames(
            "absolute mt-[-5px] z-10 w-[200px] rounded-[4px] bg-primary border border-primary top-[78px]",
            {
              hidden: !showPopup,
            },
            {}
          )}
        >
          <div className="flex flex-row">
            <span className="absolute w-[20px] self-center h-[20px] right-[8px] items-center justify-center text-center">
              <img
                className="min-h-[20px] min-w-[20px]"
                alt="srch"
                src={searchIcon}
              />
            </span>
            <Input
              customRef={inputRef}
              defaultValue=""
              placeholder={"Search" || ""}
              onChange={onSearchHandler}
              className="pl-2 px-2 w-full text-[12px] rounded-[2px] h-[40px] p-3 outline-none text-[#98A0A6]"
            />
          </div>

          <div
            ref={filterInnerBlockPopupRef}
            className={classnames("flex flex-1 z-20 flex-col bg-primary", {
              hidden: query === "",
            })}
          >
            {transformedData.slice(0, 5).map((item, i) => {
              return (
                <div
                  className={classnames(
                    "flex justify-start cursor-pointer px-2 w-full py-1 hover:bg-blue-100 hover:bg-opacity-20"
                  )}
                  key={item.tag + i}
                  onClick={() => {
                    if (inputRef && inputRef.current) {
                      inputRef.current.value = "";
                    }
                    onCheck(column, String(item.id), String(item.tag));
                    setQuery("");
                    setShowPopup(false);
                  }}
                >
                  <div className="text-[14px] text-primary">{item.tag}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <hr className="hidden bg-tableSecondary ml-[-100px] mr-[-100px] mb-[6px] mt-[2px]" />

      <div
        className={
          columnName.toLocaleLowerCase().trim() === "portfolio" || columnName.toLocaleLowerCase().trim() === "company"
            ? "grid grid-cols-3"
            : "grid"
        }
      >
        {checkedTags?.map((tag) => {
          return (
            <div className="relative text-primary text-[12px] mt-2 mr-2 pl-[12px] py-[12px] h-[40px] flex items-center border border-primary rounded-[4px]">
              {tag}
              <span
                className="absolute text-[#98A0A6] right-[12px] text-[14px] cursor-pointer"
                onClick={() => onCheck(column, null, String(tag), true)}
              >
                x
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
