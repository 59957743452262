const PatentFilterButton = ({ label, onClick }) => {
  return (
    <button
      className={
        "text-[12px] w-[130px] py-2 px-3 rounded cursor-pointer text-center hover:opacity-70 bg-primaryBlue text-white"
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default PatentFilterButton;
