import { Checkbox } from "components/Checkbox";
import config from "config";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

export const PatentFilterRestricted = ({
  filtersState,
  setFiltersState,
  defaultKeywords,
}: {
  filtersState: {
    entities: {
      value: string;
      label: string;
    }[];
    keywords: string;
  };
  setFiltersState: React.Dispatch<
    React.SetStateAction<{
      entities: {
        value: string;
        label: string;
      }[];
      keywords: string;
    }>
  >;
  defaultKeywords: {
    value: string;
    label: string;
  }[];
}) => {
  const entitiesValues = filtersState.entities
    ?.map((entity) => entity.value)
    .join("");
  const defaultKeywordsValues = defaultKeywords
    ?.map((entity) => entity.value)
    .join("");

  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const navigate = useNavigate();

  return (
    <div>
      <Checkbox
        className="sqBox"
        key="col_restricted"
        label={"Restrict to " + config.PLATFORM_NAME + " search string"}
        inputName="restricted"
        onChange={useCallback(() => {
          params.delete("offset");
          params.delete("limit");

          if (
            filtersState.entities.length > 0 &&
            entitiesValues === defaultKeywordsValues
          ) {
            setFiltersState({
              ...filtersState,
              entities: [],
            });
            params.set("restricted", "false");
          } else {
            setFiltersState({
              ...filtersState,
              entities: defaultKeywords,
            });
            params.delete("restricted");
          }
          navigate({ search: params.toString() });
        }, [
          defaultKeywords,
          defaultKeywordsValues,
          entitiesValues,
          filtersState,
          navigate,
          params,
          setFiltersState,
        ])}
        isSelected={!params.get("restricted")}
        classNames={{
          inputWrapper: "mb-0",
          inputLabel: "!text-[12px] !mx-1 select-none  text-secondary",
        }}
      />
    </div>
  );
};
