import { gql, useQuery } from "@apollo/client";
import { IAnalysisTool } from "types";

const GET_TAGS = gql`
  query getAnalysisTool(
    $classification: String
    $startYear: Int
    $endYear: Int
    $employeeRangeFilter: [String]
    $regionFilter: [String]
    $countryFilter: [String]
    $primaryClassificationFilter: [String]
    $totals: Boolean
  ) {
    organizationsReport(
      classification: $classification
      startYear: $startYear
      endYear: $endYear
      totals: $totals
      employeeRangeFilter: $employeeRangeFilter
      regionFilter: $regionFilter
      countryFilter: $countryFilter
      primaryClassificationFilter: $primaryClassificationFilter
    ) {
      year
      classification
      total
    }
  }
`;

type AnalysisToolVars = {
  classification?: string;
  startYear?: number | null;
  endYear?: number | null;
  employeeRanges?: string[];
  regions?: string[];
  countries?: string[];
  primaryClassifications?: string;
  totals?: boolean;
  skip?: boolean;
};

export const useGraphQLAnalysisTool = ({
  classification,
  startYear,
  endYear,
  totals,
  employeeRanges,
  regions,
  countries,
  primaryClassifications,
  skip,
}: AnalysisToolVars) => {
  const { loading, error, data } = useQuery<{
    organizationsReport: Array<IAnalysisTool>;
  }>(GET_TAGS, {
    variables: {
      startYear,
      endYear,
      classification,
      totals,
      primaryClassificationFilter: primaryClassifications,
      regionFilter: regions,
      countryFilter: countries,
      employeeRangeFilter: employeeRanges,
    },
    skip: skip || false,
  });

  return { loading, error, data };
};
