import appRoutes from "const/appRoutes";

export function addHttps(url?: string) {
  return url?.match("http") ? url : `https://${url}`;
}
export function removeHttps(url: string) {
  return url.replace('http://', '').replace('https://', '').replace(/\/+$/, '');
}

export const getLinkByEntity = (entity) => {
  if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("ORGANIZATION")
  ) {
    return appRoutes.COMPANY_PROFILE.replace(":entityId", entity.id);
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("UNIVERSITY")
  ) {
    return appRoutes.UNIVERSITY_PROFILE.replace(":entityId", entity.id);
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("INVESTOR")
  ) {
    return appRoutes.INVESTOR_PROFILE.replace(":entityId", entity.id);
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("GOVERNMENT_AGENCY")
  ) {
    return appRoutes.GOVERNMENT_ENTITIES_PROFILE.replace(":entityId", entity.id);
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("NATIONAL_LAB")
  ) {
    return appRoutes.GOVERNMENT_ENTITIES_PROFILE.replace(":entityId", entity.id);
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("CONSORTIUM")
  ) {
    return appRoutes.CONSORTIUM_PROFILE.replace(":entityId", entity.id);
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("BRAND")
  ) {
    return appRoutes.ENTERPRISE_USERS_PROFILE.replace(
      ":entityId",
      entity.id
    );
  } else if (
    entity.__typename === "Entity" &&
    entity.entityType.includes("CENTER")
  ) {
    return appRoutes.GROUPS_AND_CENTERS_PROFILE.replace(":entityId", entity.id);
  } else if (entity.__typename === "FundingRound") {
    return appRoutes.FUNDING_ROUNDS;
  } else if (entity.__typename === "InvestmentRound") {
    return appRoutes.FUNDING_ROUNDS;
  } else if (entity.__typename === "PrimaryClassification") {
    return appRoutes.COMPANIES + "?primaryClassification=" + entity.id;
  } else if (entity.__typename === "SecondaryClassification") {
    return appRoutes.COMPANIES + "?secondaryClassification=" + entity.id;
  }

  return "/";
};