import { gql, useQuery } from "@apollo/client";
import { ICountryEntity, VariableType } from "types";

export const GET_FUNDING = gql`
  query getCountries($sort: [CountrySort!], $isAdmin: Boolean!) {
    countries(options: { sort: $sort }) {
      id
      name
      commentary
      lat
      lng
      nationalStrategy
      fundingUsdTotal
      fundingLocalTotal
      source {
        id
        name
        url
      }
      fundingRounds {
        id
        createdAt
        fundingUsd
        fundingLocal
        fxRate
        lastUpdatedBy @include(if: $isAdmin)
        updatedAt
        localCurrency
        fundingInfo
        source
        isActive
        deliveryPeriod
        startDate
      }
    }
  }
`;

export function useGraphQLGovernmentFunding({ sort, isAdmin }: VariableType) {
  const { loading, error, data } = useQuery<
    {
      countries: ICountryEntity[];
    },
    VariableType
  >(GET_FUNDING, {
    variables: {
      sort: sort,
      isAdmin: isAdmin || false,
    },
  });

  return { loading, error, data };
}
