import config from "config";
import { gql, useLazyQuery } from "@apollo/client";
import { IBusinessTypeEntity } from "types";
import { useEffect } from "react";

const TYPE_FIELDS = `
  id
  name
`;

const GET_TYPE = gql`
  query getBusinessTags {
    tags (where: {tagType: BUSINESS_TYPE, vertical_INCLUDES: ${config.VERTICAL}}) {
      ${TYPE_FIELDS}
    }
  }
`;

export function useGraphQLBusinessType({ canStart = true } = {}) {
  const [runQuery, { loading, error, data }] = useLazyQuery<{
    tags: Array<IBusinessTypeEntity>;
  }>(GET_TYPE);

  useEffect(() => {
    if (canStart) runQuery().then();
  }, [canStart]);

  return { loading, error, data };
}
