import { gql, useQuery } from "@apollo/client";
import { ICountryEntity } from "types";

const GET_COUNTRIES = gql`
  query getCountries(
      $sort: [CountrySort!],
      $where: CountryWhere
  ) {
    countries(where: $where, options: {sort: $sort}) {
      id
      name
      region {
          id
          name
      }
    }
  }
`;

type CountryVarsType = {
  where?: any;
  sort?: any;
  skip?: boolean;
};

export function useGraphQLCountries({ where, sort, skip = false }: CountryVarsType) {
  const { loading, error, data } = useQuery<{
    countries: ICountryEntity[];
  }>(GET_COUNTRIES, {
    variables: {
      where: where,
      sort: sort,
    },
    skip: skip,
  });

  return { loading, error, data };
}
