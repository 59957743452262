import config from "config";
import { gql, useQuery } from "@apollo/client";
import { useCheckPremium } from "utils";

const GET_PENDING_ENTITIES = gql`
  query AdminDashboardEntities(
    $limit: Int
    $offset: Int
  ) {
    entities(
      options: { limit: $limit, offset: $offset, sort: { createdAt: DESC } }
      where: { AND: [{ vertical_INCLUDES: ${config.VERTICAL} }, { status: PENDING }] }
    ) {
      id
      name
      status
      entityType
      createdAt
    }
    entitiesAggregate(where: { vertical_INCLUDES: ${config.VERTICAL}, status: PENDING }) {
      count
    }
  }
`;

const GET_PENDING_ROUNDS = gql`
  query AdminDashboardRounds(
    $limit: Int
    $offset: Int
  ) {
    fundingRounds(
      options: { limit: $limit, offset: $offset, sort: { createdAt: DESC } }
      where: { AND: [{ vertical_INCLUDES: ${config.VERTICAL} }, { status: PENDING }] }
    ) {
      id
      organizationHasRound {
        id
        name
      }
      status
      createdAt
    }
    fundingRoundsAggregate(where: { vertical_INCLUDES: ${config.VERTICAL}, status: PENDING }) {
      count
    }
  }
`;

type AdminDashboardVarsType = {
  limit?: number;
  offset?: number;
};

export function useGraphQLAdminDashboardEntities({
  limit,
  offset,
}: AdminDashboardVarsType) {
  const { loading, error, data } = useQuery<{
    entities: {
      id: string;
      name: string;
      status: string;
      entityType: string[];
      createdAt: string;
    }[];
    entitiesAggregate: { count: number };
  }>(GET_PENDING_ENTITIES, {
    variables: {
      limit: limit,
      offset: offset,
    },
  });

  return { loading, error, data };
}

export function useGraphQLAdminDashboardRounds({
  limit,
  offset,
}: AdminDashboardVarsType) {
  const { loading, error, data } = useQuery<{
    fundingRounds: {
      id: string;
      organizationHasRound: {
        id: string;
        name: string;
      }[];
      status: string;
      createdAt: string;
    }[];
    fundingRoundsAggregate: { count: number };
  }>(GET_PENDING_ROUNDS, {
    variables: {
      limit: limit,
      offset: offset,
    },
  });

  return { loading, error, data };
}

const GET_PENDING_DATA = gql`
  query {
    pendingCount
  }
`;

export function usePendingCount() {
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery<{
    pendingCount: number;
  }>(GET_PENDING_DATA, {
    skip: !isPremium,
  });

  return { loading, error, data };
}
