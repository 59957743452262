import classnames from "classnames";
import { memo, SyntheticEvent } from "react";
import { Button } from "components/Button";
import { ENTITY_STATUSES } from "const";
import searchIcon from "assets/images/input-search-icon.svg";
import { useLocation, useNavigate } from "react-router";
import { useDebouncedHandler } from "hooks/useDebounce";
import { useCheckPremium, useCheckSuper } from "utils";
import AddIcon from "assets/images/add-icon-white.svg";
import resetIcon from "assets/images/resetIcon.svg";
import tableColumnIcon from "assets/images/table-columns-icon.svg";
import tableFiltersIcon from "assets/images/table-filters-icon.svg";

import { Switch } from "@headlessui/react";
import ExportButton from "./ExportButton";
import { TableManagementPanelProps } from "../TableExtended.types";

export function TableManagementPanel({
  showColumnVisibility,
  resetResizing,
  toggleColumnVisibilityModal,
  showFiltering,
  toggleFilteringModal,
  disableAdvanced,
  queryStatus,
  enableSearch,
  onSearch,
  onAdd,
  isAdmin,
  showPendingSwitch = true,
  showAddButton = true,
  isResizeStarted,
  setIsResizeStarted,
  tableName,
  buttonAddNewText,
  showResizing = true,
  showExportButton = false,
  loadingExport,
  dataExport,
  getExportData,
  columnVisibility,
}: TableManagementPanelProps) {
  const isPremium = useCheckPremium();
  const isSuper = useCheckSuper();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleSearchChange = useDebouncedHandler(
    (e: SyntheticEvent<Element, Event>) => {
      if (onSearch) {
        onSearch(e);
      }
    },
    600
  );

  return (
    <div className="flex gap-2 flex-col md:flex-row flex-wrap lg:flex-nowrap xl:absolute right-7">
      {isPremium && showResizing && (
        <div
          className={classnames(
            "hidden lg:flex flew-row items-center gap-2 text-primaryBlue text-[12px] font-bold hover:cursor-pointer select-none"
          )}
          onClick={() => {
            if (resetResizing) {
              resetResizing({});
            }
            if (setIsResizeStarted) {
              setIsResizeStarted(false);
            }
          }}
        >
          <img src={resetIcon} alt={resetIcon} className="w-[15px]" />
          <span>Reset Table Resizing</span>
        </div>
      )}

      {isAdmin && showPendingSwitch && (
        <div className="hidden lg:flex flex-row justify-center items-center gap-2 mx-2">
          <Switch
            checked={params.get("status") === ENTITY_STATUSES.PENDING}
            onChange={() => {
              // clear pagination values (limit and offset) when filter is changed
              params.delete("limit");
              params.delete("offset");

              if (params.get("status") !== ENTITY_STATUSES.PENDING) {
                params.append("status", ENTITY_STATUSES.PENDING);
              } else {
                params.delete("status");
              }

              navigate({ search: params.toString() });
            }}
            className={classnames(
              params.get("status") === ENTITY_STATUSES.PENDING
                ? "bg-primaryBlue"
                : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200"
            )}
          >
            <span
              aria-hidden="true"
              className={classnames(
                params.get("status") === ENTITY_STATUSES.PENDING
                  ? "translate-x-4"
                  : "translate-x-0",
                "pointer-events-none inline-block h-4 w-4 rounded-full bg-primary shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <span className="text-[12px] text-primary">Show only pending</span>
        </div>
      )}

      {enableSearch && isPremium && (
        <div
          className={classnames(
            "hidden xl:flex items-center max-w-full md:max-w-[190px] h-[36px] mx-0 justify-center border border-solid hover:border-primaryBlue bg-primary border-primary hover:border-gray-700 px-0  rounded-[4px]  flex-[3]",
            { "border-gray-500": !enableSearch },
            { "border-primary": enableSearch }
          )}
        >
          <input
            onChange={(e) => enableSearch && onSearch && handleSearchChange(e)}
            type="text"
            placeholder="Search"
            className={classnames(
              "px-4 text-center lg:text-left focus:outline-0 h-[34px] !text-[12px] text-[#98A0A6] rounded-sm bg-primary hover:border-primaryBlue py-2",
              { "cursor-not-allowed": !enableSearch }
            )}
            disabled={!enableSearch}
          />
          <img
            className="hidden lg:block relative right-[12px]"
            alt="search-icon"
            src={searchIcon}
          />
        </div>
      )}

      {showColumnVisibility && isPremium && (
        <Button
          className={classnames(
            "border border-solid max-h-[36px] min-h-[36px] h-[36px] px-3 gap-2 flex justify-center hover:border-primaryBlue bg-tableSecondary text-primaryBlue font-bold !text-[12px] border-primary rounded-[4px]"
          )}
          onClick={toggleColumnVisibilityModal}
        >
          <img src={tableColumnIcon} alt="tableColumnIcon" />
          Columns
        </Button>
      )}

      {showFiltering && (
        <div className="flex flex-col info-tooltip relative">
          <Button
            className={classnames(
              "border border-solid max-h-[36px] min-h-[36px] h-[36px] px-3 gap-2 flex justify-center hover:border-primaryBlue bg-tableSecondary text-primaryBlue font-bold !text-[12px] border-primary rounded-[4px]",
              {
                "pointer-events-none select-none opacity-50": !isPremium,
              }
            )}
            onClick={toggleFilteringModal}
            disabled={disableAdvanced}
          >
            <img src={tableFiltersIcon} alt="tableFiltersIcon" />
            Filters
          </Button>
        </div>
      )}

      {showExportButton && (!!isPremium || !!isAdmin || !!isSuper) && (
        <ExportButton
          tableName={tableName}
          queryStatus={queryStatus}
          loadingExport={loadingExport}
          dataExport={dataExport}
          getExportData={getExportData}
          columnVisibility={columnVisibility}
        />
      )}

      {showAddButton && isAdmin && (
        <Button
          className={classnames(
            "min-h-[36px] h-[36px] px-4 flex flex-row items-center gap-1 justify-center hover:opacity-75 font-bold !text-[12px] bg-primaryBlue text-white rounded-[4px]"
          )}
          onClick={onAdd}
        >
          <img src={AddIcon} alt="AddIcon" className="w-[14px] h-[14px]" />
          <span>{buttonAddNewText}</span>
        </Button>
      )}
    </div>
  );
}
export default memo(TableManagementPanel);
