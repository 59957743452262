import config from "config";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useCheckPremium } from "utils";
import { IClassificationEntity } from "types";
import { useEffect } from "react";

const PRIMARY_CLASSIFICATIONS_FIELDS = `
  id
  name
  description
  vertical
`;

const GET_PRIMARY_CLASSIFICATIONS = gql`
  query getPrimaryClassifications {
    primaryClassifications(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
      ${PRIMARY_CLASSIFICATIONS_FIELDS}
    }
  }
`;

export function useGraphQLPrimaryClassification({ skip = false }) {
  const isPremium = useCheckPremium();
  const { loading, error, data } = useQuery(GET_PRIMARY_CLASSIFICATIONS, {
    skip: skip || !isPremium,
  });

  return { loading, error, data };
}

const SECONDARY_CLASSIFICATIONS_FIELDS = `
  id
  name
  description
  childOf {
    id
    name
  }
`;

const GET_SECONDARY_CLASSIFICATIONS = gql`
  query getSecondaryClassifications {
    secondaryClassifications(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
      ${SECONDARY_CLASSIFICATIONS_FIELDS}
    }
  }
`;

export function useGraphQLSecondaryClassification() {
  const { loading, error, data } = useQuery(GET_SECONDARY_CLASSIFICATIONS);

  return { loading, error, data };
}

export function useClassifications({ canStart = true } = {}) {
  const isPremium = useCheckPremium();
  const [runQuery, { loading, error, data }] = useLazyQuery<{
    primaryClassifications: IClassificationEntity[];
    secondaryClassifications: IClassificationEntity[];
  }>(
    gql`
      query getClassifications {
        primaryClassifications(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
          ${PRIMARY_CLASSIFICATIONS_FIELDS}
        }
        secondaryClassifications(where: {vertical_INCLUDES: ${config.VERTICAL}}) {
          ${SECONDARY_CLASSIFICATIONS_FIELDS}
        }
      }
    `
  );

  useEffect(() => {
    if (canStart && isPremium) runQuery().then();
  }, [canStart]);

  return { loading, error, data };
}
