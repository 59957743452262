import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";

const PatentFilterSort = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  return (
    <>
      <div
        onClick={() => {
          params.delete("offset");
          params.delete("limit");
          if (params.get("sort") === "-ultimate_owner") {
            params.set("sort", "ultimate_owner");
          } else {
            params.set("sort", "-ultimate_owner");
          }
          navigate({ search: params.toString() });
        }}
        className={classNames(
          "cursor-pointer bg-primary text-primary text-[12px] font-medium py-2 px-4 rounded-full w-fit flex gap-2 hover:bg-primaryBlue hover:text-white",
          {
            "bg-primaryBlue text-white":
              params.get("sort") === "-ultimate_owner" ||
              params.get("sort") === "ultimate_owner",
          }
        )}
      >
        <span>Organization</span>{" "}
        {(params.get("sort") === "-ultimate_owner" ||
          params.get("sort") === "ultimate_owner") && (
          <span>{params.get("sort") === "-ultimate_owner" ? "↓" : "↑"}</span>
        )}
      </div>
      <div
        onClick={() => {
          params.delete("offset");
          params.delete("limit");
          if (params.get("sort") === "-publicationdate") {
            params.set("sort", "publicationdate");
          } else {
            params.set("sort", "-publicationdate");
          }
          navigate({ search: params.toString() });
        }}
        className={classNames(
          "cursor-pointer bg-primary text-primary text-[12px] font-medium py-2 px-4 rounded-full w-fit flex gap-2 hover:bg-primaryBlue hover:text-white",
          {
            "bg-primaryBlue text-white":
              params.get("sort") === "-publicationdate" ||
              params.get("sort") === "publicationdate",
          }
        )}
      >
        <span>Publication date</span>{" "}
        {(params.get("sort") === "-publicationdate" ||
          params.get("sort") === "publicationdate") && (
          <span>{params.get("sort") === "-publicationdate" ? "↓" : "↑"}</span>
        )}
      </div>
      <div
        onClick={() => {
          params.delete("offset");
          params.delete("limit");
          if (params.get("sort") === "-applicationdate") {
            params.set("sort", "applicationdate");
          } else {
            params.set("sort", "-applicationdate");
          }
          navigate({ search: params.toString() });
        }}
        className={classNames(
          "cursor-pointer bg-primary text-primary text-[12px] font-medium py-2 px-4 rounded-full w-fit flex gap-2 hover:bg-primaryBlue hover:text-white",
          {
            "bg-primaryBlue text-white":
              params.get("sort") === "-applicationdate" ||
              params.get("sort") === "applicationdate",
          }
        )}
      >
        <span>Application date</span>{" "}
        {(params.get("sort") === "-applicationdate" ||
          params.get("sort") === "applicationdate") && (
          <span>{params.get("sort") === "-applicationdate" ? "↓" : "↑"}</span>
        )}
      </div>
      <div
        onClick={() => {
          params.delete("offset");
          params.delete("limit");
          if (params.get("sort") === "score") {
            params.delete("sort");
          } else {
            params.set("sort", "score");
          }
          navigate({ search: params.toString() });
        }}
        className={classNames(
          "cursor-pointer bg-primary text-primary text-[12px] font-medium py-2 px-4 rounded-full w-fit flex gap-2 hover:bg-primaryBlue hover:text-white",
          {
            "bg-primaryBlue text-white": params.get("sort") === "score",
          }
        )}
      >
        <span>Relavance</span>
      </div>
    </>
  );
};

export default PatentFilterSort;
