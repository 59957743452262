import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../TableExtended.styles.css";
import { Button } from "components/Button";
import backImg from "assets/images/pagination-back.svg";
import nextImg from "assets/images/pagination-next.svg";
import { useNavigate } from "react-router";
import classnames from "classnames";

const PaginationBlock = ({
  premiumAccess,
  showPagination,
  previous,
  params,
  offset,
  limit,
  isPreviousData,
  currentPage,
  next,
  canPreviousPage,
  canNextPage,
  previousPage,
  totalCount,
}) => {
  const navigate = useNavigate();

  const paginationPageNumbers = [];
  for (let i = 0; i < Math.ceil(totalCount! / limit); i++) {
    paginationPageNumbers.push(i as never);
  }

  if (paginationPageNumbers.length === currentPage) {
    next = false;
  }

  if (offset > 0) {
    previous = true;
  }

  return premiumAccess ? (
    <div
      className={classnames(
        "flex flex-row w-full justify-end mr-5 text-base items-center",
        {
          "": showPagination,
          " hidden": !showPagination,
        },
        {}
      )}
    >
      <Button
        customPaddings
        className={classnames(
          {
            "hover:bg-tableSecondaryHover": previous,
            " cursor-default": !previous,
          },
          "p-[5px] pl-[10px] pr-[10px] border-none !text-[12px] text-primary font-bold focus:outline-none flex justify-center rounded-md"
        )}
        onClick={() => {
          // Pass offset in url
          params.delete("offset");
          params.append("offset", String(Number(offset) - Number(limit)));
          navigate({
            search: params.toString(),
          });
        }}
        disabled={!previous || isPreviousData}
      >
        <img alt="back" className="mr-2" src={backImg} />
        Previous
      </Button>
      <Button
        customPaddings
        className={classnames(
          "ml-1 mr-1 h-[30px] min-w-[30px] p-1 bg-tablePrimary font-bold !text-[12px] text-primary focus:outline-none border border-gray-300 rounded-md flex justify-center",
          { hidden: currentPage - 1 <= 1 }
        )}
        onClick={() => {
          // Pass offset in url
          params.delete("offset");
          params.append("offset", "0");
          navigate({
            search: params.toString(),
          });
        }}
      >
        1
      </Button>
      <Button
        customPaddings
        disabled
        className={classnames(
          "ml-1 mr-1 h-[30px] min-w-[30px] p-1 bg-tablePrimary font-bold !text-[12px] text-primary focus:outline-none border border-gray-300 rounded-md cursor-default flex justify-center",
          {
            hidden: currentPage - 1 <= 1 || paginationPageNumbers.length <= 1,
          }
        )}
      >
        ...
      </Button>
      {[...paginationPageNumbers]
        .filter((i) => currentPage - 1 <= i + 2 && currentPage - 1 >= i - 2)
        .map((i) => {
          return (
            <Button
              key={i + "button"}
              customPaddings
              className={classnames(
                "ml-1 mr-1 h-[30px] min-w-[30px] p-1 !text-[12px] text-primary font-bold bg-tablePrimary hover:bg-[#31414E] hover:text-white focus:outline-none border border-gray-300 rounded-md flex justify-center",
                { "bg-[#31414E] text-white": currentPage - 1 === i },
                {
                  "hover:bg-tableSecondaryHover": currentPage - 1 !== i,
                },
                {
                  hidden: currentPage - 1 <= i - 2 || currentPage - 1 >= i + 2,
                }
              )}
              onClick={() => {
                // Pass offset in url
                params.delete("offset");
                params.append("offset", String(limit * i));
                navigate({
                  search: params.toString(),
                });
              }}
              disabled={isPreviousData}
            >
              {i + 1}
            </Button>
          );
        })}
      <Button
        disabled
        customPaddings
        className={classnames(
          {
            " hidden ": currentPage - 1 >= paginationPageNumbers.length - 2,
          },
          "ml-1 mr-1 h-[30px] min-w-[30px] p-1 !text-[12px] text-primary font-bold bg-tablePrimary focus:outline-none border border-gray-300 rounded-md cursor-default flex justify-center"
        )}
        hidden={currentPage - 1 >= paginationPageNumbers.length - 2}
      >
        ...
      </Button>
      <Button
        customPaddings
        className={classnames(
          {
            hidden: currentPage - 1 >= paginationPageNumbers.length - 2,
          },
          "ml-1 mr-1 h-[30px] min-w-[30px] p-1 !text-[12px] text-primary hover:bg-[#31414E] hover:text-white font-bold bg-tablePrimary focus:outline-none border border-gray-300 rounded-md flex justify-center"
        )}
        onClick={() => {
          // Pass offset in url
          params.delete("offset");
          params.append(
            "offset",
            String(limit * (paginationPageNumbers.length - 1))
          );
          navigate({
            search: params.toString(),
          });
        }}
      >
        {paginationPageNumbers.length}
      </Button>
      <Button
        className={classnames(
          {
            "hover:bg-tableSecondaryHover": next,
            "cursor-default": !next,
          },
          "p-[5px] pl-[10px] pr-[10px] bg-none !text-[12px] text-primary font-bold border-none rounded-md focus:outline-none flex justify-center"
        )}
        onClick={() => {
          // Pass offset in url
          params.delete("offset");
          params.append("offset", String(Number(offset) + Number(limit)));
          navigate({
            search: params.toString(),
          });
        }}
        disabled={
          !next || currentPage >= paginationPageNumbers.length || isPreviousData
        }
        customPaddings
      >
        Next <img alt="next" className="ml-2" src={nextImg} />
      </Button>
    </div>
  ) : (
    <div
      className={classnames(
        "flex flex-row w-full justify-end mr-5 mb-5 text-base items-center",
        {
          "": showPagination,
          " hidden": !showPagination || !premiumAccess,
        },
        {}
      )}
    >
      <Button
        customPaddings
        className={classnames(
          {
            "hover:bg-tableSecondaryHover": canPreviousPage,
            " cursor-default": !canPreviousPage,
          },
          "p-[5px] pl-[10px] pr-[10px] bg-none !text-[12px] text-primary font-bold border-none  focus:outline-none flex justify-center rounded-2xl"
        )}
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        Previous
      </Button>
      <Button
        customPaddings
        className={classnames(
          "ml-1 mr-1 h-[30px] min-w-[30px] p-1 focus:outline-none border border-gray-300 rounded-md flex justify-center",
          { hidden: currentPage - 1 <= 1 }
        )}
      >
        1
      </Button>

      <Button
        customPaddings
        className={classnames(
          "ml-1 mr-1 h-[30px] min-w-[30px] p-1 focus:outline-none border border-gray-300 rounded-md flex justify-center bg-[#31414E] text-white",
          {}
        )}
        style={{ fontWeight: "bold" }}
      >
        1
      </Button>
      <Button
        className={classnames(
          {
            "hover:bg-tableSecondaryHover": canNextPage,
            "cursor-default": !canNextPage,
          },
          "p-[5px] pl-[10px] pr-[10px] bg-none !text-[12px] text-primary font-bold border-none rounded-2xl focus:outline-none flex justify-center"
        )}
        disabled={!canNextPage}
        customPaddings
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationBlock;
